import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  debounceTime,
  lastValueFrom,
  map,
  Observable,
  retry,
  shareReplay,
  tap,
} from 'rxjs';
import {
  AnnoouncementModelObject,
  BatchDetailModel,
  BatchDetailObject,
  CurrentBatchObject,
  DemoVideoModelObject,
  DoubtsSubjects,
  FacultyRootObject,
  HomeworkId,
  PathshalaObject,
  RecentlyWatchedVideoStatsObject,
  TestCategoriesObject,
  TodayScheduleObject,
  TopicsObject,
} from 'src/app/pages/batch/batch-overview/batch-overview.model';
import { PPApiService } from '../../api/api.service';
import { PPApiOptions, PPApiParams } from '../../api/api.type';
import { handleError } from '../error-handler/error-handler.service';
import { STORAGE_ENUM, StorageService } from '../storage/storage.service';
import { WeeklyScheduleObject } from '../../../pages/batch/batch-weekly-schedule/batch-weekly-schedule.model';
import { AppUrlService } from '../../app-url/app-url.service';
import {
  BatchConfig,
  BatchPlan,
  BatchStatus,
  ClubbingOfferingApiResponse,
  MahapackBatchMapping,
  MahapackBatchMappingRoot,
} from './batch.modal';

import { BATCH_DISCOVERABILITY_EVENTS } from 'src/app/core/analytics-events';
import { FirebaseAnalyticsService } from '../firebase-analytics/firebase-analytics.service';
import { SuccessResponse } from '../cohort/cohort.type';
import { GlobalService } from '../global/global.service';
import { SuccessfullyEnrolledModalComponent } from '../../components/successfully-enrolled-modal/successfully-enrolled-modal.component';
import { NgDialogAnimationService } from 'ng-dialog-animation';
import { ShowErrorService } from '../showError/show-error.service';
import { GlobalObjectService } from '../global-object/global-object.service';
import { BatchPlusModalComponent } from 'src/app/pages/batch/batch-plus-modal/batch-plus-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BatchUpgradePopUpComponent } from 'src/app/pages/batch/batch-upgrade-pop-up/batch-upgrade-pop-up.component';
import { MoengageService } from '../moengage/moengage.service';
import { BatchUserSegment } from 'src/app/enum/batchStatus.enum';
import { LeadFormsComponent } from '../../components/lead-forms/lead-forms.component';

@Injectable({
  providedIn: 'root',
})
export class BatchService {
  BATCH_DATA: string = 'BATCH_DATA';
  TEACHER_DATA: string = 'TEACHER_DATA';
  TOPIC_DATA: string = 'TOPIC_DATA';
  activeOverviewSection$: BehaviorSubject<string> = new BehaviorSubject('');
  isBatchEnrolled$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  userSelectedExam$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  BATCH_LISTING: string = 'batchListing';
  PURCHASED_BATCH_LISTING = 'userBatches';
  TAG_CAN_PAGINATE = 'TAG_CAN_PAGINATE';
  VIDEO_BACK_URL = 'videoBackUrl';
  userSelectedExam: string = 'USER_SELECTED_EXAM';
  studentMasterCloseCount = 'student_master_close_count';
  batchListingData$: BehaviorSubject<BatchDetailModel[]> = new BehaviorSubject<
    BatchDetailModel[]
  >([]);
  doubtsSubjectsData$: BehaviorSubject<DoubtsSubjects[]> = new BehaviorSubject<
    DoubtsSubjects[]
  >([]);
  batchDetails$: BehaviorSubject<BatchDetailModel> =
    new BehaviorSubject<BatchDetailModel>(new BatchDetailModel({}));

  mahapackBatchesMapping$: BehaviorSubject<MahapackBatchMapping[]> =
    new BehaviorSubject<MahapackBatchMapping[]>([]);

  isFromNewBatchListing$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  batchPlanId$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  isPlusPlanPurchased$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  premiumBatchContainerRef$: BehaviorSubject<boolean> =
    new BehaviorSubject<any>(null);
  hasComparePlanTablePoppedUp$: BehaviorSubject<boolean> =
    new BehaviorSubject<any>(false);

  constructor(
    private apiService: PPApiService,
    private storage: StorageService,
    private appUrlService: AppUrlService,
    private firebaseAnalytics: FirebaseAnalyticsService,
    private globalService: GlobalService,
    private animatedDialog: NgDialogAnimationService,
    private showErrorService: ShowErrorService,
    private globalObjectService: GlobalObjectService,
    private dialog: MatDialog,
    private firebaseService: FirebaseAnalyticsService,
    private _router: Router,
    private moengage: MoengageService
  ) {}

  get getMahapackBatchesMapping() {
    return this.mahapackBatchesMapping$;
  }

  get _batchDetails$() {
    return this.batchDetails$;
  }

  get _batchPlanId$() {
    return this.batchPlanId$;
  }

  get _isPlusPlanPurchased$() {
    return this.isPlusPlanPurchased$;
  }

  setMahapackBatchesMapping(data: MahapackBatchMapping[]) {
    this.mahapackBatchesMapping$.next(data);
  }

  setBatchDetails(data: BatchDetailModel) {
    this.batchDetails$.next(data);
  }

  setVideoBackUrl(url: string) {
    this.storage.setValue(this.VIDEO_BACK_URL, url, 'string');
  }

  getVideoBackUrl() {
    return this.storage.getValue(this.VIDEO_BACK_URL, 'string');
  }

  removeVideoBackUrl() {
    return this.storage.removeValue(this.VIDEO_BACK_URL);
  }

  setListingToStorage(data: string) {
    return this.storage.setValue(this.BATCH_LISTING, data, 'string');
  }

  getListingFromStorage() {
    return this.storage.getValue(this.BATCH_LISTING, 'json');
  }

  removeBatchListingFromStorage() {
    return this.storage.removeValue(this.BATCH_LISTING);
  }

  getActiveOverviewSection() {
    return this.activeOverviewSection$.getValue();
  }

  setActiveOverviewSection(section: string) {
    this.activeOverviewSection$.next(section);
  }

  setBatchData(data: any) {
    this.storage.setValue(this.BATCH_DATA, data, 'json');
  }

  getBatchData() {
    return this.storage.getValue(this.BATCH_DATA, 'json');
  }

  setPurchasedBatches(data: string) {
    return this.storage.setValue(this.PURCHASED_BATCH_LISTING, data, 'string');
  }

  getPurchasedBatches() {
    return this.storage.getValue(this.PURCHASED_BATCH_LISTING, 'json');
  }

  setTagCanPaginate(data: any) {
    return this.storage.setValue(this.TAG_CAN_PAGINATE, data, 'string');
  }

  getTagCanPaginate() {
    return this.storage.getValue(this.TAG_CAN_PAGINATE, 'json');
  }

  removeTagCanPaginate() {
    return this.storage.removeValue(this.TAG_CAN_PAGINATE);
  }

  setListTab(tab: string) {
    return this.storage.setValue('BATCH_LIST_TAB', tab, 'string');
  }

  getListTab() {
    return this.storage.getValue('BATCH_LIST_TAB', 'string');
  }

  setMahapackDetailToStorage(data: BatchDetailModel) {
    this.storage.setValue('MAHAPACK_DETAIL', data, 'json');
  }

  getMahapackDetailFromStorage() {
    return this.storage.getValue('MAHAPACK_DETAIL', 'json');
  }

  removeMahapackDetailFromStorage() {
    return this.storage.removeValue('MAHAPACK_DETAIL');
  }

  setStudentMasterCloseCount(count: number) {
    return this.storage.setValue(this.studentMasterCloseCount, count, 'string');
  }

  getStudentMasterCloseCount() {
    return this.storage.getValue(this.studentMasterCloseCount, 'json');
  }

  async checkWatchLimit(
    batchId: any,
    subjectId: any,
    scheduleId: any,
    query: {}
  ) {
    query = query || {};

    if (!batchId) {
      throw { message: 'Batch ID is required' };
    }

    if (!subjectId) {
      throw { message: 'Subject ID is required' };
    }

    if (!subjectId) {
      throw { message: 'Schedule ID is required' };
    }

    const url = this.appUrlService.CHECK_WATCH_LIMIT(
      batchId,
      subjectId,
      scheduleId
    );

    const params = query || {};
    const options: PPApiOptions = {
      apiPath: url,
      params: new PPApiParams().appendAll(params),
    };
    return this.apiService.get(options).pipe(retry(1), catchError(handleError));
  }

  setTeacherData(data: any) {
    this.storage.setValue(this.TEACHER_DATA, data, 'json');
  }

  setTopicData(data: any) {
    this.storage.setValue(this.TOPIC_DATA, data, 'json');
  }

  setBatchConfigToLocal(data: BatchConfig) {
    this.storage.setValue('BATCH_CONFIG', data, 'json');
  }

  getBatchConfigFromLocal() {
    return this.storage.getValue('BATCH_CONFIG', 'json');
  }

  getTeachersData() {
    return this.storage.getValue(this.TEACHER_DATA, 'json');
  }

  async setDemoVideos(data: any) {
    await this.storage.setDemoVideos(data);
  }

  async getDemoVideosFromStorage() {
    return <string>this.storage.getDemoVideos();
  }

  removeTechersData() {
    this.storage.removeTeacherData();
  }

  removeBatchData() {
    this.storage.removeBatchData();
  }

  getAllBatches(query?: any) {
    const options: PPApiOptions = {
      apiPath: 'batch-service/v4/batches/my-batches',
      // config: {
      //   baseUrl: environment.baseUrlV3,
      //   tokenKey: 'pw-token',
      //   tokenType: PPApiTokenType.BEARER,
      //   storedIn: PPApiTokenStorageType.LOCAL_STORAGE,
      // },
      params: new PPApiParams().appendAll(query),
      // .append('organizationId', ORGANIZATION_ID),
    };
    return this.apiService.get<any>(options).pipe(
      map((res: any) => res.data),
      shareReplay(),
      catchError(handleError)
    );
  }

  getAllPurchasedBatches(query?: any) {
    const options: PPApiOptions = {
      apiPath: 'v3/batches/all-purchased-batches',
      // config: {
      //   baseUrl: environment.baseUrlV3,
      //   tokenKey: 'pw-token',
      //   tokenType: PPApiTokenType.BEARER,
      //   storedIn: PPApiTokenStorageType.LOCAL_STORAGE,
      // },
      params: new PPApiParams().appendAll(query),
      // .append('organizationId', ORGANIZATION_ID),
    };
    return this.apiService.get<any>(options).pipe(
      map((res: any) => res.data),
      shareReplay(),
      catchError(handleError)
    );
  }

  // getBatchSlug() {
  //   return this.storage.BATCH_DATA
  // }

  getSaarthiBanner(query?: any) {
    const options: PPApiOptions = {
      apiPath: 'v1/organizations/preferences?',
      params: new PPApiParams().appendAll(query),
    };
    return this.apiService.get<any>(options).pipe(
      map((res: any) => res.data),
      shareReplay(),
      catchError(handleError)
    );
  }

  getFilters() {
    const options: PPApiOptions = {
      apiPath: 'v2/batches/filter-tags',
    };
    return this.apiService.get<any>(options).pipe(
      map((res: any) => res.data),
      shareReplay(),
      catchError(handleError)
    );
  }

  getRecommendedBatches(query?: any) {
    const options: PPApiOptions = {
      apiPath: 'v2/batches/recommended-batches',
      params: new PPApiParams().appendAll(query),
    };
    return this.apiService.get<BatchDetailObject>(options).pipe(
      map((res) => res.data),
      tap(),
      catchError(handleError)
    );
  }

  getBatchPathshalaDetails(batchId: string) {
    const options: PPApiOptions = {
      apiPath: `v1/batches/${batchId}/pathshala-details`,
    };

    return this.apiService.get<PathshalaObject>(options).pipe(
      map((res) => res.data),
      catchError(handleError)
    );
  }

  getSearchList(data: any) {
    const options: PPApiOptions = {
      apiPath: `v3/batches/my-batches`,
      params: new PPApiParams().appendAll(data),
    };

    return this.apiService.get<BatchDetailObject>(options).pipe(
      map((res) => res.data),
      shareReplay(),
      debounceTime(1200),
      catchError(handleError)
    );
  }

  enrollStudent(batchId: string, params?: any) {
    params = params || {};
    const options: PPApiOptions = {
      apiPath: `v1/batches/${batchId}/enroll-student`,
      params: new PPApiParams().appendAll(params),
    };

    return this.apiService.get(options).pipe(
      map((res: any) => res.success),
      catchError(handleError)
    );
  }

  batchStatus(query?: any) {
    const options: PPApiOptions = {
      apiPath: this.appUrlService.GET_BATCH_STATUS(),
      params: new PPApiParams().appendAll(query),
    };
    return this.apiService.get(options).pipe(
      map((res: any) => res.data),
      tap(),
      retry(1),
      catchError(handleError)
    );
  }

  getFacultyDetails(query?: any) {
    const options: PPApiOptions = {
      apiPath: `v1/batches/${query.batchId}/${query.teacherId}/teacher-details`,
    };

    return this.apiService.get<FacultyRootObject>(options).pipe(
      map((res) => res.data),
      tap(),
      catchError(handleError)
    );
  }

  getBatchDetails(batchId: string, query?: any) {
    const q = query || {};

    const options: PPApiOptions = {
      apiPath: `v3/batches/${batchId}/details`,
      params: new PPApiParams().appendAll(q),
    };
    return this.apiService.get<CurrentBatchObject>(options).pipe(
      map((res) => res.data),
      tap(),
      catchError(handleError)
    );
  }

  getMentorshipUnreadMessage(batchId: string) {
    const options: PPApiOptions = {
      apiPath: `v3/mentorship/groups/${batchId}/get-conversation`,
    };
    return this.apiService.get<any>(options).pipe(
      map((res) => res.data),
      tap(),
      catchError(handleError)
    );
  }

  getUserGroupRole(batchId: string) {
    const options: PPApiOptions = {
      apiPath: `v3/mentorship/groups/check-user-role/${batchId}`,
    };
    return this.apiService.get<any>(options).pipe(
      map((res) => res.data),
      tap(),
      catchError(handleError)
    );
  }

  getBattleStatus(cohortId: string) {
    const options: PPApiOptions = {
      apiPath: `v3/test-service/battleground/users/exists/${cohortId}`,
    };
    return this.apiService.get<any>(options).pipe(
      map((res) => res.data),
      tap(),
      catchError(handleError)
    );
  }

  getSubjectTopics(query?: any) {
    const options: PPApiOptions = {
      apiPath: `v2/batches/${query.batchId}/subject/${query.subjectId}/topics`,
      params: new PPApiParams().append('page', query.page),
    };
    return this.apiService.get<TopicsObject>(options).pipe(
      map((res) => res.data),
      tap(),
      catchError(handleError)
    );
  }

  getBatchDemoVideos(batchId: string, query?: any) {
    query = query || {};
    const options: PPApiOptions = {
      apiPath: `v1/batches/${batchId}/demo-videos`,
      params: new PPApiParams().appendAll(query),
    };
    return this.apiService.get<DemoVideoModelObject>(options).pipe(
      map((res) => res.data),
      tap(),
      catchError(handleError)
    );
  }

  getTodaysSchedule(batchId: string) {
    const options: PPApiOptions = {
      apiPath: `v1/batches/${batchId}/todays-schedule`,
    };
    return this.apiService.get<TodayScheduleObject>(options).pipe(
      map((res) => res.data),
      tap(),
      catchError(handleError)
    );
  }

  getTodaysStudySchedule(batchId: string, params: any) {
    params = params || {};
    const options: PPApiOptions = {
      apiPath: `v1/batches/${batchId}/todays-schedule/`,
      params: new PPApiParams().appendAll(params),
    };
    return this.apiService.get<TodayScheduleObject>(options).pipe(
      map((res) => res.data),
      tap(),
      catchError(handleError)
    );
  }

  getTestCategories(params: any) {
    const options: PPApiOptions = {
      apiPath: 'v2/test-categories/tests',
      params: new PPApiParams().appendAll(params),
    };

    return this.apiService.get<TestCategoriesObject>(options).pipe(
      map((res) => res.data),
      tap(),
      catchError(handleError)
    );
  }

  getAnnouncements(batchId: string, params: any) {
    const options: PPApiOptions = {
      apiPath: `v1/batches/${batchId}/announcement`,
      params: new PPApiParams().appendAll(params),
    };

    return this.apiService.get<AnnoouncementModelObject>(options).pipe(
      map((res) => res.data),
      shareReplay(),
      catchError(handleError)
    );
  }

  getWeeklySchedule(query?: any) {
    const { batchId, subjectId, ...restQurey } = query;
    const options: PPApiOptions = {
      apiPath: `v1/batches/${query.batchId}/weekly-schedules`,
      params: new PPApiParams().appendAll(restQurey),
    };

    return this.apiService.get<WeeklyScheduleObject>(options).pipe(
      map((res) => res.data),
      tap(),
      catchError(handleError)
    );
  }

  getStudentFeeId(batchId: string, data: any) {
    const options: PPApiOptions = {
      apiPath: `v1/batches/${batchId}/get-fee-id`,
    };

    return this.apiService.post(data, options).pipe(
      map((res: any) => res.data),
      tap(),
      catchError(handleError)
    );
  }

  getTopicContents(batchSlug: string, subjectSlug: string, query: any) {
    if (!batchSlug) {
      throw { message: 'Batch is required' };
    }

    if (!subjectSlug) {
      throw { message: 'Subject is required' };
    }

    let url = this.appUrlService.GET_TOPIC_CONTENTS(batchSlug, subjectSlug);

    const options: PPApiOptions = {
      apiPath: url,
      params: new PPApiParams().appendAll(query),
    };

    return this.apiService
      .get<any>(options)
      .pipe(tap(), catchError(handleError));
  }

  getDppQuiz(batchSlug: string, subjectSlug: string, query: any) {
    if (!batchSlug) {
      throw { message: 'Batch is required' };
    }

    if (!subjectSlug) {
      throw { message: 'Subject is required' };
    }

    if (!query?.batchSubjectId) {
      throw { message: 'batchSubjectId is required' };
    }

    let url = this.appUrlService.GET_DPP_QUIZ(batchSlug, subjectSlug);

    const options: PPApiOptions = {
      apiPath: url,
      params: new PPApiParams().appendAll(query),
    };

    return this.apiService
      .get<any>(options)
      .pipe(tap(), catchError(handleError));
  }

  getCheckTestStatus(query: any) {
    let url = this.appUrlService.GET_CHECK_TEST();
    const options: PPApiOptions = {
      apiPath: url,
      params: new PPApiParams().appendAll(query),
    };
    return this.apiService.get<any>(options).pipe(
      map((res: any) => res?.data),
      tap(),
      catchError(handleError)
    );
  }

  getBGDppList(
    batchId: string,
    startDate: string,
    endDate: string,
    subjectId: string,
    page: number,
    limit: number
  ) {
    let url = this.appUrlService.GET_BG_DPP_LIST(
      batchId,
      startDate,
      endDate,
      subjectId,
      page,
      limit
    );

    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService
      .get<any>(options)
      .pipe(tap(), catchError(handleError));
  }

  getTags() {
    const options: PPApiOptions = {
      apiPath: 'v1/batches/filter-tags',
    };
    return this.apiService
      .get<any>(options)
      .pipe(tap(), catchError(handleError));
  }

  getRecentPractice(params: any) {
    const options: PPApiOptions = {
      apiPath: 'v3/test-service/tests/recent-tests',
      params: new PPApiParams().appendAll(params),
    };
    return this.apiService
      .get<any>(options)
      .pipe(tap(), catchError(handleError));
  }

  getRecentlyWatchedVideos(query: any) {
    const options: PPApiOptions = {
      apiPath: `v3/video-stats/recent-watch`,
      params: new PPApiParams().appendAll(query),
    };
    return this.apiService
      .get<any>(options)
      .pipe(tap(), catchError(handleError));
  }

  unrollStudent(query: any, body?: any) {
    const options: PPApiOptions = {
      apiPath: this.appUrlService.UNENROLL_STUDENT(query.batchId),
    };
    return this.apiService
      .put<any>(body, options)
      .pipe(tap(), catchError(handleError));
  }

  getBatchEnrolledFlag() {
    return this.isBatchEnrolled$.getValue();
  }

  setBatchEnrolledFlag(section: boolean) {
    this.isBatchEnrolled$.next(section);
  }

  getRecentlyWatchedVideoStats(query: any) {
    const options: PPApiOptions = {
      apiPath: `v3/video-stats/fetch-stats`,
      params: new PPApiParams().appendAll(query),
    };
    return this.apiService.get<RecentlyWatchedVideoStatsObject>(options).pipe(
      map((res: any) => res),

      tap(),
      catchError(handleError)
    );
  }

  getRecentlyWatchedVideoDetails(
    batchId: string,
    subjectId: string,
    scheduleId: string
  ) {
    const options: PPApiOptions = {
      apiPath: `v1/batches/${batchId}/subject/${subjectId}/schedule/${scheduleId}/schedule-details`,
    };
    return this.apiService.get<RecentlyWatchedVideoStatsObject>(options).pipe(
      map((res: any) => res.data),
      tap(),
      catchError(handleError)
    );
  }

  setUserSelectedExam(exam: string) {
    this.userSelectedExam$.next(exam);
  }

  getUserSelectedExam(): Observable<string> {
    return this.userSelectedExam$;
  }

  getBatchDataFromAPI() {
    return this.batchListingData$;
  }

  setBatchDataFromAPI(data: any) {
    this.batchListingData$.next(data);
  }

  checkBatchDataFromAPI() {
    return this.batchListingData$.getValue();
  }

  //MAHAPACK

  postBatchFormUserDetails(obj: any) {
    let url = this.appUrlService.BATCH_FORM_SUBMIT();

    const options: PPApiOptions = {
      apiPath: url,
      // params: new PPApiParams().appendAll(query),
    };

    return this.apiService
      .post<any>(obj, options)
      .pipe(catchError(handleError));
  }

  getCenterDetails(centerId: string) {
    let url = this.appUrlService.CENTER_DETAILS(centerId);
    const options: PPApiOptions = {
      apiPath: url,
      // params: new PPApiParams().appendAll(query),
    };

    return this.apiService.get<any>(options).pipe(catchError(handleError));
  }

  getMahapackMapping(query?: any) {
    let url = this.appUrlService.MAHAPACK_MAPPING();
    const options: PPApiOptions = {
      apiPath: url,
      params: new PPApiParams().appendAll(query),
    };

    return this.apiService.get<MahapackBatchMappingRoot>(options).pipe(
      map((res) => res.data),
      catchError(handleError)
    );
  }

  getBatchConfig(batchId: string, query: any = {}) {
    let url = this.appUrlService.BATCH_CONFIG(batchId);
    const options: PPApiOptions = {
      apiPath: url,
      params: new PPApiParams().appendAll(query),
    };

    return this.apiService.get<any>(options).pipe(catchError(handleError));
  }

  getTestimonial(batchId: string) {
    let url = this.appUrlService.TESTIMONIAL(batchId);
    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService.get<any>(options).pipe(catchError(handleError));
  }

  getMahapackDemoVideo(query: any) {
    let url = this.appUrlService.MAHAPACK_DEMO_VIDEO();
    const options: PPApiOptions = {
      apiPath: url,
      params: new PPApiParams().appendAll(query),
    };

    return this.apiService.get<any>(options).pipe(
      map((res) => res.data),
      tap(),
      catchError(handleError)
    );
  }

  getBatchListForDashBoard(query: any) {
    let url = this.appUrlService.GET_BATCHES_LIST_DASHBOARD();
    const options: PPApiOptions = {
      apiPath: url,
      params: new PPApiParams().appendAll(query),
    };

    return this.apiService.get<any>(options).pipe(
      map((res) => res.data),
      tap(),
      catchError(handleError)
    );
  }

  getTestListForDashBoard(query: any) {
    let url = this.appUrlService.GET_TEST_LIST_DASHBOARD();
    const options: PPApiOptions = {
      apiPath: url,
      params: new PPApiParams().appendAll(query),
    };

    return this.apiService.get<any>(options).pipe(
      map((res) => res.data),
      tap(),
      catchError(handleError)
    );
  }

  batchDescCtasEvent(batchDetail: BatchDetailModel, ctaType: string) {
    const data = {
      batch_name: batchDetail?.name,
      batch_id: batchDetail._id,
      batch_start_date: batchDetail.startDate,
      batch_end_date: batchDetail.endDate,
      class: batchDetail.class,
      exam: batchDetail.exam,
      batch_status: batchDetail?.isPurchased ? 'purchased' : 'not_purchased',
      cta_type: ctaType,
    };

    this.firebaseAnalytics.logEvents(
      BATCH_DISCOVERABILITY_EVENTS.BATCH_DESC_CTAS,
      data,
      true
    );
  }

  notifyUser(data: any) {
    const url = this.appUrlService.NOTIFY_USER();
    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService.post<SuccessResponse>(data, options).pipe(
      map((res) => res),
      retry(1),
      catchError(handleError)
    );
  }

  getMentorshipBanner(batchId: string) {
    let url = this.appUrlService.GET_MENTORSHIP_BANNERS(batchId);
    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService.get<any>(options).pipe(
      map((res) => res.data),
      tap(),
      catchError(handleError)
    );
  }

  async notifyUserForBatch(data: any) {
    try {
      const res = await lastValueFrom(this.notifyUser(data));
      if (res && res.success) {
        // this.batchData['notifyMeStatus'] = 'ALREADY_REGISTERED';
        return true;
      }
    } catch (e) {
      this.showErrorService.showError(e);
      return false;
    }
    return;
  }

  async enrollFreeBatch(
    batchId: string,
    batchName: string,
    showModal: boolean = true,
    batchNavigation: boolean = false
  ) {
    try {
      const response: any = await lastValueFrom(this.enrollStudent(batchId));
      if (response) {
        setTimeout(() => {
          this.userBatchStatusCheck();
        }, 1000);
        this.storage.removeComparisonPlanData();
        if (showModal) this.openSuccessFullyEnrolledModal(batchNavigation);
      }
    } catch (e) {
      this.showErrorService.showError(e);
    }
  }

  async userBatchStatusCheck() {
    const queryParams = {
      landingPage: true,
    };
    const batchStatusResponse: BatchStatus = await lastValueFrom(
      this.batchStatus(queryParams)
    );
    if (batchStatusResponse?.batchUserSegment === BatchUserSegment.FREE) {
      this.moengage.setUserBatchStatus(batchStatusResponse?.batchUserSegment);
    }
  }

  openSuccessFullyEnrolledModal(batchNavigation: boolean = false) {
    const dialogRef = this.animatedDialog.open(
      SuccessfullyEnrolledModalComponent,
      {
        panelClass: 'filter-new-modal',
        disableClose: true,
        position: {
          top: `0px`,
          right: `0px`,
        },
        data: batchNavigation,
        animation: {
          to: 'left',
          incomingOptions: {
            keyframeAnimationOptions: { easing: 'ease-in-out', duration: 500 },
          },
          outgoingOptions: {
            keyframeAnimationOptions: { easing: 'ease-in-out', duration: 500 },
          },
        },
      }
    );
  }

  buyNowClick(batchId: string, query?: any) {
    const q = query || {};
    let url = this.appUrlService.BUY_NOW(batchId);
    const option: PPApiOptions = {
      apiPath: url,
      params: new PPApiParams().appendAll(q),
    };

    return this.apiService.get<any>(option).pipe(
      map((res) => res.data),
      catchError(handleError)
    );
  }

  async onBuyNowClick(batchId: string) {
    const query = this.globalService.getCampaignData() || {};
    try {
      const res = await lastValueFrom(this.buyNowClick(batchId, query));
    } catch (error) {
      this.showErrorService.showError(error);
    }
  }

  getAddOnsList(batchId: string) {
    let url = this.appUrlService.GET_ADDONS_LIST(batchId);
    const query = {
      type: 'SAARTHI,KHAZANA',
    };
    const options: PPApiOptions = {
      apiPath: url,
      params: new PPApiParams().appendAll(query),
    };

    return this.apiService.get<any>(options).pipe(
      map((res: any) => res.data),
      catchError(handleError)
    );
  }

  // Zoom integration api
  getZoomMeetingLink(data: any) {
    const url = this.appUrlService.GET_ZOOM_MEETING_URL();
    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService.post(data, options).pipe(
      map((res: any) => res.data),
      tap(),
      catchError(handleError)
    );
  }

  async getZoomMeetingLinkData(query: any) {
    try {
      const res = await lastValueFrom(this.getZoomMeetingLink(query));
      if (res && res.length > 0) {
        this.globalObjectService.window?.open(res, '_blank');
      } else {
        this.globalService.showSnackBar('No zoom url found.');
      }
    } catch (error) {
      console.log(error);
    }
  }

  getOrdersDetails(orderId: string) {
    const url = this.appUrlService.GET_ORDER_DATA(orderId);
    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService.get<any>(options).pipe(
      map((res: any) => res.data),
      catchError(handleError)
    );
  }

  getOrderInvoice(orderId: string) {
    const url = this.appUrlService.GET_ORDER_INVOICE(orderId);
    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService.get<any>(options).pipe(
      map((res: any) => res.data),
      catchError(handleError)
    );
  }

  getComparePlans(batchId: string) {
    const url = this.appUrlService.GET_COMPARE_PLANS(batchId);

    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService.get<any>(options).pipe(
      map((res: any) => res.data),
      catchError(handleError)
    );
  }

  getFreeContent(batchId: string, query?: any) {
    query = query || {};
    const url = this.appUrlService.GET_FREE_CONTENT(batchId);
    const options: PPApiOptions = {
      apiPath: url,
      params: new PPApiParams().appendAll(query),
    };

    return this.apiService.get<TodayScheduleObject>(options).pipe(
      map((res: any) => res.data),
      catchError(handleError)
    );
  }

  setBatchPlanId(id: string) {
    this.batchPlanId$.next(id);
  }

  setIsPlausPlanPurchased(data: boolean) {
    this.isPlusPlanPurchased$.next(data);
  }

  setHasComparePlanTablePoppedUp(data: boolean) {
    this.hasComparePlanTablePoppedUp$.next(data);
  }

  get _hasComparePlanTablePoppedUp$() {
    return this.hasComparePlanTablePoppedUp$.getValue();
  }

  getBatchPlusBanner(batchId: string) {
    const url = this.appUrlService.GET_BATCH_PLUS_BANNER(batchId);

    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService.get<any>(options).pipe(
      map((res: any) => res.data),
      catchError(handleError)
    );
  }

  getBatchUpgradeBanner(batchId: string) {
    const url = this.appUrlService.GET_BATCH_UPGRADE_BANNER(batchId);

    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService.get<any>(options).pipe(
      map((res: any) => res.data),
      catchError(handleError)
    );
  }

  openBatchPlanComparisonTable(
    batchData: BatchDetailModel,
    source?: string,
    sourceCTA?: string,
    planId?: string,
    upgradeModalClose?: () => void,
    selectHighestPlan?: boolean,
    isLockedPopup?: boolean
  ) {
    const dialogRef = this.dialog.open(BatchPlusModalComponent, {
      width: '717px',
      panelClass: 'batch-plus-dialog',

      data: {
        batchData,
        source,
        openFromBatchListing: source === 'batch_listing' ? true : false,
        ...(sourceCTA && { source_cta: sourceCTA }),
        planId,
        ...(selectHighestPlan && { selectHighestPlan }),
        isLockedPopup,
      },
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (upgradeModalClose && res && res === BATCH_REDIRECTION_TYPE.BUY_NOW) {
        upgradeModalClose();
      }
    });
  }

  openBatchUpgradeModal(batchData: BatchDetailModel, upgradeData: BatchPlan) {
    const dialogRef = this.dialog.open(BatchUpgradePopUpComponent, {
      width: '480px',
      panelClass: 'batch-plus-upgrade',
      disableClose: true,

      data: {
        upgradeData,
        batchData,
      },
    });
  }

  getTestPressRediredctionUrl(batchId: string) {
    const url = this.appUrlService.GET_TEST_PRESS_REDIRECTION_URL();

    const options: PPApiOptions = {
      apiPath: url,
      params: new PPApiParams().append('batchId', batchId),
    };

    return this.apiService.get<any>(options).pipe(
      map((res: any) => res.data),
      catchError(handleError)
    );
  }

  getActiveTabs(batchId: string, query: any) {
    const url = this.appUrlService.GET_ACTIVE_TABS(batchId);

    const options: PPApiOptions = {
      apiPath: url,
      params: new PPApiParams().appendAll(query),
    };

    return this.apiService.get<any>(options).pipe(
      map((res: any) => res.data),
      catchError(handleError)
    );
  }

  checkSubjectExistInBatch(subjectId: string, batchData: BatchDetailModel) {
    const batchSubjectId =
      batchData.subjects.find((res) => res.slug === subjectId)?._id || '';

    return !!batchSubjectId;
  }

  checkSameBatch(batchData: BatchDetailModel, batchId: string) {
    return batchData?._id === batchId || batchData?.slug === batchId;
  }

  subjectBatchDataCheck(
    batchData: BatchDetailModel,
    batchId: string,
    subjectId: string
  ) {
    const isSame = this.checkSameBatch(batchData, batchId);
    if (!isSame) {
      this.returnToDescription(batchId);
    } else {
      const isSubject = this.checkSubjectExistInBatch(subjectId, batchData);
      if (!isSubject) {
        this.returnToDescription(batchId);
      }
    }
  }

  returnToDescription(batchId: string) {
    const message = 'You can access one batch at a time in same browser.';
    this.globalService.showSnackBar(message);
    this._router.navigate([`/batches/${batchId}/batch-overview`]);
  }

  snoozeBatchPlanUpgrage(batchId: string) {
    const data = {
      snoozed: true,
    };

    const url = this.appUrlService.SNOOZE_BATCH_PLAN_UPGRADE(batchId);

    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService.put<any>(data, options).pipe(
      map((res: any) => res),
      catchError(handleError)
    );
  }

  snoozeBatchUpgragePopUpSnooze(batchId: string) {
    const data = {
      snoozed: true,
    };
    const url = this.appUrlService.SNOOZE_BATCH_UPGRADE_POP_UP_SNOOZE(batchId);

    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService.put<any>(data, options).pipe(
      map((res: any) => res),
      catchError(handleError)
    );
  }

  /**
   * A description of the entire function.
   *This TypeScript code defines a function
   getBatchClubbingOfferings that takes a tabId parameter
    of type string. It constructs a URL based on the tabId,
    creates API options, makes a GET request using the
    apiService, maps the response to extract the data,
    and handles errors using a handleError function.
    The function returns an observable of type
    ClubbingOfferingApiResponse.
   * @param {string} tabId - description of parameter
   * @return {Observable<ClubbingOfferingApiResponse>} description of return value
   */
  getBatchClubbingOfferings(tabId: string) {
    const url = this.appUrlService.GET_BATCH_CLUBBING_OFFERINGS(tabId);
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService.get<ClubbingOfferingApiResponse>(options).pipe(
      map((res) => res.data),
      catchError(handleError)
    );
  }

  /**
   * A description of the entire function.
   * This TypeScript function getBatchClubbingOfferingsData
   *  takes in a batchId of type string and a query of any
   *  type. It makes an HTTP GET request to a specific URL
   *  generated based on the batchId, then returns an
   *  Observable of the data received from the API call
   *  after mapping the response data and handling any errors
   *  that may occur.
   * @param {string} batchId - description of parameter
   * @param {any} query - description of parameter
   * @return {Observable<any>} description of return value
   */
  getBatchClubbingOfferingsData(batchId: string, query: any) {
    const url = this.appUrlService.GET_BATCH_CLUBBING_OFFERINGS_DATA(batchId);
    const options: PPApiOptions = {
      apiPath: url,
      params: new PPApiParams().appendAll(query),
    };
    return this.apiService.get<any>(options).pipe(
      map((res) => res.data),
      catchError(handleError)
    );
  }

  /**
   * Asynchronously retrieves batch details data.
   *This code snippet defines an asynchronous function
    getBatchDetailsData that retrieves details of a batch
    using the provided batchId. It makes an asynchronous
    API call to get the batch details, creates a
    BatchDetailModel object from the response, and sets
    the batch data. If there is an error during the process,
    it calls a function to display the error.
   * @param {string} batchId - The ID of the batch
   * @return {void}
   */
  async getBatchDetailsData(batchId: string) {
    try {
      const res = await lastValueFrom(this.getBatchDetails(batchId));
      if (res) {
        const batchData = new BatchDetailModel(res);
        this.setBatchData(batchData);
      }
    } catch (error) {
      this.showErrorService.showError(error);
    }
  }

  /**
   * A function that handles batch clubbing event.
   *This code defines a function batchClubbingEvent
    that handles a batch clubbing event. It takes
    in parameters such as batch details, event name,
    event source, group ID, action, and a flag indicating
    if it's a store. It constructs data based on these
    parameters, including plan data, batch ID, name,
    purchased status, and more. Finally, it logs the
    event to Firebase Analytics with the provided event
    name and data.
   * @param {BatchDetailModel} batchDetail - the batch detail model
   * @param {string} eventName - the name of the event
   * @param {string} source - the event source (default is '')
   * @param {string} groupid - the group id (default is '')
   * @param {string} action - the action (default is '')
   * @param {boolean} isStore - flag indicating if it's a store (default is false)
   */
  async batchClubbingEvent(
    batchDetail: BatchDetailModel,
    eventName: string,
    source: string = '',
    saarthiPlan: string = '',
    action: string = '',
    isStore: boolean = false,
    time_stamp_mSec?: any
  ) {
    console.log(batchDetail, 'batchDetail');

    const current_plan = await this.getCurrentPlan(batchDetail);

    const data: any = {
      source: source,
      batch_id: batchDetail?._id ?? '',
      batch_name: batchDetail?.name ?? '',
      is_batch_purchased: batchDetail?.isPurchased ?? false,
      is_plus_enabled: batchDetail?.isBatchPlusEnabled ?? false,
      current_plan: current_plan,
      cohort_id: this.storage.getValue(STORAGE_ENUM.COHORT_ID, 'string'),
      user_type:
        this.globalService.getIsPathShalaFromStorage() === 'true'
          ? 'offline'
          : 'online',
      time_stamp_mSec: time_stamp_mSec,
    };
    if (saarthiPlan) {
      data['saarthi_plan'] = saarthiPlan;
    }
    if (action) {
      data['action'] = action;
    }
    if (isStore) {
      delete data['is_batch_purchased'];
      delete data['is_plus_enabled'];
    }

    this.firebaseAnalytics.logEvents(eventName, data, true, true, true, true);
  }

  async getCurrentPlan(batchDetail: BatchDetailModel) {
    const planData = this.storage.getComparisonPlanData() || {};
    const purchasedPlans = planData?.plans?.filter(
      (plan: any) => plan?.isPlanPurchased
    );

    return purchasedPlans?.length
      ? purchasedPlans[purchasedPlans.length - 1]?.title
      : batchDetail?.name;
  }

  openLeadFiltrationForm({
    formId,
    batchId,
    onSuccessCB,
    onCloseCB
  }: {
    formId: string;
    batchId: string;
    onSuccessCB: () => void
    onCloseCB: () => void
  }) {
    const dialogRef = this.animatedDialog.open(
      LeadFormsComponent,
      {
        panelClass: 'lead-filter-slider',
        disableClose: true,
        position: {
          top: `0px`,
          right: `0px`,
        },
        data: {
          formId,
          batchId,
          onSuccessCB,
          onCloseCB
        },
        animation: {
          to: 'left',
          incomingOptions: {
            keyframeAnimationOptions: { easing: 'ease-in-out', duration: 500 },
          },
          outgoingOptions: {
            keyframeAnimationOptions: { easing: 'ease-in-out', duration: 500 },
          },
        },
      }
    );
  }
}

export class NotesModel {
  _id: string;
  isFree: boolean;
  isDPPNotes: boolean;
  status: string;
  date: string;
  startTime: string;
  homeworkIds: Array<HomeworkId> = [];
  dRoomId: string;
  isBatchDoubtEnabled: boolean;

  constructor(data: any) {
    this._id = data._id || '';
    this.isFree = data.isFree || false;
    this.isDPPNotes = data.isDPPNotes || false;
    this.status = data.status || '';
    this.date = data.date || '';
    this.startTime = data.startTime || '';
    if (data && data.homeworkIds && data.homeworkIds.length > 0) {
      const list: Array<HomeworkId> = [];
      data.homeworkIds.forEach((el: any) => {
        list.push(new HomeworkId(el));
      });
      this.homeworkIds = [...list];
    }
    this.dRoomId = data.dRoomId || '';
    this.isBatchDoubtEnabled = data.isBatchDoubtEnabled || false;
  }
}

export class AddOn {
  _id?: string;
  displayOrder?: number;
  status?: string;
  type?: string;
  typeId: string;
  description?: string;
  isBest?: boolean;
  batchId?: string;
  organizationId?: string;
  createdAt?: string;
  updatedAt?: string;
  saarthiPlanList?: any[];
  __v?: number;
  addOnTag?: any;
  enabledPackageIds?: string[];

  constructor(data: any) {
    this._id = data._id || '';
    this.displayOrder = data.displayOrder || 0;
    this.status = data.status || '';
    this.type = data.type || '';
    this.typeId = data.typeId || '';
    this.description = data.description || '';
    this.isBest = data.isBest || false;
    this.batchId = data.batchId || '';
    this.organizationId = data.organizationId || '';
    this.createdAt = data.createdAt || '';
    this.updatedAt = data.updatedAt || '';
    this.saarthiPlanList = data.saarthiPlanList || [];
    this.__v = data.__v || 0;
    this.addOnTag = data.addOnTag || '';
    this.enabledPackageIds = data.enabledPackageIds || [];
  }
}

export enum BATCH_ENUMS {
  UPGRADE_BANNER = 'upgrade_banner',
  FBT_UPGRADE = 'fbt_upgrade',
}

enum BATCH_REDIRECTION_TYPE {
  SELECTED_PLAN = 'SELECTED_PLAN',
  DEMO_VIDEOS = 'DEMO_VIDEOS',
  BUY_NOW = 'BUY_NOW',
  BACK = 'BACK',
}

export enum ApplicationTypes {
  LEAD_FORM = 'LEAD_FORM',
}
