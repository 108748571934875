import { FileModal } from '../../../common/services/base/base.modal';
import { TestList } from '../../tests/tests.model';
import {
  Content,
  ViewAllResults,
} from '../../library/library-subject/library-subject.model';
import { CreatedByModel } from 'src/app/common/services/doubts/doubt.model';
import { isBefore, isFuture } from 'date-fns';
import isAfter from 'date-fns/isAfter';

export interface Fee {
  amount: number;
  tax: number;
  discount: number;
  total: number;
}

export interface FileId {
  _id: string;
  baseUrl: string;
  key: string;
  name: string;
}

export interface Schedule {
  _id: string;
  day: string;
  startTime: Date;
  endTime: Date;
}

export class ImageId {
  baseUrl: string;
  name: string;
  key: string;
  _id: string;
  url: string;
  constructor(data: any) {
    data = data || {};
    this.baseUrl = data.baseUrl || '';
    this.name = data.name || '';
    this.key = data.key || '';
    this._id = data._id || '';
    this.url = this.baseUrl + this.key;
  }
}

export interface TeacherId {
  _id: string;
  firstName: string;
  lastName: string;
  imageId: ImageId;
  qualification: string;
  url: string;
}

export interface Subject {
  _id: string;
  fileId: FileId;
  imageId: ImageId;
  subject: string;
  schedules: Schedule[];
  teacherIds: TeacherId[];
  slug: string;
  subjectId: string;
  tagCount: number;
}

export interface BatchPdf {
  _id: string;
  baseUrl: string;
  name: string;
  key: string;
}

export interface Meta {
  _id: string;
  key: string;
  value: string;
  displayOrder: number;
}

export interface StudyMaterial {
  _id: string;
  type: string;
  typeId: string;
}

export interface Program {
  _id: string;
  name: string;
  board: string;
  class: string;
  exam: string[];
  language: string;
}

// export class BatchDetailModel {
//   _id: string;
//   type: string;
//   name: string;
//   byName?: any;
//   description: string;
//   batchCode: string;
//   startDate: Date;
//   endDate: Date;
//   programId: string;
//   fee: Fee;
//   isPurchased: boolean;
//   isBlocked: boolean;
//   isChatBlocked: boolean;
//   subjects: Subject[];
//   isAvailableFromPoints: boolean;
//   maxWalletPoint: number;
//   registrationStartDate: Date;
//   registrationEndDate: Date;
//   previewImage: FileModal;
//   batchPdf: BatchPdf;
//   previewVideoType: string;
//   previewVideoUrl?: any;
//   previewVideoId?: any;
//   faqCat: string;
//   attachTestCat: boolean;
//   testCat: string;
//   testCats: string[];
//   meta: Meta[];
//   previewVideoDetails: any;
//   enableStudyMaterial: boolean;
//   enableCommunity: boolean;
//   studyMaterials: StudyMaterial[];
//   markedAsNew: boolean;
//   isClassLive: boolean;
//   isPathshala: boolean;
//   program: Program;
//   slug: string;
//   exam: string[];
//   khazanaProgramId: string;
//   constructor(data: any) {
//     data = data || {};
//     this._id = data._id || '';
//     this.type = data.type || '';
//     this.name = data.name || '';
//     this.description = data.description || '';
//     this.batchCode = data.batchCode || '';
//     this.programId = data.programId || '';
//     this.previewVideoType = data.previewVideoType || '';
//     this.faqCat = data.faqCat || '';
//     this.testCat = data.testCat || '';
//     this.maxWalletPoint = data.maxWalletPoint || 0;
//     this.isPurchased = data.isPurchased || false;
//     this.isBlocked = data.isBlocked || false;
//     this.isAvailableFromPoints = data.isAvailableFromPoints || false;
//     this.attachTestCat = data.attachTestCat || false;
//     this.enableStudyMaterial = data.enableStudyMaterial || false;
//     this.enableCommunity = data.enableCommunity || false;
//     this.markedAsNew = data.markedAsNew || false;
//     this.isClassLive = data.isClassLive || false;
//     this.isPathshala = data.isPathshala || false;
//     this.startDate = data.startDate || '';
//     this.endDate = data.endDate || '';
//     this.registrationStartDate = data.registrationStartDate || '';
//     this.registrationEndDate = data.registrationEndDate || '';
//     this.fee = data.feeId || data.fee || {};
//     this.previewImage = new FileModal(data.previewImage || {});
//     this.batchPdf = data.batchPdf || {};
//     this.program = data.program || {};
//     this.subjects = data.subjects || [];
//     this.testCats = data.testCats || [];
//     this.meta = data.meta || [];
//     this.studyMaterials = data.studyMaterials || [];
//     this.slug = data.slug || '';
//     this.exam = data.exam || [];
//     this.previewVideoDetails = data.previewVideoDetails || {};
//     this.isChatBlocked = data.isChatBlocked || false;
//     this.khazanaProgramId = data.khazanaProgramId || '';
//   }
// }

export interface Teacher {
  name: string;
  _id: string;
}

export class BatchDetailConfig {
  enableBatchReferAndEarn: boolean;
  isVidyapeeth: boolean;
  isFormEnabled: boolean;
  isSubjective: boolean;
  isStartDateEnabled: boolean;
  isEndDateEnabled: boolean;
  isTeacherNameEnabled: boolean;
  referAndEarnExpireAt: string;
  defaultFbt: string;
  defaultAddon: string;
  fbtTitle: string;
  isAddonVisible: boolean;
  addonsTitle: string;
  addonsDescription: string;
  fbtPageTitle: string;
  isPremium: boolean;
  is_combo_mandatory: boolean;
  enableDpp: boolean;
  enableDppPdfs: boolean;
  enableDppVideos: boolean;
  enableNotes: boolean;
  tagImage: string;
  offlineOfferingbatchTag: string;
  isFormFilled: boolean;
  batchActivationFormLink: string;
  isAccessRevoked: boolean;
  contactSupport: boolean;
  emiCreation: EmiCreationEnum;
  paymentType: string;

  constructor(data: any) {
    data = data || {};
    this.enableBatchReferAndEarn = data.enableBatchReferAnd || false;
    this.isVidyapeeth = data.isVidyapeeth || false;
    this.isFormEnabled = data.isFormEnabled || false;
    this.isSubjective = data.isSubjective || false;
    this.isStartDateEnabled = data.isStartDateEnabled || false;
    this.isEndDateEnabled = data.isEndDateEnabled || false;
    this.isTeacherNameEnabled = data.isTeacherNameEnabled || false;
    this.isAddonVisible = data.isAddonVisible || false;
    this.referAndEarnExpireAt = data.referAndEarnExpireAt || '';
    this.defaultFbt = data.defaultFbt || '';
    this.defaultAddon = data.defaultAddon || '';
    this.fbtTitle = data.fbtTitle || '';
    this.addonsTitle = data.addonsTitle || '';
    this.addonsDescription = data.addonsDescription || '';
    this.fbtPageTitle = data.fbtPageTitle || '';
    this.isPremium = data.isPremium || false;
    this.is_combo_mandatory = data.is_combo_mandatory || false;
    this.tagImage = data.tagImage || '';
    this.offlineOfferingbatchTag = data.offlineOfferingbatchTag || '';
    this.isFormFilled = data?.isFormFilled || false;
    this.batchActivationFormLink = data?.batchActivationFormLink || '';
    this.isAccessRevoked = data?.isAccessRevoked || false;
    this.contactSupport = data?.contactSupport || false;
    this.emiCreation = data?.emiCreation || null;
    this.paymentType = data?.paymentType || '';
    if (data.hasOwnProperty('enableDpp')) this.enableDpp = data.enableDpp;
    else this.enableDpp = true;
    if (data.hasOwnProperty('enableDppPdfs'))
      this.enableDppPdfs = data.enableDppPdfs;
    else this.enableDppPdfs = true;
    if (data.hasOwnProperty('enableDppVideos'))
      this.enableDppVideos = data.enableDppVideos;
    else this.enableDppVideos = true;
    if (data.hasOwnProperty('enableNotes')) this.enableNotes = data.enableNotes;
    else this.enableNotes = true;
  }
}
export class BatchDetailModel {
  _id: string;
  type: string;
  name: string;
  byName: string;
  description: string;
  batchCode: string;
  startDate: Date;
  endDate: Date;
  programId: string;
  fee: Fee;
  isAskSaarthiEnabled: boolean;
  isPurchased: boolean;
  isBlocked: boolean;
  isChatBlocked: boolean;
  subjects: Subject[];
  isAvailableFromPoints: boolean;
  isSelfLearning: boolean;
  maxWalletPoint: number;
  registrationStartDate: Date;
  registrationEndDate: Date;
  previewImage: FileModal;
  batchPdf: BatchPdf;
  previewVideoType: string;
  previewVideoUrl?: any;
  previewVideoId?: any;
  faqCat: string;
  attachTestCat: boolean;
  testCat: string;
  testCats: string[];
  meta: Meta[];
  orientationClassBanner: ImageId;
  previewVideoDetails: any;
  enableStudyMaterial: boolean;
  enableCommunity: boolean;
  enableMentorship: boolean;
  studyMaterials: StudyMaterial[];
  markedAsNew: boolean;
  isClassLive: boolean;
  isPathshala: boolean;
  program: Program;
  slug: string;
  exam: string[];
  khazanaProgramId: string;
  class: string;
  board: string;
  language: string;
  isActive: boolean;
  isNeet: string;
  teachers: Teacher[];
  config: BatchDetailConfig;
  dRoomId: string;
  packValidity: number;
  shortDescription: string;
  batchExpiry: Date;
  metaText: string;
  notifyMeStatus: string;
  enableCommunityWeb: boolean;
  discountExpiryDate?: any;
  discountLabel?: any;
  discountValue?: any;
  plan: string;
  isSatExamEnable: boolean;
  cohortId: string;
  isInfinitePracticeEnabled: boolean;
  infinitePracticeVideoType: string;
  infinitePracticeVideoUrl: string;
  fomoIcons: string[];
  isBatchPlusEnabled: boolean;
  testPress: TestPress;

  masterBatchId?: string;
  webinarId: string;
  tagImage: string;
  batchPdfUrl: string;
  template: string;
  isBatchClubbing: boolean;
  isPlanEnabled: boolean;
  isSahayakInfinitePracticeEnabled?: boolean;
  formId: string;
  constructor(data: any) {
    data = data || {};
    this._id = data._id || '';
    this.type = data.type || '';
    this.name = data.name || '';
    this.byName = data.byName || '';
    this.description = data.description || '';
    this.batchCode = data.batchCode || '';
    this.programId = data.programId || '';
    this.previewVideoType = data.previewVideoType || '';
    this.faqCat = data.faqCat || '';
    this.language = data.language || '';
    this.testCat = data.testCat || '';
    this.maxWalletPoint = data.maxWalletPoint || 0;
    this.isAskSaarthiEnabled = data.isAskSaarthiEnabled || false;
    this.isPurchased = data.isPurchased || false;
    this.isBlocked = data.isBlocked || false;
    this.isAvailableFromPoints = data.isAvailableFromPoints || false;
    this.attachTestCat = data.attachTestCat || false;
    this.enableStudyMaterial = data.enableStudyMaterial || false;
    this.enableCommunity = data.enableCommunity || false;
    this.enableMentorship = data.enableMentorship || false;
    this.markedAsNew = data.markedAsNew || false;
    this.isClassLive = data.isClassLive || false;
    this.isPathshala = data.isPathshala || false;
    this.isSelfLearning = data.isSelfLearning || false;
    this.startDate = data.startDate || '';
    this.endDate = data.endDate || '';
    this.registrationStartDate = data.registrationStartDate || '';
    this.registrationEndDate = data.registrationEndDate || '';
    this.fee = data.feeId || data.fee || {};
    this.previewImage = new FileModal(data.previewImage || {});
    this.orientationClassBanner =
      new ImageId(data.orientationClassBanner) || new ImageId({});
    this.batchPdf = data.batchPdf || {};
    this.program = data.program || {};
    this.subjects = data.subjects || [];
    this.testCats = data.testCats || [];
    this.meta = data.meta || [];
    this.studyMaterials = data.studyMaterials || [];
    this.slug = data.slug || '';
    this.exam = data.exam || [];
    this.previewVideoDetails = data.previewVideoDetails || {};
    this.isChatBlocked = data.isChatBlocked || false;
    this.khazanaProgramId = data.khazanaProgramId || '';
    this.class = data.class || '';
    this.board = data.board || '';
    this.language = data.language || '';
    this.isNeet = data.isNeet || '';
    this.isActive = data.isActive || false;
    this.teachers = data.teachers || [];
    this.config =
      new BatchDetailConfig(data.config) || new BatchDetailConfig({});
    this.dRoomId = data.dRoomId || '';
    this.previewVideoUrl = data.previewVideoUrl || '';
    this.packValidity = data.packValidity || 0;
    this.shortDescription = data.shortDescription || '';
    this.batchExpiry = data.batchExpiry || '';
    this.metaText = data.metaText || '';
    this.notifyMeStatus = data.notifyMeStatus || '';
    this.enableCommunityWeb = data.enableCommunityWeb || false;
    this.discountExpiryDate = data.discountExpiryDate || '';
    this.discountLabel = data.discountLabel || '';
    this.discountValue = data.discountValue || 0;
    this.plan = data.plan || '';
    this.isSatExamEnable = data.isSatExamEnable || false;
    this.cohortId = data.cohortId || '';
    this.isInfinitePracticeEnabled = data.isInfinitePracticeEnabled || false;
    this.infinitePracticeVideoType = data.infinitePracticeVideoType || '';
    this.infinitePracticeVideoUrl = data.infinitePracticeVideoUrl || '';
    this.isBatchPlusEnabled = data.isBatchPlusEnabled || false;
    this.fomoIcons = data.fomoIcons || [];
    this.testPress = data.testPress || new TestPress({});
    this.masterBatchId = data.masterBatchId || '';
    this.webinarId = data.webinarId || '';
    this.batchPdfUrl = data.batchPdfUrl || '';
    this.template = data.template || '';
    this.isBatchClubbing = data.isBatchClubbing || false;
    this.isPlanEnabled = data.isPlanEnabled || false;
    this.isSahayakInfinitePracticeEnabled =
      data.isSahayakInfinitePracticeEnabled || false;
    this.formId = data.formId || '';
  }
}

export class ReferEarnModal {
  key: string;
  id: string;
  batchMessageReferAndEarn: string;
  batchPointsPerRefer: number;
  batchPointsPerReferToReferer: number;
  batchReferAndEarnStatus: boolean;
  images: Array<FileModal> = [];

  constructor(data: any) {
    data = data || {};
    this.id = data._id || '';
    this.key = data.key || '';
    if (data && data.value && Object.keys(data.value).length > 0) {
      this.batchMessageReferAndEarn = data.value.batchMessageReferAndEarn
        ? data.value.batchMessageReferAndEarn || ''
        : '';
      this.batchPointsPerRefer = data.value.batchPointsPerRefer
        ? data.value.batchPointsPerRefer || 0
        : 0;
      this.batchPointsPerReferToReferer = data.value
        .batchPointsPerReferToReferer
        ? data.value.batchPointsPerReferToReferer || 0
        : 0;
      this.batchReferAndEarnStatus = data.value.batchReferAndEarnStatus
        ? data.value.batchReferAndEarnStatus || false
        : false;
      if (
        data &&
        data.value &&
        data.value.images &&
        data.value.images.length > 0
      ) {
        data.value.images.forEach((item: any) => {
          this.images.push(new FileModal(item));
        });
      }
      // this.image = data.value.image ? data.value.image || 'assets/refer_earn/refer_default.webp' : 'assets/refer_earn/refer_default.webp';
    }
  }
}

export class CouponResModel {
  coupon: Coupon;
  totalAmount: number;
  discountedAmount: number;
  finalAmount: number;
  referrerCoinsDiscount: number;
  refereeAmountType: string;
  refereeCashBackAmount: number;
  referralCode: string;

  constructor(data: any) {
    data = data || {};
    this.coupon = new Coupon(data.coupon || {});
    this.totalAmount = data.totalAmount || 0;
    this.discountedAmount = data.discountedAmount || 0;
    this.finalAmount = data.finalAmount || 0;
    this.referrerCoinsDiscount = data.referrerCoinsDiscount || 0;
    this.refereeAmountType = data.refereeAmountType || '';
    this.refereeCashBackAmount = data.refereeCashBackAmount || 0;
    this.referralCode = data.referralCode || 0;
  }
}

export class Coupon {
  _id: string;
  type: string;
  offerType: string;
  currentUsage: number;
  status: string;
  packageIds: Array<any> = [];
  name: string;
  description: string;
  couponCode: string;
  startDate: string;
  endDate: string;
  usagePerCandidate: number;
  minPurchaseAmount: number;
  maxDiscountAmount: number;
  organization: string;
  couponAmount: number;
  maxUsage: number;
  createdAt: string;
  updatedAt: string;

  constructor(data: any) {
    data = data || {};
    this._id = data._id || '';
    this.type = data.type || '';
    this.offerType = data.offerType || '';
    this.currentUsage = data.currentUsage || 0;
    this.status = data.status || '';
    if (data && data.packageIds && data.packageIds.length > 0) {
      data.packageIds.forEach((el: any) => {
        this.packageIds.push(el);
      });
    }
    this.name = data.name || '';
    this.description = data.description || '';
    this.couponCode = data.couponCode || '';
    this.startDate = data.startDate || '';
    this.endDate = data.endDate || '';
    this.usagePerCandidate = data.usagePerCandidate || '';
    this.minPurchaseAmount = data.minPurchaseAmount || '';
    this.maxDiscountAmount = data.maxDiscountAmount || '';
    this.organization = data.organization || '';
    this.couponAmount = data.couponAmount || '';
    this.maxUsage = data.maxUsage || '';
    this.createdAt = data.createdAt || '';
    this.updatedAt = data.updatedAt || '';
  }
}

export enum EmiCreationEnum {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export interface BatchDetailObject {
  success: boolean;
  data: BatchDetailModel[];
}

export interface CurrentBatchObject {
  success: boolean;
  data: BatchDetailModel;
}

/**
 * Announcment interface
 */
export interface Attachment {
  baseUrl: string;
  _id: string;
  key: string;
  name: string;
  createdAt: Date;
}

export interface CreatedBy {}

export interface announcement {
  _id: string;
  batchId: string;
  announcement: string;
  attachment: Attachment;
  createdBy: CreatedBy;
  created: string;
  createdAt: Date;
}

export interface announcementObject {
  success: boolean;
  data: announcement[];
}

/**
 * Teacher Interface
 */
export class TeacherModel {
  firstName: string;
  lastName: string;
  imageId: FileModal;
  experience: string;
  sub: string;
  _id: string;
  featuredLine: string;
  tags: [];
  about: string;
  qualification: string;
  subject: string;

  constructor(props: any) {
    props = props || {};
    this._id = props._id || '';
    this.experience = props.experience || '';
    this.firstName = props.firstName || '';
    this.lastName = props.lastName || '';
    this.sub = props.subName || '';
    if (props && props.imageId) {
      this.imageId = new FileModal(props.imageId) || new FileModal({});
    } else {
      this.imageId = new FileModal({});
    }
    this.about = props.about || '';
    this.qualification = props.qualification || '';
    if (props && props.tags && props.tags.length > 0) {
      this.tags = props.tags;
    }
    this.featuredLine = props.featuredLine || '';
    this.subject = props.subject || '';
  }
}

export interface Slide {
  solutionUrl: any[];
  solutionVerified: boolean;
  _id: string;
  serialNumber: number;
  name: string;
  solutionTxt: string;
  img: string;
  solutionText?: any;
  solutionUrls: any[];
}

export interface DemoVideoDetails {
  id: string;
  name: string;
  image: string;
  videoUrl: string;
  duration: string;
  status: string;
  types: string[];
}

export interface DemoVideoModel {
  dRoomId: string;
  _id: string;
  restrictedSchedule: boolean;
  restrictedTime: number;
  restrictedTimes: number;
  commentCount: number;
  urlType: string;
  exerciseIds: string[];
  homeworkIds: string[];
  tags: string[];
  isFree: boolean;
  slideReadyToUse: boolean;
  isChatEnabled: boolean;
  isDoubtEnabled: boolean;
  isCommentDisabled: boolean;
  isDPPVideos: boolean;
  isDPPNotes: boolean;
  status: string;
  batchSubjectId: string;
  topic: string;
  date: Date;
  startTime: Date;
  endTime: Date;
  url: string;
  day: string;
  createdAt: Date;
  updatedAt: Date;
  slides: Slide[];
  timeline: any[];
  __v: number;
  meetingId: string;
  meetingPassword: string;
  ratingCount: number;
  isPathshala: boolean;
  teachers: any[];
  videoDetails: DemoVideoDetails;
  videoType: string;
}

export interface DemoVideoModelObject {
  data: DemoVideoModel[];
  success: boolean;
}

export class AttachmentId {
  baseUrl: string;
  _id: string;
  name: string;
  key: string;
  organization: string;
  createdAt: string;
  url: string;

  constructor(data: any) {
    this.baseUrl = data.baseUrl || '';
    this._id = data._id || '';
    this.name = data.name || '';
    this.key = data.key || '';
    this.organization = data.organization || '';
    this.createdAt = data.createdAt || '';
    this.url = this.baseUrl + this.key;
  }
}

export class HomeworkId {
  attachmentIds: Array<AttachmentId> = [];
  _id: string;
  topic: string;
  note: string;
  submissionDate: string;
  status: string;
  actions: Array<string> = [];
  isFree: boolean | false;

  constructor(data: any) {
    this._id = data._id || '';
    this.topic = data.topic || '';
    this.note = data.note || '';
    if (data && data.attachmentIds && data.attachmentIds.length > 0) {
      const list: Array<AttachmentId> = [];
      data.attachmentIds.forEach((el: any) => {
        list.push(new AttachmentId(el));
      });
      this.attachmentIds = [...list];
    }

    if (data && data.isFree) {
      this.isFree = data.isFree;
    } else {
      this.isFree = false;
    }
    this.submissionDate = data.submissionDate || '';
    this.status = data.status || '';
    this.actions = data.actions;
  }
}

export class Tag {
  _id: string;
  name: string;

  constructor(tag: any) {
    this._id = tag._id || '';
    this.name = tag.name || '';
  }
}

export interface SubjectId {
  _id: string;
  name: string;
  slug: string;
}

export class VideoDetails {
  id: string;
  name: string;
  image: string;
  videoUrl: string;
  duration: string;
  status: string;
  types: string[];
  embedCode: string;
  vimeoId: string;
  video_id: string;
  hls_url: string;
  vtt_url: string;
  title: string;
  thumbnail_url: string;
  isLive: boolean;
  _id: string;
  createdAt: string;

  constructor(data: any) {
    data = data || {};
    this.id = data.id || '';
    this.name = data.name || '';
    this.image = data.image || '';
    this.videoUrl = data.videoUrl || '';
    this.duration = data.duration || '';
    this.status = data.status || '';
    this.types = data.types || '';
    this.embedCode = data.embedCode || '';
    this.vimeoId = data.vimeoId || '';
    this.video_id = data.video_id || '';
    this.hls_url = data.hls_url || '';
    this.vtt_url = data.vtt_url || '';
    this.title = data.title || '';
    this.isLive = data.isLive || false;
    this._id = data._id || '';
    this.createdAt = data.createdAt || '';
  }
}

export class TodayScheduleModel {
  _id: string;
  restrictedSchedule: boolean;
  restrictedTime: number;
  urlType: string;
  exerciseIds: any[];
  homeworkIds: HomeworkId[];
  tags: Tag[];
  isActive: boolean;
  isFree: boolean;
  isChatEnabled: boolean;
  isDoubtEnabled: boolean;
  isCommentDisabled: boolean;
  status: string;
  batchSubjectId: string;
  day: string;
  date: string;
  startTime: string;
  endTime: string;
  timeline: any[];
  isDPPNotes: boolean;
  isDPPVideos: boolean;
  isPathshala: boolean;
  teachers: any[];
  topic: string;
  meetingId: string;
  meetingPassword: string;
  url: string;
  ratingCount: number;
  subjectId: SubjectId;
  isBatchDoubtEnabled: boolean;
  dRoomId: string;
  videoDetails: VideoDetails;
  isLive: boolean;
  isUpcoming: boolean;
  conversationId: string;
  typeId: string;
  lastWatchedPointInSec: number;
  chapterId: string;
  slug: string;
  scheduleId: string;
  tagIds: string[];
  rating: number;
  feedback: string;
  teacherImage: ImageId;
  teacherName: string;
  isComplete: boolean;
  zoomHostId?: string;
  tag: string;
  scheduleType: string;

  constructor(data: any) {
    data = data || {};
    this.conversationId = data.conversationId || '';
    this._id = data._id || '';
    this.restrictedSchedule = data.restrictedSchedule || false;
    this.restrictedTime = data.restrictedTime || 0;
    this.urlType = data.urlType || '';
    this.zoomHostId = data.zoomHostId || '';
    this.exerciseIds = data.exerciseIds;
    if (data && data.homeworkIds && data.homeworkIds.length > 0) {
      const list: Array<HomeworkId> = [];
      data.homeworkIds.forEach((el: any) => {
        list.push(new HomeworkId(el));
      });
      this.homeworkIds = [...list];
    }
    if (data && data.tags && data.tags.length > 0) {
      const list: Array<Tag> = [];
      data.tags.forEach((el: any) => {
        list.push(new Tag(el));
      });
      this.tags = [...list];
    }
    this.isFree = data.isFree || false;
    this.isChatEnabled = data.isChatEnabled || false;
    this.isDoubtEnabled = data.isDoubtEnabled || false;
    this.isBatchDoubtEnabled = data.isBatchDoubtEnabled || false;
    this.isCommentDisabled = data.isCommentDisabled || false;
    this.status = data.status || '';
    this.batchSubjectId = data.batchSubjectId || '';
    this.day = data.data || '';
    this.date = data.date || '';
    this.startTime = data.startTime || '';
    this.endTime = data.endTime || '';
    this.timeline = data.timeline || [];
    this.isDPPNotes = data.isDPPNotes || false;
    this.isDPPVideos = data.isDPPVideos || false;
    this.isPathshala = data.isPathshala || false;
    this.teachers = data.teachers || [];
    this.topic = data.topic || '';
    this.meetingId = data.meetingId || '';
    this.meetingPassword = data.meetingPassword || '';
    this.url = data.url || '';
    this.isActive = data.isActive || false;
    this.videoDetails = data.videoDetails || {};
    this.typeId = data.typeId || '';
    this.isLive =
      (isBefore(new Date(this.startTime), new Date()) &&
        isAfter(new Date(this.endTime), new Date())) ||
      false;
    this.isUpcoming = isFuture(new Date(this.startTime));
    this.subjectId = data.subjectId || {};
    this.dRoomId = data.dRoomId || '';
    this.lastWatchedPointInSec = data.lastWatchedPointInSec || 0;
    this.slug = data.slug || '';
    this.scheduleId = data.scheduleId || '';
    // ratingCount: number;
    // isBatchDoubtEnabled: boolean;
    // videoDetails: VideoDetails;
    this.chapterId = data.chapterId || '';
    this.tagIds = data.tagIds || [];
    this.rating = data.rating || 0;
    this.feedback = data.feedback || '';
    this.teacherImage = data.teacherImage || new ImageId({});
    this.teacherName = data.teacherName || '';
    this.isComplete = data.isCompleted || false;
    this.tag = data.tag || '';
    this.scheduleType = data.scheduleType || '';
  }
}

export interface TodayScheduleObject {
  data: TodayScheduleModel[];
  success: boolean;
}

export interface TestCategoriesObject {
  success: boolean;
  data: TestList[];
}

export interface Attachment {
  baseUrl: string;
  _id: string;
  key: string;
  name: string;
  createdAt: Date;
}

export interface CreatedBy {}

export interface AnnouncementModel {
  _id: string;
  batchId: string;
  announcement: string;
  attachment: Attachment;
  createdBy: CreatedBy;
  created: string;
  scheduleTime: Date;
  createdAt: Date;
}

export interface AnnoouncementModelObject {
  success: boolean;
  data: AnnouncementModel[];
}

/**
 * Batch Topics Interface
 */
export class TopicsData {
  _id: string;
  name: string;
  type: string;
  typeId: string;
  displayOrder: number;
  notes: string;
  exercises: string;
  videos: string;
  slug: string;

  constructor(data: any) {
    data = data || {};
    this._id = data._id || '';
    this.name = data.name || '';
    this.type = data.type || '';
    this.typeId = data.typeId || '';
    this.displayOrder = data.displayOrder || 0;
    this.notes = data.notes || '0';
    this.exercises = data.exercises || '0';
    this.videos = data.videos || '0';
    this.slug = data.slug || '';
  }
}

export class SubTopicModal {
  _id: string;
  name: string;
  isActive: boolean;
  contentList: Array<ViewAllResults> = [];
  processing: boolean;
  paginate: boolean;
  page: number;
  type: string;
  slug: string;
  constructor(data: any) {
    data = data || {};
    this._id = data._id || '';
    this.name = data.name || '';
    this.isActive = false;
    this.processing = true;
    this.page = 1;
    this.paginate = true;
    this.type = data.type || '';
    this.slug = data.slug || '';
  }
}

export interface PaginateTopics {
  limit: number;
  totalCount: number;
}

export interface TopicsObject {
  success: boolean;
  data: TopicsData[];
  paginate: PaginateTopics;
}

/**
 * Faculty dialog details interface.
 */
export interface FacultyImageId {
  baseUrl: string;
  _id: string;
  name: string;
  key: string;
  organization: string;
  createdAt: Date;
  __v: number;
}

export class FacultyData {
  tags: string[];
  _id: string;
  firstName: string;
  lastName: string;
  imageId: FacultyImageId;
  featuredLine: string;
  qualification: string;
  about: string;
}

export interface FacultyRootObject {
  success: boolean;
  data: FacultyData;
}

export class PathshalaModel {
  centers: Array<{ name: string }>;
  classes: Array<{ name: string }>;
  departments: Array<{ name: string }>;
  courseDuration: Array<{ name: string }>;

  constructor(data: any) {
    data = data || {};
    this.centers = data.centers || [];
    this.classes = data.classes || [];
    this.departments = data.departments || [];
    this.courseDuration = data.courseDuration || [];
  }
}

export class BatchStudentFee {
  batches: any[];
  discount: number;
  gst: number;
  status: string;
  _id: string;
  userId: string;
  batchId: string;
  amount: number;
  submissionDate: Date;
  installmentNumber: number;
  createdAt: Date;
  updatedAt: Date;
  __v: number;

  constructor(data: any) {
    this.batches = data.batches || [];
    this.discount = data.discount || 0;
    this.gst = data.discount || 0;
    this.amount = data.amount || 0;
    this.installmentNumber = data.installmentNumber || 0;
    this.__v = data.__v || 0;
    this.status = data.status || '';
    this._id = data._id || '';
    this.userId = data.userId || '';
    this.batchId = data.batchId || '';
    this.submissionDate = data.submissionDate || '';
    this.createdAt = data.createdAt || '';
    this.updatedAt = data.updatedAt || '';
  }
}

export interface PathshalaObject {
  success: boolean;
  data: PathshalaModel;
}

export interface SubjectId {
  _id: string;
  name: string;
  slug: string;
}

export interface VideoDetails {
  name: string;
  image: string;
  embedCode: string;
  description: string;
  duration: string;
  _id: string;
  id: string;
}

// export interface RecentWatchedVideosModel {
//   userId: string;
//   videoId: string;
//   timeWatchedInSec: number;
//   type: string;
//   typeId: string;
//   isComplete: boolean;
//   batchId: string;
//   batchSubjectId: string;
//   programId: string;
//   subjectId: SubjectId;
//   chapterId?: any;
//   topicId?: any;
//   subTopicId?: any;
//   lastWatchedPointInSec: number;
//   urlType: string;
//   startTime: Date;
//   endTime: Date;
//   isFree: boolean;
//   isChatEnabled: boolean;
//   isDoubtEnabled: boolean;
//   isCommentDisabled: boolean;
//   isPathshala: boolean;
//   slug: string;
//   conversationId: string;
//   dRoomId: string;
//   videoDetails: VideoDetails;
//   topic: string;
// }

export interface RecentWatchedVideosModel {
  userId: string;
  videoId: string;
  timeWatchedInSec: number;
  type: string;
  typeId: string;
  isComplete: boolean;
  batchId: string;
  batchSubjectId: string;
  programId: string;
  subjectId: SubjectId;
  chapterId?: any;
  topicId?: any;
  subTopicId?: any;
  lastWatchedPointInSec: number;
  urlType: string;
  startTime: Date;
  endTime: Date;
  isFree: boolean;
  isChatEnabled: boolean;
  isDoubtEnabled: boolean;
  isCommentDisabled: boolean;
  isPathshala: boolean;
  slug: string;
  conversationId: string;
  dRoomId: string;
  videoDetails: VideoDetails;
  topic: string;
  date: Date;
  batchDetail: BatchDetail;
  homeworkIds?: any;
  scheduleId: string;
}

export interface BatchDetail {
  dRoomId: string;
  isDoubtEnabled: boolean;
  name: string;
  slug: string;
  _id: string;
}

export interface BatchDetail {
  dRoomId: string;
  isDoubtEnabled: boolean;
  name: string;
  slug: string;
  _id: string;
}
/** doubt all doubts listing **/

export class DoubtsSubjects {
  subjectId: string;
  subjectName: string;
  status: string;
  isDataFetched: boolean;
  isActive: boolean;

  constructor(data: any) {
    this.subjectId = data.subjectId || '';
    this.subjectName = data.subjectName || '';
    this.status = data.status || '';
    this.isDataFetched = data.isDataFetched || false;
    this.isActive = data.activeSection || false;
  }
}

export interface DoubtsSubjectRootObject {
  success: boolean;
  data: DoubtsSubjects[];
}

export interface ScheduleData {
  restrictedSchedule: boolean;
  restrictedTime: number;
  restrictedTimes: number;
  commentCount: number;
  teachers: any[];
  urlType: string;
  exerciseIds: any[];
  homeworkIds: string[];
  tags: string[];
  isFree: boolean;
  slideReadyToUse: boolean;
  isChatEnabled: boolean;
  isDoubtEnabled: boolean;
  isCommentDisabled: boolean;
  isPathshala: boolean;
  isDPPVideos: boolean;
  isDPPNotes: boolean;
  status: string;
  _id: string;
  solutionReadyToUse: boolean;
  batchSubjectId: string;
  day: string;
  date: Date;
  startTime: Date;
  endTime: Date;
  createdAt: Date;
  updatedAt: Date;
  slides: Slide[];
  timeline: any[];
  __v: number;
  meetingId: string;
  meetingPassword: string;
  topic: string;
  url: string;
  conversationId: string;
  slug: string;
  dRoomId: string;
}

export interface CreatedBy {
  _id: string;
  firstName: string;
  lastName: string;
  username: string;
  imageId: ImageId;
}

export class Doubt {
  doubtId: string;
  title: string;
  description: string;
  status: string;
  slideId: string;
  createdAt: Date;
  conversationId: string;
  createdBy: CreatedByModel;
  uiStatus: string;
  type: string;
  dRoomId: string;
  sameDoubtCount: number;
  reportCount: number;
  isPopular: boolean;
  isRecommended: boolean;
  isCommentDisabled: boolean;
  commentCount: number;

  constructor(props: any) {
    props = props || {};
    this.doubtId = props.doubtId || props._id || '';
    if (props && props.status && props.status.length > 0) {
      this.status = props.status;
    } else {
      this.status = '';
    }
    this.title = props.title || '';
    this.description = props.description || '';
    this.conversationId = props.conversationId || '';
    this.slideId = props.slideId || props.typeId || '';
    this.type = props.type || '';
    this.createdAt = props.createdAt || new Date();
    if (
      (props &&
        props.status &&
        props.status.toLowerCase() === 'unresolved ss') ||
      props.status.toLowerCase() === 'resolved ss' ||
      props.status.toLowerCase() === 'resolved gs'
    ) {
      this.uiStatus = 'Resolved';
    } else if (
      (props &&
        props.status &&
        props.status.toLowerCase() === 'unresolved gs') ||
      props.status.toLowerCase() === 'awaiting'
    ) {
      this.uiStatus = 'Awaiting';
    } else if (
      props &&
      props.status &&
      props.status.toLowerCase().includes('shared')
    ) {
      this.uiStatus = 'View solution';
    } else {
      this.uiStatus = this.status;
    }
    this.createdBy = new CreatedByModel(props.createdBy || {});
    this.dRoomId = props.dRoomId || '';
    this.sameDoubtCount = props.sameDoubtCount || 0;
    this.reportCount = props.reportCount || 0;
    this.isPopular = props.isPopular || false;
    this.isRecommended = props.isRecommended || false;
    this.isCommentDisabled = props.isCommentDisabled || false;
    this.commentCount = props.commentCount || 0;
  }
}

export class BatchLectureData {
  scheduleData: ScheduleData;
  doubts: Array<Doubt> = [];

  constructor(props: any) {
    props = props || {};
    this.scheduleData = props.scheduleData || {};
    if (props && props.doubts) {
      props.doubts.forEach((el: Doubt) => {
        this.doubts.push(new Doubt(el));
      });
    }
  }
}

export interface RecentlyWatchedVideoStatsObject {
  success: boolean;
  data: videoStatsData[];
}

export interface videoStatsData {
  userId: string;
  videoId: string;
  timeWatchedInSecTimestamp: number;
  type: string;
  typeId: string;
  isComplete: boolean;
  batchId: string;
  batchSubjectId: string;
  programId: string;
  lastWatchedPointInSec: number;
}

export interface DayCatagory {
  name: string;
  display_order: number;
}

export class Batch {
  _id: string;
  name: string;
  isPathshala: boolean;
  masterBatchId: string;

  constructor(data: any) {
    data = data || {};
    this._id = data._id || '';
    this.name = data.name || '';
    this.isPathshala = data.isPathshala || false;
    this.masterBatchId = data.masterBatchId || '';
  }
}

export class AllPurchasedBatches {
  batch: Batch;
  isPaid: boolean;
  mahapackBatch: Batch;
  isBlocked: boolean;

  constructor(data: any) {
    data = data || {};
    this.batch = new Batch(data.batch || {});
    this.isPaid = data.isPaid || false;
    this.isBlocked = data.isBlocked || false;
    this.mahapackBatch = new Batch(data.mahapackBatch || {});
  }
}

class TestPress {
  freeBatchId: string;
  paidBatchId: string;
  subTitle: string;
  title: string;

  constructor(data: any) {
    data = data || {};
    this.freeBatchId = data?.freeBatchId || '';
    this.paidBatchId = data?.paidBatchId || '';
    this.subTitle = data?.subTitle || '';
    this.title = data?.title || '';
  }
}
