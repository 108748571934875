import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, lastValueFrom, map, Observable, of, retry } from 'rxjs';
import { PPApiService } from '../../api/api.service';
import { PPApiOptions, PPApiParams } from '../../api/api.type';
import { AppUrlService } from '../../app-url/app-url.service';
import { handleError } from '../error-handler/error-handler.service';
import { ShowErrorService } from '../showError/show-error.service';
import { StorageService } from '../storage/storage.service';
import { ORGANIZATION_ID } from '../../../constants/app.constant';
import {
  CohortBatchesResponse,
  CohortConfig,
  CohortConfigResponse,
  CohortFilterQuery,
  CohortResponse,
  CohortRouteConfig,
  ExamPrefencesResponse,
  RecentCohortResponse,
  ReferAndDataResponse,
  SuccessResponse,
  UserPreferencesModel,
} from './cohort.type';

@Injectable({
  providedIn: 'root',
})
export class CohortService {
  ORGANIZATION_ID = ORGANIZATION_ID;
  FILTER_DATA: string = 'FILTER_DATA';
  cohortUpdated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  isRedirectedFromUrl$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );
  batchListingQuery$: BehaviorSubject<any> = new BehaviorSubject<any>({});
  private cohortRouteConfig$: BehaviorSubject<Array<CohortRouteConfig>> =
    new BehaviorSubject<Array<CohortRouteConfig>>([]);
  private needCohortUpdate$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  batchRedirection$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor(
    private apiService: PPApiService,
    private storage: StorageService,
    private appUrlService: AppUrlService,
    private showErrorService: ShowErrorService
  ) {}

  private _cohortId$ = new BehaviorSubject<string>('');

  get cohortId$() {
    return this._cohortId$.asObservable();
  }

  private _cohortConfig$: BehaviorSubject<CohortConfig> =
    new BehaviorSubject<CohortConfig>(new CohortConfig({}));

  get cohortConfig$() {
    return this._cohortConfig$.asObservable();
  }
  getCohortConfigActiveValue() {
    return this._cohortConfig$.getValue();
  }

  private _userPreferences$: BehaviorSubject<UserPreferencesModel> =
    new BehaviorSubject<UserPreferencesModel>(<UserPreferencesModel>{});

  get userPreferences$() {
    return this._userPreferences$.asObservable();
  }

  private _cohortFilterQuery$ = new BehaviorSubject<CohortFilterQuery>(
    <CohortFilterQuery>{}
  );

  get cohortFilterQuery$() {
    return this._cohortFilterQuery$.asObservable();
  }

  private _cohortFilter$ = new BehaviorSubject<any>({});

  get cohortFilter$() {
    return this._cohortFilter$.asObservable();
  }

  get cohortRoutesConfig$() {
    return this.cohortRouteConfig$.asObservable();
  }

  setNeedCohortUpdate(data: boolean) {
    this.needCohortUpdate$.next(data);
  }

  getNeedCohortUpdate() {
    return this.needCohortUpdate$;
  }

  setCohortId$(data: string) {
    this._cohortId$.next(data);
  }

  setUserPreferences(data: UserPreferencesModel) {
    this._userPreferences$.next(data);
  }

  setCohortCofig$(data: CohortConfig) {
    this._cohortConfig$.next(data);
  }

  setCohortConfigToLocal(data: CohortConfig) {
    this.storage.setValue('UserCohortConfig', data, 'json');
  }

  getCohortConfigFromLocal() {
    return this.storage.getValue('UserCohortConfig', 'json');
  }

  setCohortRouteConfig$(data: Array<CohortRouteConfig>) {
    this.cohortRouteConfig$.next(data);
  }

  setCohortFilterQuery$(data: CohortFilterQuery) {
    this._cohortFilterQuery$.next(data);
    this.storage.setValue('CohortFilter', data, 'json');
  }

  deleteCohortFilterFromLocal() {
    this.storage.removeValue('CohortFilter');
  }

  getCohortQuestions(query?: any) {
    const url = this.appUrlService.GET_COHORT_LANDING_QUESTIONS();
    let options: PPApiOptions = {
      apiPath: url,
      params: query && new PPApiParams().appendAll(query),
    };

    return this.apiService.get<CohortResponse>(options).pipe(
      map((res) => res.data),
      retry(1),
      catchError(handleError)
    );
  }

  getCohortBatches(cohortId: string, query: any = {}) {
    const url = this.appUrlService.GET_COHORT_BATCHES(cohortId);
    const options: PPApiOptions = {
      apiPath: url,
      params: new PPApiParams().appendAll(query),
    };

    return this.apiService.get<CohortBatchesResponse>(options).pipe(
      map((res) => res),
      retry(1),
      catchError(handleError)
    );
  }

  getCohortConfig(cohortId: string): Observable<CohortConfig | null>  {
    if (!cohortId) {
      return of(null);
    }
    const url = this.appUrlService.GET_COHORT_CONFIG(cohortId);
    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService.get<CohortConfigResponse>(options).pipe(
      map((res) => res.data),
      retry(1),
      catchError(handleError)
    );
  }

  getRecentCohorts() {
    const url = this.appUrlService.GET_RECENT_COHORTS();
    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService.get<RecentCohortResponse>(options).pipe(
      map((res) => res.data),
      retry(1),
      catchError(handleError)
    );
  }

  updateUserCohort(data: any) {
    const url = this.appUrlService.UPDATE_USER_COHORT();
    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService.put<any>(data, options).pipe(
      map((res) => res),
      retry(1),
      catchError(handleError)
    );
  }

  updateRecentCohort(data: any) {
    const url = this.appUrlService.UPDATE_RECENT_COHORTS();
    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService.put<SuccessResponse>(data, options).pipe(
      map((res) => res),
      retry(1),
      catchError(handleError)
    );
  }

  // setCohortConfigToLocal(data: CohortConfigResponse) {
  //   this.storage.setValue('COHORT_COFIG', data, 'json');
  // }

  // getCohortFromLocal() {
  //   return this.storage.getValue('COHORT_COFIG', 'json');
  // }

  getReferAndEarnData() {
    let query = {
      type: 'referAndEarn',
    };
    const url = this.appUrlService.GET_PREFERENCES();
    const options: PPApiOptions = {
      apiPath: url,
      params: new PPApiParams().appendAll(query),
    };

    return this.apiService.get<ReferAndDataResponse>(options).pipe(
      map((res) => res),
      retry(1),
      catchError(handleError)
    );
  }

  setCohortIdToLocal(cohortId: string) {
    this.storage.setValue('COHORT_ID', cohortId, 'string');
  }

  getCohortIdFromLocal() {
    return this.storage.getValue('COHORT_ID', 'string');
  }

  async updateRecentCohortOfUser(data: { cohortId: string }, action?: string) {
    if (!data.cohortId) {
      return;
    }

    if (action && action === COHORT_ACTION.NO_API_CALL) {
      this.setCohortId$(data.cohortId);
      this.setCohortIdToLocal(data.cohortId);
      this.cohortUpdated$.next(true);
      return;
    }

    try {
      const res = await lastValueFrom(this.updateRecentCohort(data));
      if (res && res.success) {
        this.setCohortId$(data.cohortId);
        this.setCohortIdToLocal(data.cohortId);
        this.cohortUpdated$.next(true);
      }
    } catch (e) {
      this.showErrorService.showError(e);
    }
  }

  getExamPreferences(prefs: Array<string>) {
    const url = this.appUrlService.GET_EXAM_PREFERENCES();
    const options: PPApiOptions = {
      apiPath: url,
      params: new PPApiParams()
        .append('organizationId', ORGANIZATION_ID)
        .append('prefs', prefs.join(',')),
    };

    return this.apiService.get<ExamPrefencesResponse>(options).pipe(
      map((res) => res.data),
      retry(1),
      catchError(handleError)
    );
  }

  setFilter(filter: any) {
    this._cohortFilter$.next(filter);
  }

  clearCohortData() {
    this._cohortId$.next('');
    this._cohortConfig$.next(new CohortConfig({}));
    this.cohortRouteConfig$.next([]);
    this._userPreferences$.next(<UserPreferencesModel>{});
    this.needCohortUpdate$.next(false);
    this._cohortFilter$.next('');
    this._cohortFilterQuery$.next(<CohortFilterQuery>{});
    this.cohortUpdated$.next(false);
  }

  getUnleashFeatureFlag(data: UnleashApiPayloadType) {
    const url = this.appUrlService.UNLEASH_FEATURE_FLAG();
    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService.post(data, options).pipe(
      map((res: any) => res.data),
      catchError(handleError)
    );
  }

  async unleashFeatureFlag(
    data: UnleashApiPayloadType
  ): Promise<UnleashDataType> {
    try {
      const res = await lastValueFrom(this.getUnleashFeatureFlag(data));
      if (res) {
        return <UnleashDataType>res;
      }
      return <UnleashDataType>{};
    } catch (error) {
      this.showErrorService.showError(error);
      return <UnleashDataType>{};
    }
  }
}

export interface UnleashDataType {
  isEnabled: boolean;
  variantData: VariantData;
}

export interface VariantData {
  name: string;
  payload: Payload;
  enabled: boolean;
  feature_enabled: boolean;
  featureEnabled: boolean;
}

export enum COHORT_ACTION {
  API_CALL = 'API_CALL',
  NO_API_CALL = 'NO_API_CALL',
}

export interface Payload {
  type: string;
  value: string;
}

export interface UnleashApiPayloadType {
  userId: string;
  featureName: string;
  experimentName: string;
  customData: any;
  sendAnalytics: boolean;
}

export class UserProfileCohortData {
  needCohortUpdate: boolean;
  cohortId: string;
  exams: string[];

  constructor(data: any) {
    data = data || {};
    this.needCohortUpdate = data.needCohortUpdate || false;
    this.cohortId = data.cohortId || '';
    this.exams = data.exams || [];
  }
}
