import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalService } from './common/services/global/global.service';

import { AuthService } from './common/services/auth/auth.service';
import { fromEvent, lastValueFrom, Subscription } from 'rxjs';
import { ShowErrorService } from './common/services/showError/show-error.service';
import { StorageService } from './common/services/storage/storage.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { ORGANIZATION_ID, PAYMENT_METHOD } from './constants/app.constant';
import { Platform } from '@angular/cdk/platform';
import {
  APP_VERSION,
  GlobalConstantService,
  MAIN_PROGRAM,
  setSlugFromNetwork,
} from './constants/global-constant/global-constant.service';
import { EditprofileService } from './common/services/edit-profile/editprofile.service';
import { NameEmailEntryComponent } from './common/components/name-email-entry/name-email-entry.component';
import { LoaderService } from './common/services/loader/loader.service';
import { GlobalObjectService } from './common/services/global-object/global-object.service';

import { FirebaseMessagingService } from './common/services/firebase-messaging/firebase-messaging.service';
import { StoreService } from './common/services/store/store.service';

import { environment } from 'src/environments/environment';
import { AngularFireRemoteConfig } from '@angular/fire/compat/remote-config';
import { ProgramService } from './common/services/program-service/program.service';
import { LOGOUT } from './core/analytics-events';
import { PW_LOGO } from './core/assets.location';
import { NotificationService } from './common/services/notification/notification.service';

import { FirebaseAnalyticsService } from './common/services/firebase-analytics/firebase-analytics.service';
import {
  COHORT_ACTION,
  CohortService,
  UserProfileCohortData,
} from './common/services/cohort/cohort.service';
import { webViewPages } from './app-routing.module';
import {
  CookieKeys,
  CookieServiceService,
} from './common/services/cookie-service/cookie-service.service';
import { LoadingPageComponent } from 'src/app/common/components/loading-page/loading-page.component';
import { UserSegment } from './common/services/study/study.service';
import { FirebaseRemoteConfigService } from './common/services/firebase-remote-config/firebase-remote-config.service';
import { PaymentService } from './common/services/payment/payment.service';
import { UnderMaintenanceService } from './common/services/under-maintenance/under-maintenance.service';
import { Learn2EarnService } from './common/services/learn-2-earn/learn-2-earn.service';

declare const navigator: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    StoreService,
    ProgramService,
    GlobalConstantService,

    FirebaseAnalyticsService,
  ],
})
export class AppComponent implements OnInit, OnDestroy, AfterContentChecked {
  paymentMethod = PAYMENT_METHOD.getValue();
  orderResponse: any;
  isSideNavOpen: boolean;
  mobileQuery: any;
  sideNavSub: Subscription;
  activeTab: string;
  userInfo: any;
  tokenSubs: Subscription;
  showSplash = true;
  userSubs: Subscription;
  message: any;
  emailOpenSubs: Subscription;
  hyperServiceObject: any;
  paymentMethodSub: Subscription;
  pwLogo = PW_LOGO;
  firebasetoken: string;
  triggerModalSub: Subscription;
  loaderDialog: MatDialogRef<LoadingPageComponent>;
  processing: boolean;
  cohortRouteSubs: Subscription;
  logouteventSubs: Subscription;
  cameFrom: string = '';
  private _mobileQueryListener: () => void;
  isLearnToEarnSideBarOpen = false;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private media: MediaMatcher,
    private _globalService: GlobalService,
    private _authService: AuthService,
    private storage: StorageService,
    public router: Router,
    private _showErrorService: ShowErrorService,
    private storageService: StorageService,
    public dialog: MatDialog,
    private showErrorService: ShowErrorService,
    private platform: Platform,
    private editProfileService: EditprofileService,
    private loaderService: LoaderService,
    private globalObjectService: GlobalObjectService,
    private firebaseMessageService: FirebaseMessagingService,
    private storeService: StoreService,
    private remoteConfig: AngularFireRemoteConfig,
    private programService: ProgramService,
    private notificationService: NotificationService,
    private firebaseService: FirebaseAnalyticsService,
    private cookieService: CookieServiceService,
    private activatedRoute: ActivatedRoute,
    private cohortService: CohortService,
    private remoteConfigService: FirebaseRemoteConfigService,
    private paymentService: PaymentService,
    private underMaintenanceService: UnderMaintenanceService,
    private learn2EarnService: Learn2EarnService
  ) {
    this._globalService.getRandomId();
    this.preventDevTools();
    this.mobileQuery = media.matchMedia('(max-width: 769px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.checkTokenExpiry();
    setTimeout(() => {
      if (this.router.url === '/batches') {
        this.openLoaderModal();
      }
      this.initializeWebsite();
      this.showSplash = false;
    }, 3000);
    if ('serviceWorker' in this.globalObjectService.navigator!) {
      this.globalObjectService
        .navigator!.serviceWorker.register(environment.serviceWorker, {
          scope: '/study/',
        })
        .then(function (registration) {})
        .catch(function (err) {
          console.log('Service worker registration failed, error:', err);
        });
    }

    this.paymentMethodSub = PAYMENT_METHOD.subscribe((data) => {
      this.paymentMethod = data;
      if (this.paymentMethod === 'JUSPAY') {
        this.getSignature();
      }
    });
    this._globalService.juspay$.subscribe((data) => {
      this.juspayEvent(data);
    });

    this.logouteventSubs = this._globalService.logoutEvent$.subscribe((res) => {
      if (res) {
        this.firebaseService.logEvents(LOGOUT, res, true, true);
        this._globalService.logoutEvent$.next(null);
      }
    });
    this.cameFrom = this.activatedRoute.snapshot.queryParams['cameFrom'] || '';
  }

  checkTokenExpiry() {
    const expiresIn = this.storage.getValue('expires_in', 'json') || '';
    const currentTimestamp =
      new Date().getTime() +
      this._globalService.TOKEN_EXPIRY_DURATION_IN_MILLISECONDS / 2;

    if (currentTimestamp > expiresIn && expiresIn) {
      this._authService.refreshToken().subscribe((data) => {
        if (data?.data) {
          this._authService.setRefreshToken(data['data']['refresh_token']);
          this._authService.setExpiryTime(data['data']['expires_in']);
          this._authService.setToken(data['data']['access_token']);
          this._globalService.setAccessToken(data['data']['access_token']);
          this._authService.setRefreshToken(data['data']['refresh_token']);
          this._authService.setExpiryTime(data['data']['expires_in']);
          const data1 = {
            token: data['data']['access_token'],
            refresh_token: data['data']['refresh_token'],
            expires_in: data['data']['expires_in'],
            randomId: this._globalService.getRandomId(),
          };
          const expiry = new Date(
            new Date().getTime() + 60 * 60 * 24 * 7 * 1000
          ).toUTCString();
        }
      });
    } else {
      setTimeout(() => {
        this.checkTokenExpiry();
      }, this._globalService.TOKEN_EXPIRY_DURATION_IN_MILLISECONDS / 7);
    }
  }

  @HostListener('contextmenu', ['$event'])
  onRightClick(event: any) {
    if (environment.production) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    if (environment.production) {
      e.preventDefault();
    }
  }

  @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
    if (environment.production) {
      e.preventDefault();
    }
  }

  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
    if (environment.production) {
      e.preventDefault();
    }
  }

  // @ts-ignore
  preventDevTools() {
    if (environment.production) {
      return new Promise((resolve) => {
        const code = `document.addEventListener('keydown', function () {
            if (event.keyCode === 123) {
                event.preventDefault();
            } else if (event.ctrlKey && event.shiftKey && event.keyCode === 73) {
                event.preventDefault();
            } else if (event.ctrlKey && event.keyCode === 85) {
                event.preventDefault();
            } else if (event.keyCode === 9 || event.key === "Tab") {
                event.preventDefault();
            }
        }, false);`;
        const node = document.createElement('script');
        node.type = 'text/javascript';
        node.async = true;
        node.charset = 'utf-8';
        node.innerText = code;
        document.getElementsByTagName('head')[0].appendChild(node);
        resolve(true);
      });
    }
  }

  initializeWebsite() {
    this.addNetworkListener();
    if (!webViewPages.includes(this.router?.url?.split('?')[0])) {
      this.isTokenExist();
    }
    this.getUserProfile();
  }

  getUserProfile() {
    this.userSubs = this._globalService.getUser().subscribe((user) => {
      if (user) {
        this.userInfo = user;
        // this.underMaintenanceService.getStatus();
      }
    });
  }

  async getSignature() {
    const data = {
      client: 'web',
    };
    if (this.paymentMethod === 'JUSPAY')
      try {
        const res = await lastValueFrom(
          this.storeService.jusPayInitSignature(data)
        );
        // this.events.publish('initiateHyperSevice', res['data']);
        this.initiateHyperService(res['data']);
      } catch (e) {
        // console.log(e);
      } finally {
      }
  }

  juspayEvent(order: any) {
    if (order) {
      order['response']['customerEmail'] = this.userInfo['email'];
      this.orderResponse = order['response'];

      const sdkPayload = {
        service: 'in.juspay.hyperpay',
        requestId: this.uuidv4(),
        payload: this.orderResponse,
      };
      this.hyperServiceObject.process(sdkPayload);
    }
  }

  initiateHyperService(data: any) {
    data['hyperSDKDiv'] = 'merchantViewId';
    data['integrationType'] = 'redirection'; //iframe //redirection *!/
    setTimeout(() => {
      const sdkPayload = {
        service: 'in.juspay.hyperpay',
        requestId: this.uuidv4(),
        payload: data,
      };
      this.hyperServiceObject.initiate(sdkPayload);
    }, 1500);
  }

  uuidv4() {
    // @ts-ignore
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      // tslint:disable-next-line:no-bitwise
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  }

  ngAfterContentChecked() {
    this.changeDetectorRef.detectChanges();
  }

  addNetworkListener() {
    let online = this.globalObjectService.navigator!.onLine;
    fromEvent(this.globalObjectService.window!, 'online').subscribe((val) => {
      online = true;
      this._globalService.showSnackBar('Internet connection detected');
    });

    fromEvent(this.globalObjectService.window!, 'offline').subscribe((val) => {
      online = false;
      this._globalService.showSnackBar('No internet connection detected');
    });
  }

  async setIneuronRedirectionUrl() {
    const url =
      this.activatedRoute.snapshot.queryParamMap.get('redirectUrl') || '';
    const decodedUrl = decodeURIComponent(url) || '';
    if (decodedUrl.length > 0) {
      this._globalService.setIneuronRedirectionUrl(decodedUrl);
    }
  }

  async isTokenExist() {
    await this.setIneuronRedirectionUrl();
    const message = 'Logging In...';
    this.loaderService.loadData(message);
    const accessToken =
      this.activatedRoute.snapshot.queryParamMap.get('TOKEN') ||
      this._globalService.getAccessToken().getValue() ||
      '';

    const ineuronRedirectionUrl =
      this._globalService.getIneuronRedirectionUrl() || '';
    const randomId = this._globalService.getRandomId() || '';
    if (ineuronRedirectionUrl.length > 0 && accessToken.length > 0) {
      const url =
        ineuronRedirectionUrl +
        `${
          ineuronRedirectionUrl.includes('?') ? '&' : '?'
        }token=${accessToken}&randomid=${randomId}`;
      if (
        url.includes('pwskills') ||
        url.includes('localhost') ||
        url.includes('stage') ||
        url.includes('pw')
      ) {
        this.globalObjectService.window!.open(url, '_self');
        this.loaderService.unloadData(message);
        this._globalService.removeIneuronRedirectionUrl();
        return;
      } else {
        this._globalService.showSnackBar('Not A Valid Url!');
        this._globalService.removeIneuronRedirectionUrl();
        this.loaderService.unloadData(message);
        return;
      }
    }
    this.loaderService.unloadData(message);
    if (
      accessToken.length > 0 &&
      accessToken !==
        '38e26c92d851824dd8556c7bcbd7c2747b4daec761b36ea5d599929c7d5935d3'
    ) {
      this.self();
      this.firebaseMessageService.requestPermission();

      // this.checkVersion();
      // this.getPaymentMethod();
      if (this.mobileQuery.matches) {
        this._globalService.openSideNav(false);
      } else {
        if (!this.router.url.toLocaleLowerCase().includes('under-maintenance'))
          this._globalService.openSideNav(true);
      }
    } else {
      this._globalService.openSideNav(false);
    }

    this.tokenSubs = this._globalService.getAccessToken().subscribe(
      (token) => {
        if (token) {
          this.loadScript();

          this.getSignature();

          this.firebaseMessageService.receiveMessage();
          this.message = this.firebaseMessageService.currentMessage;
          this.firebasetoken = this.storageService.getDeviceId() || '';
          const navigator = this.globalObjectService.navigator!;
          navigator.serviceWorker?.addEventListener('message', (event) => {
            if (event && event['data'] && event['data']['payload']) {
              // this.firebaseMessageService.showNotificationAlert(
              //   event['data']['payload']
              // );
            }
          });

          if (this.mobileQuery.matches) {
            this._globalService.openSideNav(false);
          } else {
            if (
              this.router.url.includes('video-player') ||
              this.router.url.toLocaleLowerCase().includes('under-maintenance')
            ) {
              this._globalService.openSideNav(false);
            } else {
              this._globalService.openSideNav(true);
            }
          }
        } else {
          this._globalService.openSideNav(false);
        }
      },
      (err) => {
        this._showErrorService.showError(err);
      }
    );
  }

  checkVersion() {
    if (APP_VERSION !== this._globalService.getAppVersion()) {
      // this.logoutUser();
    }
  }

  loadScript() {
    return new Promise((resolve) => {
      const dynamicScripts = [
        // 'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.2/MathJax.js?config=TeX-MML-AM_CHTML-full',
        'https://checkout.razorpay.com/v1/checkout.js',
      ];
      for (let i = 0; i < dynamicScripts.length; i++) {
        const node = this.globalObjectService.document.createElement('script');
        node.src = dynamicScripts[i];
        node.type = 'text/javascript';
        node.async = true;
        node.charset = 'utf-8';
        this.globalObjectService.document
          .getElementsByTagName('head')[0]
          .appendChild(node);
      }
      resolve(true);
    });
  }

  async handleUserRedirection() {
    let batchUserSegment = '';
    try {
      const query = {
        landingPage: true,
      };
      const res = await lastValueFrom(
        this._globalService.getBatchUserSegment(query)
      );
      if (res && res['batchUserSegment']) {
        batchUserSegment = res['batchUserSegment'];
        await this._globalService.setUserSegmentToStorage(
          res['batchUserSegment']
        );
      }
      if (res?.hasOwnProperty('isPathshala')) {
        await this._globalService.setIsPathShalaToStorage(res['isPathshala']);
      }
    } catch (e) {
      await this.showErrorService.showError(e);
    }
    let initialUrl = this.router.url.split('?')[0];
    if (
      batchUserSegment === UserSegment.PAID &&
      (initialUrl === 'study/batches' || initialUrl === '/batches') &&
      !this.router.url.includes('batchChildUrl')
    ) {
      this.router.navigateByUrl('/batches/study');
      return;
    }
    if (this.router?.url?.includes('openLearn2EarnSideBar')) {
      try {
        const configData = await this.learn2EarnService?.getConfigData();
        if (configData?.isLearnToEarnActive) {
          this._globalService?.openLearnToEarnSideBar(true);
        }
      } catch (error: any) {
        console.log(error);
      }
    }
    if (this.router.url.split('?')[0] === '/auth') {
      this.router.navigateByUrl(
        this.activatedRoute.snapshot.queryParamMap.get('url') || 'batches'
      );
    }
  }

  onLearn2EarnSidebarClose() {
    this._globalService?.openLearnToEarnSideBar(false);
  }

  async self() {
    try {
      const res = await lastValueFrom(this._authService.verifyAuthToken());
      if (res) {
        if (res['data'] && res['data'].isVerified) {
          this.userInfo = JSON.parse(this.storageService.getUser() || '');
          this.selfActions(this.userInfo);
        }
      }
    } catch (err: any) {
      if (
        err &&
        err.error &&
        (err.error.status === 401 || err.error.status === 403)
      ) {
        this.storageService.setResponceCode(err.error.status);
        this.logoutUser();
      } else {
        console.error(err);
      }
    } finally {
      await this.getPreferences();
    }
  }

  async getUserProfileFromNetwork(): Promise<UserProfileCohortData> {
    const query = {
      fields: 'needCohortUpdate',
    };
    try {
      const res = await lastValueFrom(
        this._authService.getUserProfileInfo(query)
      );
      if (res && res['data']) {
        return new UserProfileCohortData(res['data']);
      } else {
        return new UserProfileCohortData(res['data']);
      }
    } catch (e) {
      console.error(e);
      return new UserProfileCohortData({});
    }
  }

  async selfActions(userInfo: any) {
    const cohortData = await this.getUserProfileFromNetwork();
    this.userInfo.profileId['needCohortUpdate'] = cohortData.needCohortUpdate;
    this._globalService.cohortCheckOnSelf(this.userInfo);
    const cohortId = await this.getRecentCohort();
    if (cohortId) this.cohortService.setCohortIdToLocal(cohortId);
    this._globalService.batcheUnleashFeatureCheck();
    this.checkVersions();
    this.subscribeToNotification(userInfo?.primaryNumber);
    if (this.mobileQuery.matches) {
      this._globalService.openSideNav(false);
    } else {
      if (
        this.router.url.includes('video-player') ||
        this.router.url.includes('under-maintenance')
      ) {
        this._globalService.openSideNav(false);
      } else {
        this._globalService.openSideNav(true);
      }
    }
    this.goAhead(userInfo);

    this.getProgramDetails();
    this._globalService.handleFormList();
    await this.handleUserRedirection();
  }

  async getRecentCohort() {
    try {
      const res = await lastValueFrom(this.cohortService.getRecentCohorts());

      if (
        this.activatedRoute.snapshot.queryParamMap.keys.includes('cohortId')
      ) {
        await this.cohortService.updateRecentCohortOfUser(
          {
            cohortId: this.activatedRoute.snapshot.queryParams['cohortId'],
          },
          COHORT_ACTION.NO_API_CALL
        );
        this.cohortService.isRedirectedFromUrl$.next(true);
        return this.activatedRoute.snapshot.queryParams['cohortId'];
      } else if (res && res.selectedCohortId?.length > 0) {
        await this.cohortService.updateRecentCohortOfUser(
          {
            cohortId: res.selectedCohortId,
          },
          COHORT_ACTION.NO_API_CALL
        );
        return res.selectedCohortId;
      } else {
        await this.cohortService.updateRecentCohortOfUser(
          {
            cohortId: this.userInfo['profileId']['cohortId'],
          },
          COHORT_ACTION.NO_API_CALL
        );
        return this.userInfo['profileId']['cohortId'];
      }
    } catch (error) {
      if (error === 'Backend returned code undefined, body was: undefined') {
        console.log(error);
      } else {
        this.showErrorService.showError(error);
      }
    } finally {
    }
  }

  async subscribeToNotification(mobileNumber: string) {
    if (this.firebasetoken.length > 0) {
      let data = {
        topic: mobileNumber,
        deviceId: this.firebasetoken,
      };

      let res = await lastValueFrom(
        this.notificationService.subscribeToNotif(data)
      );
      data = {
        topic: 'all',
        deviceId: this.firebasetoken,
      };
      res = await lastValueFrom(
        this.notificationService.subscribeToNotif(data)
      );
    }
  }

  async getProgramDetails() {
    const res = this._globalService.getProgramSlug();
    if (res) {
      setSlugFromNetwork(res);
      return;
    }
    try {
      const res = await lastValueFrom(
        this.programService.getProgramDetails(MAIN_PROGRAM)
      );
      if (res) {
        setSlugFromNetwork(res.slug);
        this._globalService.setProgramSlug(res.slug);
      }
    } catch (e) {
      await this.showErrorService.showError(e);
    }
  }

  async checkVersions() {
    const studentVersion = +this._globalService.getAppVersion();
    if (studentVersion && studentVersion <= 162) {
      this._authService.exchangeToken().subscribe((res: any) => {
        if (res && res.data && res.data.access_token) {
          this._globalService.setAccessToken(res.data.access_token);
          this._globalService.setAppVersion(APP_VERSION);
        }
      });
    }
  }

  async showNameEmailModal(user: any) {
    this.triggerModalSub = this._globalService.triggerNameEmailModal$.subscribe(
      (res) => {
        if (!res) return;
        if (this.router.url === '/auth') return;

        const dialogRef = this.dialog.open(NameEmailEntryComponent, {
          width: '550px',
          disableClose: true,
          panelClass: 'name-email-alert-css',
        });
      }
    );
  }

  async setNameAndEmail(data: any) {
    try {
      const res = await lastValueFrom(
        this.editProfileService.updateProfile(data)
      );
      this.userInfo.firstName = data.firstName;
      this.userInfo.email = data.email;
      await this._globalService.setUser(this.userInfo);
      this._globalService.showSnackBar('Profile updated successfully...');
    } catch (e) {
      await this.showErrorService.showError(e);
    } finally {
      // this.loaderService.unloadData('Loading...');
      this._globalService.setEmailModalOpen(false);
    }
  }

  logoutUser() {
    this._globalService.logoutUserGlobally('absolute');
  }

  async goAhead(user: any) {
    this._globalService.setUser(user);
  }

  async ngOnInit() {
    this.cookieService.setJSONValue(CookieKeys.KEY_PAIR_ID, 'key-pair-id');
    this.cookieService.setJSONValue(CookieKeys.SIGNATURE, 'signature');
    this.cookieService.setJSONValue(CookieKeys.POLICY, 'Policy');
    this.globalObjectService.document.cookie = `${CookieKeys.KEY_PAIR_ID}=key-pair-id;domain=demo.pw.live;path=/`;
    this.globalObjectService.document.cookie = `${CookieKeys.SIGNATURE}=signature;domain=demo.pw.live;path=/`;
    this.globalObjectService.document.cookie = `${CookieKeys.POLICY}=policy;domain=demo.pw.live;path=/`;

    this.globalObjectService.window!.onload = (event) => {
      // @ts-ignore
      this.hyperServiceObject =
        // @ts-ignore
        new this.globalObjectService.window!.HyperServices();
      // @ts-ignore
      this.globalObjectService.window!['hyperServiceObject'] =
        this.hyperServiceObject;
    };
    this.getSideNav();
    const isCheckBrave = this.isBrave();
    let message = '';
    if (!isCheckBrave) {
      message =
        'This website uses cookies to ensure you get the best experience on our website.';
    } else {
      message =
        'This website uses cookies to ensure you get the best experience on our website. Also disable shields for our website if using.';
    }
    this.setCookiesConsent(message);
    this.showNameEmailModal(this.userInfo);
    this.remoteConfigService
      .fetchConfig()
      .then(() => {
        this.getRemoteConfigValueForNewFbt();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async getRemoteConfigValueForNewFbt() {
    try {
      this.remoteConfigService
        .getConfigValue(environment.RUN_NEW_FBT)
        .then((data) =>
          this.paymentService.setShouldRunNewFbt(data as boolean)
        );
    } catch (err) {
      console.error(err);
    }
  }

  isBrave() {
    if (navigator.brave != undefined) {
      if (navigator.brave.isBrave.name == 'isBrave') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  setCookiesConsent(message: string) {
    if (typeof window !== 'undefined') {
      (window as any).cookieconsent.initialise({
        palette: {
          popup: {
            background: '#5a4bda',
          },
          button: {
            background: '#FFFFFF',
            text: '#5a4bda',
          },
        },
        theme: 'block',
        position: 'bottom-right',
        content: {
          message: `${message}`,
          dismiss: 'Allow',
          link: '',
          href: '',
        },
      });
    }
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
    if (this.emailOpenSubs) {
      this.emailOpenSubs.unsubscribe();
    }
    if (this.paymentMethodSub) {
      this.paymentMethodSub.unsubscribe();
    }
    this.cohortRouteSubs?.unsubscribe();
    this.triggerModalSub?.unsubscribe();
    this.logouteventSubs?.unsubscribe();
  }

  getSideNav() {
    this.sideNavSub = this._globalService.isSideNavOpen().subscribe(
      (res) => {
        this.isSideNavOpen = res;
      },
      () => {
        console.log('Unable to get user menu value');
      }
    );

    this._globalService.isLearnToEarnSideBarOpen().subscribe(
      (res) => {
        this.isLearnToEarnSideBarOpen = res;
      },
      () => {
        console.log('Unable to get user menu value');
      }
    );
  }

  async getPreferences() {
    const res = this._globalService.getPreferences();
    if (res) {
      return;
    }
    const query = {
      organizationId: ORGANIZATION_ID,
      prefs: 'Languages,Classes,Boards,Exams,BatchBanner,Banner',
    };

    try {
      const res: any = await lastValueFrom(
        this._authService.getPreferences(query)
      );

      this._globalService.setPreferences(res['data']);
    } catch (e) {
      await this.showErrorService.showError(e);
    } finally {
    }
  }

  openLoaderModal() {
    this.loaderDialog = this.dialog.open(LoadingPageComponent, {
      panelClass: 'loading-page-dialog',
    });
  }
}
