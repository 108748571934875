import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BehaviorSubject, catchError, lastValueFrom, map, Subject } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { STORAGE_ENUM, StorageService } from '../storage/storage.service';
import { TestData } from '../../../pages/tests/tests.model';
import { differenceInHours, differenceInMinutes } from 'date-fns';
import { TopicData } from '../../../pages/courses/chapters/topic-card/topics.model';
import { GlobalObjectService } from '../global-object/global-object.service';
import { Location } from '@angular/common';
import { UserBankDetailsObject } from 'src/app/pages/referral-new/referral-new-model';
import { CohortService } from '../cohort/cohort.service';
import { CookieServiceService } from '../cookie-service/cookie-service.service';
import { MatDialog } from '@angular/material/dialog';
import { AdmitCardModalComponent } from '../../components/admit-card-modal/admit-card-modal.component';
import { PPApiOptions, PPApiParams } from '../../api/api.type';
import { AppUrlService } from '../../app-url/app-url.service';
import { PPApiService } from '../../api/api.service';
import { handleError } from '../error-handler/error-handler.service';
import { FORM_TRIGGERS } from 'src/app/constants/form.constant';
import { CookieService } from 'ngx-cookie-service';
import { Base64 } from '../../utils/encode-utilities';
import { PaymentReminderComponent } from 'src/app/pages/batch/part-payment/components/payment-reminder/payment-reminder.component';
import {
  EMIInfoResponse,
  EzPayResponse,
  UpcomingInstalments,
  UpcomingInstalmentsData,
} from '../batch/batch.modal';
import { ShowErrorService } from '../showError/show-error.service';
import { UNLEASH_PAYLOAD_ENUM } from 'src/app/constants/unleash.constant';

declare let require: any;
const { v4: uuidv4 } = require('uuid');
interface CloseParams {
  id: string;
  triggerEvent: string;
  type: string;
  action: string;
}
interface Body {
  type: string;
  action: string;
  triggerEvent: string;
}
interface StoredListObj {
  _id: string;
  name: string;
  needToDisplay: boolean;
  isAlreadySubmitted: boolean;
  triggerEvents: any[];
  needToReSubmit: boolean;
}
@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  public saveUrlBeforeTest: string;
  public redirectUrl: string;
  public currentPlatform = ['android'];
  PAYMENT_KEYS = 'PAYMENT_KEYS';
  STORE_ITEM = 'STORE_ITEM';
  VIDEO = 'VIDEO_DETAILS';
  PDF = 'PDF';
  TOKEN_EXPIRY_DURATION_IN_MILLISECONDS = 1000 * 60 * 60 * 24 * 7;
  TOPIC_DATA: string = 'TOPIC_DATA';
  CHAPTER_DATA: string = 'CHAPTER_DATA';
  PREFERENCES: string = 'PREFERENCES';
  DOUBT_CONNECT_RELOAD_COUNT = 'DOUBT_CONNECT_RELOAD_COUNT';
  VIDEO_STATS = 'VIDEO_STATS';
  CLICK_ID: string;
  filterTags = 'FILTER_TAGS';
  programSlug = 'PROGRAM_SLUG';

  LIBRARY_DATA = 'libraryData';
  LIBRARY_ACTIVE_SUBJECT = 'LibraryActiveSubject';
  LIBRARY_SUBJECT_DATA = 'librarySubjectData';
  INEURON_REDIRECTION_URL = 'ineuron_redirection_url';
  FORM_LIST = 'form_list';
  BATCH_USER_SEGMENT = 'batch_user_segment';
  VIDYAPEETH_DETAILS = 'vidyapeethDetails';
  IS_PATHSHALA = 'is_pathshala';
  cookiePaths = ['/', '/vp', '/study'];
  closeParamsobj: CloseParams = {
    id: '',
    triggerEvent: '',
    type: '',
    action: '',
  };
  _triggerNameEmailModal$ = new BehaviorSubject<boolean>(false);
  batchActiveTab = new BehaviorSubject<string>('');
  public _saveUrlBeforeTest$: BehaviorSubject<string> =
    new BehaviorSubject<string>('');

  public isOfflineMode = true;
  paymentDetails: any;
  isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  programId: string;
  _programId$ = new Subject<string>();
  accessToken: string = '';
  isNewUserLogin: boolean = false;
  public _user$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public _openEmailModal$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public juspay$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public showSideNav$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public showSideSubNav$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public showLearnToEarnSideBar$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  _accessToken$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  angularRoute = this.location.path();
  url = this.globalObject.windowObj.location.href;
  openContent: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  domainAndApp = this.url.replace(this.angularRoute, '');
  activeListSection$: BehaviorSubject<string> = new BehaviorSubject('');
  allContentActiveSection$: BehaviorSubject<string> = new BehaviorSubject('');
  testActiveTab$: BehaviorSubject<string> = new BehaviorSubject('');
  recentLearningActiveTab$: BehaviorSubject<string> = new BehaviorSubject('');
  activeFeedBlog$: BehaviorSubject<string> = new BehaviorSubject('');
  activeSocket$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  activeSubject$: BehaviorSubject<string> = new BehaviorSubject('');
  public cameFromUrl$: BehaviorSubject<string> = new BehaviorSubject<string>(
    ''
  );
  userBankDetails$: BehaviorSubject<UserBankDetailsObject> =
    new BehaviorSubject<UserBankDetailsObject>(new UserBankDetailsObject({}));
  refreshToken: string = '';
  _refreshToken$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  showToast$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  logoutEvent$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  samsungRedirectionUrl$: BehaviorSubject<string> = new BehaviorSubject<string>(
    ''
  );
  clientType$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  purchaseSource$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  previousUrl$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  hideHeader$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  batchRevampUnleash$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor(
    private _storageService: StorageService,
    private _snackBar: MatSnackBar,
    private globalObject: GlobalObjectService,
    private location: Location,
    private authService: AuthService,
    private router: Router,
    private storageService: StorageService,
    private cohortService: CohortService,
    private cookieService: CookieServiceService,
    private dialog: MatDialog,
    private appUrlService: AppUrlService,
    private apiService: PPApiService,
    private ngxCookieService: CookieService,
    private showErrorService: ShowErrorService
  ) {
    this.getUserToken();
    const url = this._storageService.getCurrentUrlBeforeTest() || '';
    if (url.length > 0) {
      this.setTestBackUrl(url);
    }
  }

  setCartData(data: any) {
    this._storageService.setCartData(JSON.stringify(data));
  }

  getCartData() {
    return JSON.parse(this._storageService.getCartData());
  }

  removeCartData() {
    this._storageService.removeCartData();
  }

  setShowToast(data: boolean) {
    this.showToast$.next(data);
  }

  get _showToast$() {
    return this.showToast$;
  }

  setNameEmailTrigger(data: boolean) {
    this._triggerNameEmailModal$.next(data);
  }

  get triggerNameEmailModal$() {
    return this._triggerNameEmailModal$.asObservable();
  }

  setPaymentDetails(data: any) {
    this.paymentDetails = data;
    this._storageService.setPaymentDetails(JSON.stringify(data));
  }

  removePaymentDetails() {
    this._storageService.removePaymentDetails();
  }

  getPaymentDetails() {
    return JSON.parse(this._storageService.getPaymentDetails());
  }

  setIframeAfterPaymentRedirection(value: any) {
    this._storageService.setIframePaymentRedirectionLink(value);
  }
  getIframeAfterPaymentRedirection() {
    return JSON.parse(
      this._storageService.getIframePaymentRedirectionLink() || ''
    );
  }
  removeIframeAfterPaymentRedirection() {
    return this._storageService.removeIframePaymentRedirectionLink();
  }

  setPaymentRedirectionUrl(url: string) {
    this._storageService.setPaymentRedirectionLink(url);
  }

  getPaymentRedirectionUrl() {
    return this._storageService.getPaymentRedirectionUrl();
  }

  removePaymentRedirectionUrl() {
    return this._storageService.removePaymentRedirectionLink();
  }

  // logDeviceInfo() {
  //   const uuidFromStorage: string =
  //     this.globalObject.window!.localStorage.getItem('uuid') || '';
  //   if (uuidFromStorage.length > 0) {
  //     this.uuid = uuidFromStorage;
  //   } else {
  //     Device.getId().then((res: any) => {
  //       this.uuid = res.uuid;
  //       this.globalObject.window!.localStorage.setItem('uuid', this.uuid);
  //     });
  //   }
  // }
  authSuccessful() {
    this.isLoggedIn.next(true);
  }

  authFailure() {
    this.isLoggedIn.next(false);
  }

  getUserToken() {
    const token = this._storageService.getUserToken();

    if (token) {
      this.setAccessToken(token);
      this.isLoggedIn.next(true);
      this.getUserFromStorage();
    } else {
      this.authFailure();
    }
  }

  setEmailModalOpen(value: boolean) {
    this._openEmailModal$.next(value);
  }
  getEmailValueOpen() {
    return this._openEmailModal$;
  }

  /*getProgramDetails(programId: string) {
    const url = 'v1' + `/programs/${programId}`
    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService.get<any>(options).pipe(
      map((res) => res.data),
      tap(),
      catchError(handleError)
    );
  }*/

  showPurchasePop() {}

  setPaymentKeys(data: any) {
    this._storageService.setValue(this.PAYMENT_KEYS, data, 'json');
  }

  getPaymentKeys() {
    return this._storageService.getValue(this.PAYMENT_KEYS, 'json');
  }

  getUserFromStorage() {
    const user = this._storageService.getUser();
    if (typeof user === 'string') {
      this.setUser(JSON.parse(user));
    } else {
      this.setUser(user);
    }
  }

  setIneuronRedirectionUrl(url: string) {
    this.storageService.setValue(this.INEURON_REDIRECTION_URL, url, 'string');
  }
  getIneuronRedirectionUrl() {
    return this.storageService.getValue(this.INEURON_REDIRECTION_URL, 'string');
  }
  removeIneuronRedirectionUrl() {
    this.storageService.removeValue(this.INEURON_REDIRECTION_URL);
  }

  setAccessToken(token: any) {
    this.authSuccessful();
    this.accessToken = token;
    this._storageService.setUserToken(token);
    this._accessToken$.next(token);
    return;
  }

  setAppVersion(appVersion: string) {
    this._storageService.setAppVersion(appVersion);
  }

  getAppVersion() {
    return this._storageService.getAppVersion() || '';
  }

  setVideoDetails(details: any) {
    this._storageService.setValue('VIDEO_DETAILS', details, 'json');
  }

  setVideoId(videoId: any) {
    this._storageService.setValue('VIDEO_ID', videoId, 'string');
  }

  getVideoDetails() {
    return this._storageService.getValue('VIDEO_DETAILS', 'json');
  }

  removeVideoDetails() {
    return this._storageService.removeValue('VIDEO_DETAILS');
  }

  setIframeRedirection(data: any) {
    return this._storageService.setIframeRedirection(data);
  }

  getIframeRedirection() {
    return this._storageService.getIframeRedirection();
  }

  removeIframeRedirection() {
    return this._storageService.removeIframeRedirection();
  }

  setQBankReturnURL(url: string) {
    this._storageService.setValue('Q_BANK_REDIRECT_URL', url, 'string');
  }

  getQBankReturnURL() {
    return this._storageService.getValue('Q_BANK_REDIRECT_URL', 'string');
  }

  removeQBankReturnURL() {
    return this._storageService.removeValue('Q_BANK_REDIRECT_URL');
  }

  setJoiningId(details: any) {
    this._storageService.setValue('JOINING_ID', details, 'string');
  }

  getJoiningId() {
    return this._storageService.getValue('JOINING_ID', 'string');
  }

  removeJoiningId() {
    return this._storageService.removeValue('JOINING_ID');
  }

  getAccessToken() {
    return this._accessToken$;
  }

  openSideNav(value: boolean) {
    this.showSideNav$.next(value);
  }

  openLearnToEarnSideBar(value: boolean) {
    this.showLearnToEarnSideBar$.next(value);
  }

  setMaintenanceTimer(data: any) {
    return this._storageService.setTimerData(JSON.stringify(data));
  }

  getMaintenanceTimer() {
    return this._storageService.getTimerData();
  }

  removeMaintenanceTimer() {
    return this._storageService.removeTimerData();
  }

  isSideNavOpen() {
    return this.showSideNav$;
  }

  isSideSubjectOpen() {
    return this.showSideSubNav$;
  }

  isLearnToEarnSideBarOpen() {
    return this.showLearnToEarnSideBar$;
  }

  getAccessTokenFromStorage() {
    const token = this._storageService.getUserToken();
  }

  setPremiumSaarthiData(data: string) {
    this._storageService.setValue('premium_saarthi', data, 'string');
  }

  getPremiumSaarthiData() {
    return this._storageService.getValue('premium_saarthi', 'string');
  }

  removePremiumSaarthiData() {
    return this._storageService.removeValue('premium_saarthi');
  }

  setSaarthiTypeId(typeId: string) {
    this._storageService.setValue('addOnSaarthiTypeId', typeId, 'string');
  }

  getSaarthiTypeId() {
    return this._storageService.getValue('addOnSaarthiTypeId', 'string');
  }

  removeSaarthiTypeId() {
    return this._storageService.removeValue('addOnSaarthiTypeId');
  }
  setUser(user: any) {
    if (user) {
      const programId =
        user['profileId'] && user['profileId'].programId
          ? user['profileId'].programId._id
            ? user['profileId'].programId._id
            : user['profileId'].programId
          : '';

      if (programId) {
        this.setProgram(programId);
      }
      this._user$.next(user);
      return this._storageService.setUser(JSON.stringify(user));
    }
  }

  setProgram(programId: string) {
    if (programId.length > 0) {
      this.programId = programId;
      this._programId$.next(programId);
    }
  }

  setStoreItem(item: any) {
    this._storageService.setValue(this.STORE_ITEM, item, 'json');
  }

  getStoreItem() {
    return this._storageService.getValue(this.STORE_ITEM, 'json');
  }

  getUser() {
    return this._user$;
  }

  removeUser() {
    this._user$.next(null);
    this.setEmailModalOpen(false);
  }

  isAuth() {
    return this._accessToken$.value !== '';
  }

  showSnackBar(message: string, buttonText = 'OK', duration?: number) {
    this._snackBar.open(message, buttonText, {
      duration: duration || 2000,
      verticalPosition: 'top',
    });
  }

  setPdfUrl(data: string) {
    this._storageService.setValue('PDF', data, 'string');
  }

  getPdfUrl() {
    return this._storageService.getValue('PDF', 'json');
  }

  removePdf() {
    this._storageService.removeValue('PDF');
  }
  setPdfBackUrl(details: any) {
    this._storageService.setValue('pdf-back-url', details, 'json');
  }

  getPdfBackUrl() {
    return this._storageService.getValue('pdf-back-url', 'json');
  }

  removePdfBackUrl() {
    return this._storageService.removeValue('pdf-back-url');
  }

  clearStorage() {
    this._storageService.clearAllLocalStorage();
    localStorage.setItem('coach-mark', 'false');
  }

  setTest(test: TestData) {
    this._storageService.setValue('TEST_DETAILS', test, 'json');
  }

  getTest() {
    return this._storageService.getValue('TEST_DETAILS', 'json');
  }

  removeTest() {
    this._storageService.removeValue('TEST_DETAILS');
  }

  // recent-learning-ask-saarthi
  setRecentlearningAskSaarthi(data: any) {
    this._storageService.setValue('recent_learing_saarthi_data', data, 'json');
  }

  getRecentlearningAskSaarthi() {
    return this._storageService.getValue('recent_learing_saarthi_data', 'json');
  }

  removeRecentlearningAskSaarthi() {
    return this._storageService.removeValue('recent_learing_saarthi_data');
  }

  // @ts-ignore
  getTimeDifference(time: any) {
    const timeInMin = differenceInMinutes(new Date(), new Date(time));

    // In Mins
    if (timeInMin < 60) {
      return `${timeInMin} mins ago`;
    }

    // In Hours
    if (timeInMin > 60 && timeInMin < 1440) {
      if (Math.floor(timeInMin / 60) === 1) {
        return `${Math.floor(timeInMin / 60)} hour ago`;
      }
      return `${Math.floor(timeInMin / 60)} hours ago`;
    }

    // In Days
    if (timeInMin > 1440 && timeInMin < 43200) {
      return `${Math.floor(timeInMin / 1440)} day ago`;
    }

    // In Months
    if (timeInMin > 43200 && timeInMin < 525600) {
      return `${Math.floor(timeInMin / 43200)} months ago`;
    }

    // In Years
    if (timeInMin > 525600) {
      return `${Math.floor(timeInMin / 525600)} Year ago`;
    }
  }

  setChapterDetails(chapterdetails: any) {
    this._storageService.setValue(this.CHAPTER_DATA, chapterdetails, 'json');
  }

  getChapterDetails() {
    return this._storageService.getValue(this.CHAPTER_DATA, 'json');
  }

  removeChapterDetails() {
    return this._storageService.removeValue(this.CHAPTER_DATA);
  }

  setBookPdf(pdf: any) {
    this._storageService.setValue(this.PDF, pdf, 'json');
  }

  getBookPdf() {
    return this._storageService.getValue(this.PDF, 'json');
  }

  removeBookPdf() {
    this._storageService.removeValue(this.PDF);
  }

  setTopicData(topics: TopicData[]) {
    this._storageService.setValue(this.TOPIC_DATA, topics, 'json');
  }

  getTopicData() {
    return this._storageService.getValue(this.TOPIC_DATA, 'json');
  }

  removeTopicData() {
    return this._storageService.removeValue(this.TOPIC_DATA);
  }

  setPreferences(data: any) {
    this.setStudyTime();
    return this._storageService.setValue(this.PREFERENCES, data, 'json');
  }

  setStudyTime() {
    this._storageService.setStudyTime(new Date());
  }

  getStudyTime() {
    const time = this._storageService.getStudyTime();
    return differenceInHours(new Date(time), new Date());
  }

  getPreferences(type?: string) {
    if (type === 'study') {
      const time = Math.abs(this.getStudyTime());
      if (time > 3) {
        return {};
      } else {
        return this._storageService.getValue(this.PREFERENCES, 'json');
      }
    } else {
      return this._storageService.getValue(this.PREFERENCES, 'json');
    }
  }

  removePreferences() {
    return this._storageService.removeValue(this.PREFERENCES);
  }

  setLibraryPrograms(data: any) {
    return this._storageService.setValue(this.LIBRARY_DATA, data, 'json');
  }

  getLibraryPrograms() {
    return this._storageService.getValue(this.LIBRARY_DATA, 'json');
  }

  removeLibraryPrograms() {
    return this._storageService.removeValue(this.LIBRARY_DATA);
  }

  setDppBackUrl(url: string) {
    return this._storageService.setValue('dpp-back-url', url, 'json');
  }

  getDppBackUrl() {
    return this._storageService.getValue('dpp-back-url', 'json');
  }
  setNewTestBackUrl(url: string) {
    return this._storageService.setValue('new-test-back-url', url, 'string');
  }

  getNewTestBackUrl() {
    return this._storageService.getValue('new-test-back-url', 'json');
  }

  removeDppBackUrl() {
    return this._storageService.removeValue('dpp-back-url');
  }

  setFilterTags(data: any) {
    return this._storageService.setValue(this.filterTags, data, 'json');
  }

  getFilterTags() {
    return this._storageService.getValue(this.filterTags, 'json');
  }

  getProgramSlug() {
    return this._storageService.getValue(this.programSlug, 'string');
  }

  setProgramSlug(slug: string) {
    return this._storageService.setValue(this.programSlug, slug, 'string');
  }

  setChatToDppBackUrl(url: string) {
    return this._storageService.setValue('Chat-dpp-back-url', url, 'json');
  }

  getChatToDppBackUrl() {
    return this._storageService.getValue('Chat-dpp-back-url', 'string');
  }

  removeChatToDppBackUrl() {
    return this._storageService.removeValue('Chat-dpp-back-url');
  }

  // testSeries
  setChatToTestSeriesBackUrl(url: string) {
    return this._storageService.setValue(
      'chat-testSeries-back-url',
      url,
      'json'
    );
  }

  getChatToTestSeriesBackUrl() {
    return this._storageService.getValue('chat-testSeries-back-url', 'json');
  }

  removeChatToTestSeriesBackUrl() {
    return this._storageService.removeValue('chat-testSeries-back-url');
  }

  setSelectedNav(data: any) {
    return this._storageService.setValue('SelectedNav', data, 'string');
  }

  getSelectedNav() {
    return this._storageService.getValue('SelectedNav', 'string');
  }

  removeSelectedNav() {
    return this._storageService.removeValue('SelectedNav');
  }

  setBackUrlForTestSeries(url: string) {
    this._storageService.setValue('backUrlForTestSeries', url, 'json');
  }

  getBackUrlForTestSeries() {
    return this._storageService.getValue('backUrlForTestSeries', 'json');
  }

  removeBackUrlForTestSeries() {
    return this._storageService.removeValue('backUrlForTestSeries');
  }

  setTestBackUrl(data: string) {
    data = decodeURIComponent(data);

    this.saveUrlBeforeTest = data;
    this._saveUrlBeforeTest$.next(data);
    this._storageService.setCurrentUrlBeforeTest(data);
  }

  getTestBackUrl() {
    return this.storageService.getCurrentUrlBeforeTest() || '';
  }

  removeTestBackUrl() {
    this._saveUrlBeforeTest$.next('');
    this._storageService.removeCurrentUrlBeforeTest();
  }

  getDoubtConnectReloadCount() {
    return this._storageService.getValue(
      this.DOUBT_CONNECT_RELOAD_COUNT,
      'string'
    );
  }

  setDoubtConnectReloadCount(count: number) {
    this._storageService.setValue(
      this.DOUBT_CONNECT_RELOAD_COUNT,
      count,
      'string'
    );
  }

  setVideoStats(data: any) {
    const localData = this.getVideoStats() || [];

    if (localData.length > 0) {
      const filterArray =
        localData.filter((item: any) => item.videoId === data.videoId) || [];
      if (filterArray.length > 0) {
        const item = filterArray[0];
        item.details = data.details;
        this._storageService.setValue(
          this.VIDEO_STATS,
          JSON.stringify(localData),
          'string'
        );
      } else {
        localData.push(data);
        this._storageService.setValue(
          this.VIDEO_STATS,
          JSON.stringify(localData),
          'string'
        );
      }
    } else {
      const array = [];
      array.push(data);
      this._storageService.setValue(
        this.VIDEO_STATS,
        JSON.stringify(array),
        'string'
      );
    }
  }

  getVideoStats() {
    return this._storageService.getValue(this.VIDEO_STATS, 'json');
  }

  removeVideoStats() {
    return this._storageService.removeValue(this.VIDEO_STATS);
  }

  getActiveListSection() {
    return this.activeListSection$.getValue();
  }
  setActiveListSection(section: string) {
    this.activeListSection$.next(section);
  }

  setChaptersData(data: any) {
    return this._storageService.setValue(
      this.LIBRARY_SUBJECT_DATA,
      data,
      'json'
    );
  }

  getChaptersData() {
    return this._storageService.getValue(this.LIBRARY_SUBJECT_DATA, 'json');
  }

  removeChaptersData() {
    return this._storageService.removeValue(this.LIBRARY_SUBJECT_DATA);
  }

  setActiveLibrarySubject(sub: any) {
    return this._storageService.setValue(
      this.LIBRARY_ACTIVE_SUBJECT,
      sub,
      'json'
    );
  }

  getActiveLibrarySubject() {
    return this._storageService.getValue(this.LIBRARY_ACTIVE_SUBJECT, 'json');
  }

  removeActiveLibrarySubject() {
    return this._storageService.removeValue(this.LIBRARY_ACTIVE_SUBJECT);
  }

  setCameFrom(data: string) {
    return this.cameFromUrl$.next(data);
  }

  getCameFrom() {
    return this.cameFromUrl$;
  }

  setRandomId(value: string) {
    this._storageService.setRandomId(value);
  }

  setChatSaarthiBackUrl(url: string) {
    return this._storageService.setValue('chat-saarthi-back-url', url, 'json');
  }

  getChatSaarthiBackUrl() {
    return this._storageService.getValue('chat-saarthi-back-url', 'json');
  }

  removeChatSaarthiBackUrl() {
    return this._storageService.removeValue('chat-saarthi-back-url');
  }

  setNewUserLogin(value: boolean) {
    this.isNewUserLogin = value;
  }

  getNewUserLogin() {
    return this.isNewUserLogin;
  }

  getRandomId() {
    const idFromStorage = this._storageService.getRandomId() || '';
    if (idFromStorage.length === 0) {
      const randomId = uuidv4();
      this.setRandomId(randomId);
      return randomId;
    } else {
      return idFromStorage;
    }
  }

  async logoutUserGlobally(
    type: string,
    userClicked?: boolean,
    redirectUrl?: string
  ) {
    await this.logoutEvent(userClicked || false);
    (this.globalObject.window as any).Moengage.destroy_session();
    const deviceId = (await this._storageService.getDeviceId()) || '';
    if (userClicked) {
      await this.authService.logoutUser(deviceId).subscribe();
    }
    await this.logoutEvent(userClicked || false);
    (this.globalObject.window as any).Moengage.destroy_session();

    this.setAccessToken('');
    this.removeUser();
    this.isLoggedIn.next(false);
    this.authFailure();
    this.clearStorage();
    this.cohortService.clearCohortData();
    this.cookieService.deleteAllCookies();
    this.ngxCookieService.delete('TOKEN_CONTEXT');
    this.cookiePaths.forEach((path) => {
      this.ngxCookieService.deleteAll(path);
    });
    this.cookieService.deleteAllCookies();

    this.ngxCookieService.deleteAll();

    // if (type === 'relative') {
    //   await this.router.navigate(['/auth'], {
    //     queryParams: {
    //       url: window.location.pathname.split('/study/')[1],
    //     },
    //   });
    // } else {
    //   await this.router.navigate(['/auth']);
    // }

    let url = window.location.origin;

    if (redirectUrl) {
      url += `?redirectUrl=${redirectUrl}`;
    }

    this.storageService.removeReferredBy();
    window.location.assign(url);
  }

  async logoutEvent(userClicked: boolean) {
    const idFromStorage = this._storageService.getRandomId() || '';
    const token = this._storageService.getUserToken();
    const refresh_token = this._storageService.getRefreshToken() || '';
    const response_code = this._storageService.getResponceCode() || '200';
    const req_url = this._storageService.getRequestUrl() || '';
    const eventData = {
      UserPhoneNumber: this._user$.getValue()?.primaryNumber,
      type: '',
      randomid: idFromStorage,
      access_token: token,
      url: req_url,
      response_code: '',
      refresh_token: refresh_token,
    };
    if (userClicked) {
      eventData['type'] = 'user_clicked_logout';
      eventData['response_code'] = '200';
    } else {
      eventData['type'] = 'system_generated_logout';
      eventData['response_code'] = response_code;
    }

    if (!refresh_token && !token) return;

    this.logoutEvent$.next(eventData);
  }

  setAppliedCoupon(code: string) {
    this.storageService.setAppliedCoupon(code);
  }

  getAppliedCoupon() {
    return this.storageService.getAppliedCoupon();
  }

  setRefreshToken(token: string) {
    this.refreshToken = token;
    this._refreshToken$.next(token);
    this._storageService.setRefreshToken(token);
    return;
  }

  removeAppliedCoupon() {
    this.storageService.removeAppliedCoupon();
  }
  isEmpty(obj: any) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }
  setQuestion(details: any) {
    this._storageService.setValue('QUESTION', details, 'string');
  }

  getQuestion() {
    return this._storageService.getValue('QUESTION', 'string');
  }

  removeQuestion() {
    return this._storageService.removeValue('QUESTION');
  }
  setAnswer(details: any) {
    this._storageService.setValue('ANSWER', details, 'string');
  }

  getAnswer() {
    return this._storageService.getValue('ANSWER', 'string');
  }

  removeAnswer() {
    return this._storageService.removeValue('ANSWER');
  }
  setSearchQuery(details: any) {
    this._storageService.setValue('SEARCH_TEXT', details, 'string');
  }

  getSearchQuery() {
    return this._storageService.getValue('SEARCH_TEXT', 'string');
  }

  removeSearchQuery() {
    return this._storageService.removeValue('SEARCH_TEXT');
  }

  setFormListToStorage(data: any) {
    return this._storageService.setValue(this.FORM_LIST, data, 'json');
  }

  getFormListFromStorage() {
    return this._storageService.getValue(this.FORM_LIST, 'json');
  }

  removeFormListFromStorage() {
    return this._storageService.removeValue(this.FORM_LIST);
  }

  setUserSegmentToStorage(data: any) {
    return this._storageService.setValue(
      this.BATCH_USER_SEGMENT,
      data,
      'string'
    );
  }

  getUserSegmentFromStorage() {
    let userSegment = this._storageService.getValue(
      this.BATCH_USER_SEGMENT,
      'string'
    );
    try {
      userSegment = JSON.parse(userSegment);
    } catch (error) {}

    return userSegment;
  }

  removeUserSegmentFromStorage() {
    return this._storageService.removeValue(this.BATCH_USER_SEGMENT);
  }

  setIsPathShalaToStorage(data: any) {
    return this._storageService.setValue(this.IS_PATHSHALA, data, 'string');
  }

  getIsPathShalaFromStorage() {
    return this._storageService.getValue(this.IS_PATHSHALA, 'string');
  }

  removeIsPathShalaFromStorage() {
    return this._storageService.removeValue(this.IS_PATHSHALA);
  }

  removeSessionStorage() {
    return this._storageService.removeSessionStorage();
  }

  /* for saving centre and city name */
  setCityCentreName(data: any) {
    return this._storageService.setValue(this.VIDYAPEETH_DETAILS, data, 'json');
  }

  getCityCentreName() {
    return this._storageService.getValue(this.VIDYAPEETH_DETAILS, 'json');
  }

  removeCityCentreName() {
    return this._storageService.removeValue(this.VIDYAPEETH_DETAILS);
  }

  cohortCheckOnSelf(userInfo: any) {
    const cohortId = userInfo['profileId']['cohortId'] || '';
    const needCohortUpdate = userInfo['profileId']['needCohortUpdate'] || false;
    const hasExam = userInfo['profileId']['exams']?.length > 0;
    // this.cohortService.setCohortId$(cohortId);
    if (
      userInfo['profileId']['exams'] &&
      userInfo['profileId']['class'] &&
      userInfo['profileId']['language']
    ) {
      const userPreference = {
        exam: userInfo['profileId']['exams'],
        class: userInfo['profileId']['class'],
        language: userInfo['profileId']['language'],
      };

      this.cohortService.setUserPreferences(userPreference);
    }
    if ((needCohortUpdate || !cohortId?.length) && hasExam) {
      this.cohortService.setNeedCohortUpdate(true);
    } else {
      this.cohortService.setNeedCohortUpdate(false);
    }
  }

  getFormList(query?: any) {
    query = query || {};
    const options: PPApiOptions = {
      apiPath: this.appUrlService.GET_FORM_LIST_URL(),
      params: new PPApiParams().appendAll(query),
    };
    return this.apiService.get<any>(options).pipe(
      map((res: any) => res.data),
      catchError(handleError)
    );
  }

  getThankYouPageDetails(formId: string) {
    const options: PPApiOptions = {
      apiPath: this.appUrlService.GET_THANKYOU_DETAIL(formId),
    };
    return this.apiService.get<any>(options).pipe(
      map((res: any) => res.data),
      catchError(handleError)
    );
  }

  async handleFormList() {
    const query = {
      formType: 'ADMIT_CARD',
    };
    try {
      const res = await lastValueFrom(this.getFormList(query));
      if (res) {
        let data: any[] = res.map((item: any) => new FormList(item)) || [];

        if (data.length > 0) {
          data = data.filter((form) => {
            return (
              !form.isAlreadySubmitted ||
              (form.isAlreadySubmitted && form.needToReSubmit)
            );
          });
        }

        const form = {
          lastFetched: new Date().getTime(),
          data,
        };
        this.setFormListToStorage(form);
      }
    } catch (e) {
      console.log('error', e);
    }
  }

  openAdmitCardModal(formId: string, isMandatory: boolean, trigger: string) {
    isMandatory = isMandatory || false;

    const isOpen = this.dialog.getDialogById('admit-card-popup');
    if (isOpen || this.accessToken.length === 0) return;
    const dialog = this.dialog.open(AdmitCardModalComponent, {
      panelClass: 'admit-card-modal',
      id: 'admit-card-popup',
      data: {
        formId,
        isMandatory,
        trigger,
      },
      disableClose: true,
    });

    dialog.afterClosed().subscribe((result) => {});
    this.closeParamsobj = {
      id: formId,
      triggerEvent: trigger,
      type: 'ADMIT_CARD',
      action: 'close',
    };
  }
  async triggerAdmitCard(triggerType: FORM_TRIGGERS) {
    let formList = this.getFormListFromStorage()?.data || [];
    if (!formList?.length) {
      await this.handleFormList();
      formList = this.getFormListFromStorage()?.data || [];
    }

    formList.forEach((form: FormList) => {
      if (form.needToDisplay) {
        form.triggerEvents.forEach((event) => {
          if (event.type === triggerType) {
            this.openAdmitCardModal(form._id, event.isMandatory, triggerType);

            return;
          }
        });
      }
    });
  }

  showEMIReminder(reminderConfig: {
    isMultipleEMIAvailable: boolean;
    instalments: Array<UpcomingInstalments>;
    surl: string;
    furl: string;
    page: string;
  }) {
    this.dialog.open(PaymentReminderComponent, {
      width: '480px',
      maxHeight: '590px',
      disableClose: true,
      data: {
        ...(reminderConfig || {}),
      },
    });
  }

  getEMIInfo(payload: { batchId: string; surl: string; furl: string }) {
    const url = this.appUrlService.GET_EMI_INFO();
    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService.post<any>(payload, options).pipe(
      map((res: any) => res.data),
      catchError(handleError)
    );
  }

  getEzPayUrl(payload: EMIInfoResponse) {
    const url = this.appUrlService.GET_EZ_PAY_SDK_URL();
    const options: PPApiOptions = {
      apiPath: url,
    };
    return this.apiService.post<any>(payload, options).pipe(
      map((res: any) => res.data),
      catchError(handleError)
    );
  }

  async fetchEzPayInfo(res: EMIInfoResponse) {
    const data = new EMIInfoResponse(res);

    const ezPayRes = await lastValueFrom(this.getEzPayUrl(data));

    return new EzPayResponse(ezPayRes);
  }

  async fetchEMIInfo(payload: { batchId: string; surl: string; furl: string }) {
    const res = await lastValueFrom(this.getEMIInfo(payload));
    if (res) {
      const data = new EMIInfoResponse(res);

      const ezPayRes = await lastValueFrom(this.getEzPayUrl(data));

      return new EzPayResponse(ezPayRes);
    }

    return new EzPayResponse({});
  }

  async handleEMIPayment({
    batchId,
    surl,
    furl,
  }: {
    batchId: string;
    surl: string;
    furl: string;
  }) {
    try {
      const res = await this.fetchEMIInfo({
        batchId,
        surl,
        furl,
      });

      if (res?.url) {
        window.open(res?.url, '_self');
      }
    } catch (error: any) {
      if (error && error?.message) {
        const errorObj = {
          ...error,
          message: Array.isArray(error?.message)
            ? error?.message[0]
            : error?.message,
        };
        await this.showErrorService.showError(errorObj);
      }
    }
  }

  closeAdmitCard() {
    return new Promise((resolve, reject) => {
      const { id, triggerEvent, type, action } = this.closeParamsobj;
      const options: PPApiOptions = {
        apiPath: this.appUrlService.POST_SUBMIT_FORM(id),
      };

      const body: Body = {
        type: type,
        action: action,
        triggerEvent: triggerEvent,
      };

      this.apiService
        .post<any>(body, options)
        .pipe(
          map((res: any) => res.data),
          catchError((error: any) => {
            reject(error);
            return handleError(error);
          })
        )
        .subscribe((result: any) => {
          resolve(result);
          const storedDataString = localStorage.getItem('form_list');
          let storedData;

          if (storedDataString !== null) {
            storedData = JSON.parse(storedDataString);
          } else {
            console.error(
              'No data found in LocalStorage for the specified key!'
            );
          }
          if (
            storedData &&
            Array.isArray(storedData.data) &&
            storedData.data.length > 0
          ) {
            const targetIndex = storedData.data.findIndex(
              (obj: StoredListObj) =>
                obj._id === id && obj.needToDisplay === true
            );
            if (targetIndex !== -1) {
              storedData.data[targetIndex].needToDisplay = false;
              localStorage.setItem('form_list', JSON.stringify(storedData));
            }
          }
        });
    });
  }

  getBatchUserSegment(queryParams: any = {}) {
    const options: PPApiOptions = {
      apiPath: this.appUrlService.GET_USER_SEGMENT(),
      params: new PPApiParams().appendAll(queryParams),
    };
    return this.apiService.get<any>(options).pipe(
      map((res: any) => res.data),
      catchError(handleError)
    );
  }

  querySplitter(query: string) {
    let cookieArr = query.split('&');
    let queryObj: any = {};
    for (let i = 0; i < 3; i++) {
      let cookiePart = cookieArr[i].split(/=(.*)/s); //splits from first occurrence of "="
      queryObj[cookiePart[0]] = cookiePart[1];
    }
    console.log(queryObj);
    return queryObj;
  }

  setHasAdmitCardTriggered(data: any) {
    this.storageService.setSessionValue('ADMIT_CARD_TRIGGERED', data, 'json');
  }

  getHasAdmitCardTriggered() {
    return this.storageService.getSessionValue('ADMIT_CARD_TRIGGERED', 'json');
  }

  setBatchActiveTab(activeTab: string) {
    this.batchActiveTab.next(activeTab);
  }

  getBatchActiveTab() {
    return this.batchActiveTab.getValue();
  }

  titleCase(str: string): string {
    if (!str) return '';
    return str
      .toLowerCase()
      .split(' ')
      .map(function (word: string) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');
  }

  getCampaignData() {
    const encodedString = this.storageService.getCampaignData();

    if (encodedString) {
      const campaignData = Base64.decode(encodedString);
      return JSON.parse(campaignData);
    }

    return;
  }

  updateUserLocally(data: any) {
    const user = this._user$.getValue();
    if (user && user?.profileId) {
      user['profileId']['board'] = data?.board;
      user['profileId']['exams'] = data?.exams;
      user['profileId']['class'] = data?.class;
      user['profileId']['stream'] = data?.stream;
      user['profileId']['language'] = data?.language;
      user['profileId']['cohortId'] = data?.cohortId;

      this.setUser(user);
    }
  }

  getUnleashFeatureFlag(data: UnleashApiPayloadType) {
    const url = this.appUrlService.UNLEASH_FEATURE_FLAG();
    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService.post(data, options).pipe(
      map((res: any) => res.data),
      catchError(handleError)
    );
  }

  async unleashFeatureFlag(
    data: UnleashApiPayloadType
  ): Promise<UnleashDataType> {
    try {
      const res = await lastValueFrom(this.getUnleashFeatureFlag(data));
      if (res) {
        return <UnleashDataType>res;
      }
      return <UnleashDataType>{};
    } catch (error) {
      this.showErrorService.showError(error);
      return <UnleashDataType>{};
    }
  }

  async batcheUnleashFeatureCheck() {
    const url = this.router.url.includes('?')
      ? this.router.url.split('?')[0]
      : this.router.url;

    if (url === '/batches') {
      return;
    }
    const user = this._user$.getValue();
    const unleashQuery: UnleashApiPayloadType = {
      userId: user?.id || '',
      featureName: 'batch-revamp-fe',
      experimentName: 'batch-revamp-fe',
      customData: {
        id: '',
      },
      sendAnalytics: true,
    };
    const res = await this.unleashFeatureFlag(unleashQuery);
    if (
      res?.isEnabled &&
      res?.variantData?.payload?.value === UNLEASH_PAYLOAD_ENUM.REVAMP_ENABLED
    ) {
      this.batchRevampUnleash$.next(true);
      this.storageService.setIsBatchRevampEnabled(true);
    } else {
      this.batchRevampUnleash$.next(false);
      this.storageService.setIsBatchRevampEnabled(false);
    }
  }

  setPreviousUrl$(url: string) {
    this.previousUrl$.next(url);
  }

  get _previousUrl$() {
    return this.previousUrl$.getValue();
  }

  generateWhatsappLink(batchSlug: string) {
    const user = this._storageService.getUser();
    let userId = '';
    if (user) {
      userId = JSON.parse(user)?.id;
    }
    const urlEncodedText = encodeURI(
      `Looking for quality education content at an affordable prices? Check out this course from PhysicsWallah and get enrolled to crack the toughest exams with best in class study material and top rated faculties. : https://www.pw.live/study/batches/${batchSlug}/batch-overview?referred_by=${userId}`
    );
    return `https://api.whatsapp.com/send?text=${urlEncodedText}`;
  }
}

export class TriggerEvent {
  type: string;
  typeList: string[];
  isMandatory: boolean;

  constructor(data: any) {
    data = data || {};
    this.type = data.type || '';
    this.typeList = data.typeList || '';
    this.isMandatory = data.isMandatory || false;
  }
}

export class FormList {
  _id: string;
  name: string;
  type: string;
  triggerEvents: TriggerEvent[];
  isAlreadySubmitted: boolean;
  needToReSubmit: boolean;
  needToDisplay: boolean;

  constructor(data: any) {
    data = data || {};
    this._id = data._id || '';
    this.name = data.name || '';
    this.isAlreadySubmitted = data.isAlreadySubmitted || false;
    this.needToReSubmit = data.needToReSubmit || false;
    this.needToDisplay = data.needToDisplay || false;
    if (data.triggerEvents) {
      this.triggerEvents = data.triggerEvents.map(
        (item: any) => new TriggerEvent(item)
      );
    } else {
      this.triggerEvents = [];
    }
  }
}

export interface UnleashDataType {
  isEnabled: boolean;
  variantData: VariantData;
}

export interface VariantData {
  name: string;
  payload: Payload;
  enabled: boolean;
  feature_enabled: boolean;
  featureEnabled: boolean;
}

export interface Payload {
  type: string;
  value: string;
}

export interface UnleashApiPayloadType {
  userId: string;
  featureName: string;
  experimentName: string;
  customData: any;
  sendAnalytics: boolean;
}
