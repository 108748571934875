import {
  AfterViewInit,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ContentService,
  PlayerEvent,
  SaveVideoStatsModal,
} from 'src/app/common/services/content/content.service';
import { GlobalService } from 'src/app/common/services/global/global.service';
import { SaarthiService } from 'src/app/common/services/saarthi/saarthi.service';
import { ShowErrorService } from 'src/app/common/services/showError/show-error.service';
import { StorageService } from 'src/app/common/services/storage/storage.service';
import { VideolicenseService } from 'src/app/common/services/videolicense/videolicense.service';
import isAfter from 'date-fns/isAfter';
import { addHours } from 'date-fns';
import { lastValueFrom, timer } from 'rxjs';
import { Location } from '@angular/common';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ReportComponent } from 'src/app/pages/batch/batch-video-player/report/report.component';
import {
  ACTIVE_REPORT_IMG,
  CROSS,
  DISLIKE,
  DISLIKED_FILLED,
  INACTIVE_REPORT_IMG,
  LIKE,
  LIKE_UNFILLED,
  SCAN,
  WHATSAPP_ICON,
} from 'src/app/core/assets.location';
import { LoaderService } from 'src/app/common/services/loader/loader.service';
import { ReportInstructionComponent } from 'src/app/pages/batch/batch-video-player/report-instruction/report-instruction.component';
import { Platform } from '@angular/cdk/platform';
import {
  APP_VERSION,
  MAIN_PROGRAM,
} from 'src/app/constants/global-constant/global-constant.service';
import { ReportService } from 'src/app/common/services/report/report.service';
import { ReportModel } from 'src/app/common/services/report/report.model';
import { environment } from 'src/environments/environment';
import { PlayerConfigService } from 'src/app/common/services/player-config/player-config.service';

declare const videojs: any;

enum FEEDBACK_TYPE {
  SAARTHI_QUESTION = 'SAARTHI_QUESTION',
  QUESTION_TYPE = 'SAARTHI_QUESTION',
}

@Component({
  selector: 'app-video-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.scss'],
})
export class VideoModalComponent implements OnInit {
  videoSource: string = '';
  questionSolution: string = '';
  question: string = '';
  type: any;
  isText: boolean = false;
  enterValue: any;
  platForm: any;
  timer: any;
  timeSpent: number;
  videoDetails: any;
  videoType: string;
  playerConfig: any;
  playType = 'hls';
  vimeoId: string;
  videojs: any;
  epochStartTime: number;
  epochEndTime: number;
  mode: string;
  particularContentId: any;
  fileName: any;
  extension: any;
  file: any;
  userInfo: any;
  activeReportImg = ACTIVE_REPORT_IMG;
  inactiveReportImg = INACTIVE_REPORT_IMG;
  activeSection: string;
  videoTitle: string;
  saarthiId: string;
  isLiked: boolean;
  ratingValue: number;
  @ViewChild('player', { static: false }) playerRef: any;
  player: any;
  initialPlayerInfo: PlayerEvent;
  startTimeStamp: number;
  startTime: number;
  videoStats: SaveVideoStatsModal;
  question_id: string;
  scan = SCAN;
  like = LIKE;
  liked = LIKE_UNFILLED;
  dislike = DISLIKE;
  disLiked = DISLIKED_FILLED;
  whatsapp = WHATSAPP_ICON;
  whatsappShare: string;
  isDisliked: boolean;
  cross = CROSS;
  constructor(
    private route: ActivatedRoute,
    private saarthiService: SaarthiService,
    public router: Router,
    private videoLicenseService: VideolicenseService,
    private globalService: GlobalService,
    private contentService: ContentService,
    private showErrorService: ShowErrorService,
    private storageService: StorageService,
    private location: Location,
    public dialog: MatDialog,
    private platform: Platform,
    private playerConfigService: PlayerConfigService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<VideoModalComponent>
  ) {
    this.videoStats = new SaveVideoStatsModal({});

    this.videoType = this.data?.videoDetails?.url_type || this.data?.videoType;
  }

  ngOnInit(): void {
    const res = this.data?.videoDetails;

    if (this.isEmpty(res) || res.embedCode === '' || res.url === '') {
      this.globalService.showSnackBar('No Preview Available!', 'OK');
      this.location.back();
    } else {
      this.videoDetails = res;

      this.videoTitle =
        this.videoDetails?.name ||
        this.videoDetails?.title ||
        this.videoDetails?.name;

      this.getUser();
      this.playerInit();
    }
  }
  ngAfterViewInit() {
    // this.prepareVideoStats();
  }
  getUser() {
    this.userInfo = this.globalService.getUser().value;
  }
  goToDetails() {
    // if (this.enterValue && !this.fileName && !this.extension) {
    //   this.router.navigate(['saarthi/doubts/solution-listing'], {
    //     queryParams: { text: this.enterValue },
    //   });
    // } else if (this.fileName && this.extension) {
    //   this.router.navigate(['saarthi/doubts/solution-listing'], {
    //     queryParams: {
    //       // file: this.saarthiService.image,
    //       filename: this.fileName,
    //       extension: this.extension,
    //     },
    //   });
    // }
    try {
      if (this.playerRef.playerInfo !== undefined) {
        this.playerRef.playerInfo.playTime = this.player?.currentTime();
        this.playerRef.playerInfo.remainingTime =
          Math.floor(this.playerRef?.playerInfo.duration) -
          Math.floor(this.player.currentTime());
        this.initialPlayerInfo = new PlayerEvent(this.playerRef?.playerInfo);
        const data = {
          ...this.initialPlayerInfo,
          startTimeStamp: this.startTimeStamp,
          startTime: this.startTime || 0,
        };
        this.saveStats(data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      this.location.back();
    }
    //  this.globalObjectService.closeFullscreen();
  }

  playerLoaded(data: any) {
    this.player = data;
  }
  handleVideoPlay(event: any) {
    this.startTimeStamp = new Date().getTime();
    this.startTime = event.playTime || 0;
    this.initialPlayerInfo = new PlayerEvent(this.playerRef.playerInfo);
  }
  async handleVideoPause(event: any) {
    this.initialPlayerInfo = new PlayerEvent(this.playerRef.playerInfo);
    const data = {
      ...this.initialPlayerInfo,
      startTimeStamp: this.startTimeStamp,
      startTime: this.startTime,
    };
    // this.saveStats(data);
  }
  saveStats(event: any) {
    this.videoStats.isComplete = event['ended'] || false;
    this.videoStats.lastWatchedPointInSec = Math.floor(event.playTime) || 0;
    this.videoStats.videoLength = Math.floor(event.duration) || 0;
    const watchStats = {
      endTime: Math.floor(event.playTime) || 0,
      endTimeTimestamp: new Date().getTime(),
      startTime: Math.floor(event.startTime) || 0,
      startTimeTimestamp: event.startTimeStamp || 0,
    };
    // if (watchStats.startTime > watchStats.endTime) {
    //   return;
    // }
    this.videoStats.watchSessions[0].watchStats.push(watchStats);
  }
  handleFullScreenChange(event: any) {}

  async sendStats() {
    try {
      const res: any = lastValueFrom(
        this.contentService.saveVideo(this.videoStats)
      );
      if (res) {
      }
    } catch (e) {
      // await this.showErrorService.showError(e);
    } finally {
    }
  }
  prepareVideoStats() {
    this.videoStats = {
      batchId: '',
      batchSubjectId: '',
      chapterId: '',
      isComplete: false,
      lastWatchedPointInSec: 0,
      programId: MAIN_PROGRAM,
      subTopicId: '',
      subjectId: '',
      topicId: '',
      type: 'ask_doubt',
      typeId: this.videoDetails._id || this.videoDetails.typeId,
      userId: this.userInfo.id,
      videoId:
        this.videoDetails?.video_details?._id ||
        this.videoDetails?.video_details?.id ||
        'NA',
      videoLength: 0,
      watchSessions: [
        {
          deviceType: 'Desktop',
          medium: 'Web',
          isLive: (this.videoDetails && this.videoDetails.isLive) || false,
          videoType: this.videoDetails && this.videoDetails.urlType,
          clientVersion: +APP_VERSION,

          watchStats: [],
        },
      ],
    };
  }

  async playerInit() {
    if (this.player) {
      this.player.reset();
    }
    if (this.platForm === 'desktop') {
      this.globalService.openSideNav(false);
    }
    const source = timer(1000, 1000);
    this.timer = source.subscribe((val) => {
      this.timeSpent++;
    });
    const name =
      this.videoDetails?.videoDetails?.name ||
      this.videoDetails?.videoDetails?.title ||
      this.videoDetails?.name ||
      '';
    const status = this.videoDetails?.videoDetails?.status || '';
    const poster =
      this.videoDetails?.videoDetails?.image ||
      this.videoDetails?.videoDetails?.thumbnail_url ||
      this.videoDetails?.image;

    const videoUrl =
      this.videoDetails?.videoDetails?.embedCode ||
      this.videoDetails?.videoDetails?.hls_url ||
      this.videoDetails?.videoDetails?.videoUrl ||
      this.videoDetails?.url ||
      this.videoDetails?.embedCode ||
      this.videoDetails?.video_url ||
      this.videoDetails?.videoUrl ||
      this.videoDetails?.embed_code ||
      '';
    const isLive = this.videoDetails.isLive || false;
    const isEnableFullScreen = false;
    if (
      (this.videoDetails.videoType === 'youtube' ||
        this.videoType === 'youtube') &&
      videoUrl
    ) {
      this.playerConfig = {
        poster: poster,
        liveui: isLive,
        sources: [
          {
            src: videoUrl,
            type: 'video/youtube',
          },
        ],
        autoplay: true,
        startTime: 0,
        fullScreenEnabled: isEnableFullScreen,
        fluid: true,
        fill: false,
        responsive: false,
        seekButtons: true,
        seekSeconds: 10,
        /*   watermark: {
                       text: this.userInfo ? this.userInfo.username : this.globalService.getUser().getValue().username
                   }*/
      };
    } else if (
      (this.videoDetails.videoType === 'vimeo' || this.videoType === 'vimeo') &&
      videoUrl &&
      !this.videoDetails.isLive &&
      this.videoDetails.videoDetails.hls_url
    ) {
      const vimeoId = this.videoDetails.videoDetails.vimeoId;
      let videoDownloadResponses;
      if (this.playType === 'mp4') {
        videoDownloadResponses = await this.contentService
          .getVimeoDownloadUrl({
            vimeoId: vimeoId,
            type: 'downloads',
          })
          .toPromise();
      }

      let sources = [];
      if (
        videoDownloadResponses &&
        videoDownloadResponses['data'] &&
        videoDownloadResponses['data'].length
      ) {
        sources = videoDownloadResponses['data'].map((el: any) => {
          return {
            src: el.link,
            type: 'video/mp4',
            label: el.height,
          };
        });
      } else {
        sources = [
          {
            src: videoUrl,
            type: 'application/x-mpegURL',
          },
        ];
      }

      this.loadNewVideoPlayer(name, poster, sources);
    } else if (
      (this.videoDetails.videoType === 'vimeo' || this.videoType === 'vimeo') &&
      videoUrl &&
      !this.videoDetails.isLive &&
      !this.videoDetails.videoDetails.hls_url
    ) {
      try {
        const resp = await this.contentService.getVimeoDownloadUrl({
          vimeoId: this.vimeoId,
          type: 'downloads',
        });

        // @ts-ignore
        if (!resp || !resp['data']) {
          return;
        }

        // @ts-ignore
        const sourcesMp4 = resp['data'].map((el: any) => {
          return {
            src: el.link,
            type: 'video/mp4',
            label: el.height,
          };
        });
        this.playerConfig = {
          poster: poster,
          liveui: isLive,
          sources: sourcesMp4,
          autoplay: true,
          startTime: 0,
          fullScreenEnabled: isEnableFullScreen,
          fluid: true,
          fill: false,
          responsive: false,
          seekButtons: true,
          seekSeconds: 10,
          /* watermark: {
                         text: this.userInfo ? this.userInfo.username : this.globalService.getUser().getValue().username
                     }*/
        };
      } catch (e) {
        await this.showErrorService.showError(e);
      }
    } else if (
      (this.videoDetails.videoType === 'penpencilvdo' ||
        this.videoType === 'penpencilvdo') &&
      videoUrl
    ) {
      this.playerConfig = await this.playerConfigService.penpencilLogic(
        this.videoDetails.videoDetails,
        isLive,
        videoUrl,
        poster,
        isEnableFullScreen
      );
    } else if (
      this.videoDetails.videoType === 'awsVideo' ||
      this.videoType === 'awsVideo'
    ) {
      this.epochStartTime =
        new Date(this.videoDetails.startTime).getTime() / 1000;
      this.epochEndTime = new Date(this.videoDetails.endTime).getTime() / 1000;
      const cookie = await this.getAWSKey(videoUrl);
      const changedCookie = this.setCharAt(cookie, 0, '&');
      const playerConfigLocal: any = {
        poster: poster,
        liveui: isLive,
        sources: [],
        autoplay: true,
        startTime: 0,
        fullScreenEnabled: isEnableFullScreen,
        fluid: true,
        fill: false,
        responsive: false,
        seekButtons: true,
        seekSeconds: 0,
        query: cookie.replace('?', ''),
      };

      if (!this.videoDetails.isLive && this.epochEndTime === 0) {
        this.globalService.showSnackBar(
          `No End Time found. Taking You Back...`
        );
      }
      playerConfigLocal.sources = [
        {
          // @ts-ignore
          src: this.videoDetails.isLive
            ? videoUrl + `?start=${this.epochStartTime}${changedCookie}`
            : videoUrl +
              `?start=${this.epochStartTime}${changedCookie}&end=${this.epochEndTime}`,
          // @ts-ignore
          type: 'application/x-mpegURL',
          // @ts-ignore
          withCredentials: false,
          // @ts-ignore
          cacheEncryptionKeys: true,
        },
      ];
      this.playerConfig = playerConfigLocal;
    } else if (
      this.videoDetails.embedCode &&
      this.videoDetails.embedCode.includes('youtube') &&
      this.mode === 'public-feeds'
    ) {
      const poster = this.videoDetails.imageUrl;
      const videoUrl = this.videoDetails.embedCode;
      this.particularContentId = this.videoDetails.id;
      const name = this.videoDetails.name;
      const vimeoId = this.videoDetails.vimeoId;
      this.playerConfig = {
        poster: poster,
        liveui: false,
        sources: [
          {
            src: videoUrl,
            type: 'video/youtube',
          },
        ],
        autoplay: true,
        startTime: 0,
        fullScreenEnabled: false,
        fluid: true,
        fill: false,
        responsive: false,
        seekButtons: true,
        seekSeconds: 10,
        /*   watermark: {
        text: this.waterMarkText || this.globalService.getUser().getValue().username
              }*/
      };
    } else if (!this.videoDetails.isLive) {
      await this.globalService.showSnackBar('Video url does not exists');
    }
  }
  loadNewVideoPlayer(title: any, image: any, sources: any) {
    this.playerConfig = {
      poster: image,
      liveui: false,
      sources: sources,
      autoplay: true,
      startTime: 0,
      fullScreenEnabled: false,
      fluid: true,
      fill: false,
      responsive: false,
      seekButtons: true,
      seekSeconds: 10,
      /* watermark: {
                 text: this.userInfo ? this.userInfo.username : this.globalService.getUser().getValue().username
             }*/
    };
  }

  async getLicenseFromServer(emeOptions: any, keyMessage: any, callback: any) {
    const request = JSON.parse(new TextDecoder().decode(keyMessage));
    const token = this.globalService.accessToken;

    const params = {
      key: this.videoLicenseService.hexEncode(
        this.videoLicenseService.encrypt(
          token,
          this.videoLicenseService.base64ToHex(request.kids[0])
        )
      ),
      isEncoded: true,
    };
    try {
      const response = await this.videoLicenseService
        .getKeyFromServer(params)
        .toPromise();
      const keyObj = {
        kty: 'oct',
        kid: request.kids[0],
        k: this.videoLicenseService.hexToBase64(
          this.videoLicenseService.decrypt(
            this.globalService.accessToken,
            response['data'].otp
          )
        ), // This key should be come from server
      };

      callback(
        null,
        new TextEncoder().encode(
          JSON.stringify({
            keys: [keyObj],
          })
        )
      );
    } catch (e) {}
  }
  async getAWSKey(videoUrl: string) {
    const awsCookie = this.storageService.getAwsCookie() || '[]';
    const cookieArray = JSON.parse(awsCookie) || [];
    if (cookieArray && cookieArray.length > 0) {
      const cookieDataFromStorage = cookieArray.filter(
        (item: any) => item.videoUrl === videoUrl
      )[0];
      if (
        cookieDataFromStorage &&
        Object.keys(cookieDataFromStorage).length > 0
      ) {
        if (
          isAfter(
            new Date(),
            new Date(addHours(cookieDataFromStorage.createdAt, 6))
          )
        ) {
          const cookieData = {
            videoUrl: videoUrl,
            cookie: '',
          };
          return this.getAwsCookieViaNetwork(cookieData, cookieArray);
        } else {
          return cookieDataFromStorage['cookie'];
        }
      } else {
        const cookieData = {
          videoUrl: videoUrl,
          cookie: '',
        };
        return this.getAwsCookieViaNetwork(cookieData, cookieArray);
      }
    } else {
      const cookieData = {
        videoUrl: videoUrl,
        cookie: '',
      };
      return await this.getAwsCookieViaNetwork(cookieData, cookieArray);
    }
  }
  setCharAt(str: string, index: number, chr: string) {
    if (index > str.length - 1) {
      return str;
    }
    return str.substring(0, index) + chr + str.substring(index + 1);
  }
  async getAwsCookieViaNetwork(data: any, array: Array<any> = []) {
    try {
      const res = await this.videoLicenseService
        .getAwsKey(data.videoUrl)
        .toPromise();

      if (res && res['data']) {
        data['cookie'] = res['data'];
        data['createdAt'] = new Date();
        return data.cookie;
      }
    } catch (e) {
      console.log(e);
    } finally {
      array.push(data);
      this.storageService.setAwsCookie(JSON.stringify(array));
    }
  }
  isEmpty(obj: any) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  async ngOnDestroy() {
    // const watchStats = this.videoStats.watchSessions[0].watchStats;
    // this.globalService.openSideNav(true);
    this.globalService.removeAnswer();
    this.globalService.removeQuestion();
    // if (
    //   this.videoStats &&
    //   this.videoStats.watchSessions &&
    //   this.videoStats.watchSessions[0].watchStats.length > 0
    // ) {
    // if (watchStats.length > 1) {
    //   for (let i = 0; i < watchStats.length - 1; i++) {
    //     if (
    //       watchStats[i].startTime === watchStats[i + 1].startTime ||
    //       watchStats[i].startTime === watchStats[i].endTime
    //     ) {
    //       this.videoStats.watchSessions[0].watchStats.splice(i, 1);
    //     }
    //   }
    // }
    // await this.sendStats();
    // }
  }
  onClose() {
    this.dialogRef.close();
  }
}
