<section class="h-full py-5 relative">
  <div class="text-center text-lg sm:text-xl">Fill required details</div>
  <img
    [src]="filterCloseIcon"
    alt="close-icon"
    (click)="closeLeadForm()"
    class="mr-1 cursor-pointer absolute right-3 top-3"
  />
  <iframe
    #leadIframe
    (load)="checkLoaded($event, leadIframe)"
    [src]="url | safe : 'resourceUrl'"
    allow="clipboard-read; clipboard-write; accelerometer; autoplay;fullscreen; encrypted-media; gyroscope; picture-in picture "
    class="responsive-iframe"
    id="leadIframe"
  ></iframe>
</section>
