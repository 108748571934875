import { Instalment } from '../batch/batch.modal';
import { discount } from './../../../core/assets.location';
export interface PackageListAPIResponse {
  success: boolean;
  data: PackageList[];
}

export class PackageList {
  _id: string;
  isAvailableFromPoints: boolean;
  type: string;
  discount: number;
  isCombo: boolean;
  comboItems: any[];
  isPreOrderEnabled: boolean;
  typeWalletPointUse: string;
  minWalletPoint: number;
  maxWalletPoint: number;
  displayOrder: number;
  durations: any[];
  renewals: any[];
  teachers: any[];
  status: string;
  isCashbackEnable: boolean;
  cashbackType: string;
  cashbackValue: number;
  isAddonEnabled: boolean;
  addons: any[];
  fbtExtraDiscount: number;
  addOnTag: string;
  name: string;
  packageTag: string;
  comboTag: string;
  price: number;
  organizationId: string;
  expiryDuration: number;
  createdBy: string;
  slug: string;
  batch: string;
  khazanaPackageId: string;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
  preOrderDiscount: number;
  preOrderExpiryDuration: number;
  preOrderPrice: number;
  previewVideoType: string;
  secondaryImageId?: Image;
  items: Item[];
  isBest?: boolean;
  bookId: string;
  testCategoryModeId: string;
  imageId?: Image;
  packageType: string;

  constructor(data: any = {}) {
    this._id = data._id || '';
    this.isAvailableFromPoints = data.isAvailableFromPoints || false;
    this.type = data.type || '';
    this.discount = data.discount || 0;
    this.isCombo = data.isCombo || false;
    this.comboItems = data.comboItems || [];
    this.isPreOrderEnabled = data.isPreOrderEnabled || false;
    this.typeWalletPointUse = data.typeWalletPointUse || '';
    this.minWalletPoint = data.minWalletPoint || 0;
    this.maxWalletPoint = data.maxWalletPoint || 0;
    this.displayOrder = data.displayOrder || 0;
    this.durations = data.durations || [];
    this.renewals = data.renewals || [];
    this.teachers = data.teachers || [];
    this.status = data.status || '';
    this.isCashbackEnable = data.isCashbackEnable || false;
    this.cashbackType = data.cashbackType || '';
    this.cashbackValue = data.cashbackValue || '';
    this.isAddonEnabled = data.isAddonEnabled || false;
    this.addons = data.addons || [];
    this.fbtExtraDiscount = data.fbtExtraDiscount || 0;
    this.addOnTag = data.addOnTag || '';
    this.name = data.name || '';
    this.packageTag = data.packageTag || '';
    this.comboTag = data.comboTag || '';
    this.organizationId = data.organizationId || '';
    this.price = data.price || 0;
    this.expiryDuration = data.expiryDuration || 0;
    this.createdBy = data.createdBy || '';
    this.slug = data.slug || '';
    this.batch = data.batch || '';
    this.khazanaPackageId = data.khazanaPackageId || '';
    this.createdAt = data.createdAt || '';
    this.updatedAt = data.updatedAt || '';
    this.preOrderDiscount = data.preOrderDiscount || 0;
    this.preOrderExpiryDuration = data.preOrderExpiryDuration || 0;
    this.preOrderPrice = data.preOrderPrice || 0;
    this.previewVideoType = data.previewVideoType || '';
    this.bookId = data.bookId || '';
    this.testCategoryModeId = data.testCategoryModeId || '';
    this.isBest = data.isBest || false;
    this.secondaryImageId = new Image(data.secondaryImageId) || new Image({});
    this.imageId = new Image(data.secondaryImageId) || new Image({});
    this.packageType = data?.packageType || '';

    if (data.items) {
      this.items = data.items.map((item: any) => new Item(item));
    } else {
      this.items = [];
    }
  }
}

export class PreOrder {
  status: boolean;
  constructor(data: any = {}) {
    this.status = data.status || false;
  }
}

export class Image {
  _id: string;
  baseUrl: string;
  name: string;
  key: string;

  constructor(data: any = {}) {
    this._id = data._id || '';
    this.baseUrl = data.url || '';
    this.name = data.name || '';
    this.key = data.name || '';
  }
}

export class ItemDetails {
  isPurchased: boolean;
  isDeleted: boolean;
  _id: string;
  id: string;
  createdAt?: string;
  updatedAt?: string;
  weight?: number;
  status: string;
  type: string;
  relatedProducts: any[];
  ratingCount?: number;
  quantity?: number;
  price?: number;
  preOrder: PreOrder;
  name: string;
  description: string;
  language: string;
  bundleDiscount?: number;
  discount?: number;
  deliveryCharge?: number;
  image: Image;
  saarthiPlans: SaarthiPlan[];
  defaultSaarthiPlan: string;

  constructor(data: any = {}) {
    this.isPurchased = data.isPurchased || false;
    this.isDeleted = data.isDeleted || false;
    this._id = data._id || '';
    this.id = data.id || '';
    this.createdAt = data.createdAt || '';
    this.updatedAt = data.updatedAt || '';
    this.weight = data.weight || 0;
    this.status = data.status || '';
    this.type = data.type || '';
    this.relatedProducts = data.relatedProducts || [];
    this.ratingCount = data.ratingCount || 0;
    this.quantity = data.quantity || 0;
    this.price = data.price || 0;
    this.preOrder = new PreOrder(data.preOrder) || new PreOrder({});
    this.name = data.name || '';
    this.description = data.description || '';
    this.language = data.language || '';
    this.bundleDiscount = data.bundleDiscount || 0;
    this.discount = data.discount || 0;
    this.deliveryCharge = data.deliveryCharge || 0;
    this.image = new Image(data.image) || new Image({});
    this.defaultSaarthiPlan = data.defaultSaarthiPlan || '';

    if (data.saarthiPlans) {
      this.saarthiPlans = data.items.map((item: any) => new SaarthiPlan(item));
    } else {
      this.saarthiPlans = [];
    }
  }
}

export class Item {
  type: string;
  itemDetails: ItemDetails;
  typeId: string;

  constructor(data: any = {}) {
    this.type = data.type || '';
    this.itemDetails = new ItemDetails(data.itemDetails) || new ItemDetails({});
    this.typeId = data.typeId || '';
  }
}

export class SaarthiPlan {
  price: number;
  discount: number;
  duration: number;
  _id: string;
  saarthiId: string;
  isAvailableFromPoints: boolean;
  maxWalletPoints: number;
  durationType: string;
  isSelected: boolean;
  expire_at: Date;

  constructor(data: any) {
    data = data || {};
    this._id = data._id || '';
    this.saarthiId = data.saarthiId || '';
    this.price = data.price || 0;
    this.discount = data.discount || 0;
    this.duration = data.duration || 0;
    this.isAvailableFromPoints = data.isAvailableFromPoints || false;
    this.maxWalletPoints = data.maxWalletPoints || 0;
    this.durationType = data.durationType || '';
    this.isSelected = false;
    this.expire_at = data.expire_at || '';
  }
}

export class Coupon {
  type: string;
  offerType: string;
  currentUsage: number;
  status: string;
  packageIds: any[];
  _id: string;
  name: string;
  couponCode: string;
  startDate: string;
  endDate: string;
  usagePerCandidate: number;
  minPurchaseAmount: number;
  maxDiscountAmount: number;
  organization: string;
  couponAmount: number;
  maxUsage: number;
  createdAt: string;
  updatedAt: string;

  constructor(data: any = {}) {
    this.type = data.type || '';
    this.offerType = data.offerType || '';
    this.currentUsage = data.currentUsage || 0;
    this.status = data.status || '';
    this.packageIds = data.packageIds || [];
    this._id = data._id || '';
    this.name = data.name || '';
    this.couponCode = data.couponCode || '';
    this.startDate = data.startDate || '';
    this.endDate = data.endDate || '';
    this.usagePerCandidate = data.usagePerCandidate || 0;
    this.minPurchaseAmount = data.minPurchaseAmount || 0;
    this.maxDiscountAmount = data.maxDiscountAmount || 0;
    this.couponAmount = data.couponAmount || 0;
    this.maxUsage = data.maxUsage || 0;
    this.organization = data.organization || '';
    this.createdAt = data.createdAt || '';
    this.updatedAt = data.updatedAt || '';
  }
}

export class SaarthiPlanList {
  _id: string;
  saarthiId: string;
  price: number;
  discount: number;
  duration: number;
  isSelected: boolean;
  durationType: string;
  expire_at: Date;
  constructor(data: any) {
    data = data || {};
    this._id = data._id || '';
    this.saarthiId = data.saarthiId || '';
    this.price = data.price || 0;
    this.discount = data.discount || 0;
    this.duration = data.duration || 0;
    this.isSelected = data.isSelected || false;
    this.durationType = data.durationType || '';
    this.expire_at = data.expire_at || '';
  }
}

export class Label {
  name: string;
  constructor(data: any) {
    data = data || {};
    this.name = data.name || '';
  }
}

export class Meta {
  type: string;
  text: string;
  constructor(data: any) {
    data = data || {};
    this.type = data.type || '';
    this.text = data.text || '';
  }
}

export class TestCategoryId {
  _id: string;
  name: string;
  description: string;
  label: Label[];
  keywords: any[];
  image: Image;
  programId: string;
  clientId: string;
  displayOrder: number;
  price: number;
  status: string;
  slug: string;
  startTime: Date;
  endTime: Date;
  meta: Meta[];
  createdAt: Date;
  updatedAt: Date;

  constructor(data: any) {
    data = data || {};
    this._id = data._id || '';
    this.updatedAt = data.updatedAt || '';
    this.createdAt = data.createdAt || '';
    this.description = data.description || '';
    this.displayOrder = data.displayOrder || 0;
    this.status = data.status || '';
    this.name = data.name || '';
    this.price = data.price || 0;
    this.startTime = data.startTime || '';
    this.endTime = data.endTime || '';
    this.meta = data.meta || [];
    this.keywords = data.keywords || [];
    this.label = data.label || [];
    this.image = data.image || {};
    this.programId = data.programId || '';
    this.clientId = data.clientId || '';
    this.slug = data.slug || '';
  }
}

export class Meta2 {
  type: string;
  text: string;
  constructor(data: any) {
    data = data || {};
    this.type = data.type || '';
    this.text = data.text || '';
  }
}

export class Label1 {
  imageId: string;
  videoType: string;
  platforms: string[];

  constructor(data: any) {
    data = data || {};
    this.imageId = data.imageId || '';
    this.videoType = data.videoType || '';
    this.platforms = data.platforms || [];
  }
}

export class UrlEmbeding {
  status: boolean;
  constructor(data: any) {
    data = data || {};
    this.status = data.status || false;
  }
}

export class ImageId {
  baseUrl: string;
  _id: string;
  name: string;
  key: string;
  type: string;
  __v: number;
  createdAt: Date;
  constructor(data: any) {
    data = data || {};
    this.__v = data.__v || '';
    this._id = data._id || '';
    this.type = data.type || '';
    this.name = data.name || '';
    this.baseUrl = data.baseUrl || '';
    this.createdAt = data.createdAt || '';
  }
}

export class Package {
  _id: string;
  isAvailableFromPoints: boolean;
  type: string;
  discount: number;
  status: string;
  name: string;
  program: string;
  price: number;
  organizationId: string;
  programId: string;
  expiryDuration: number;
  createdBy: string;
  createdAt: Date;
  updatedAt: Date;
  __v: number;
  durations: string[];
  comboItems: string[];
  displayOrder: number;
  isCombo: boolean;
  isPreOrderEnabled: boolean;
  maxWalletPoint: number;
  minWalletPoint: number;
  preOrderDiscount: number;
  preOrderExpiryDuration: number;
  preOrderPrice: number;
  previewVideoType: string;
  renewals: any[];
  typeWalletPointUse: string;
  cashbackType: string;
  cashbackValue: number;
  isCashbackEnable: boolean;
  teachers: string[];
  imageId: ImageId;
  parentPackage: string;
  description: string;

  constructor(data: any) {
    data = data || {};
    this.__v = data.__v || '';
    this._id = data._id || '';
    this.type = data.type || '';
    this.isAvailableFromPoints = data.isAvailableFromPoints || false;
    this.discount = data.discount || 0;
    this.isCombo = data.isCombo || false;
    this.isPreOrderEnabled = data.isPreOrderEnabled || false;
    this.typeWalletPointUse = data.typeWalletPointUse || '';
    this.minWalletPoint = data.minWalletPoint || 0;
    this.maxWalletPoint = data.maxWalletPoint || 0;
    this.displayOrder = data.displayOrder || 0;
    this.durations = data.durations || [];
    this.comboItems = data.comboItems || [];
    this.renewals = data.renewals || [];
    this.teachers = data.teachers || [];
    this.status = data.status || '';
    this.isCashbackEnable = data.isCashbackEnable || false;
    this.cashbackType = data.cashbackType || '';
    this.cashbackValue = data.cashbackValue || 0;
    this.name = data.name || '';
    this.price = data.price || 0;
    this.organizationId = data.organizationId || '';
    this.expiryDuration = data.expiryDuration || 0;
    this.createdBy = data.createdBy || '';
    this.updatedAt = data.updatedAt || '';
    this.preOrderDiscount = data.preOrderDiscount || 0;
    this.preOrderExpiryDuration = data.preOrderExpiryDuration || 0;
    this.preOrderPrice = data.preOrderPrice || 0;
    this.previewVideoType = data.previewVideoType || '';
    this.imageId = new ImageId(data.imageId) || {};
    this.parentPackage = data.parentPackage || '';
    this.description = data.description || '';
  }
}

export class ProductDetails {
  _id: string;
  status: string;
  createdBy: string;
  type: string;
  testCategoryId: TestCategoryId;
  price: number;
  discount: number;
  videoType: string;
  startTime: Date;
  endTime: Date;
  duration: number;
  meta: Meta2[];
  label1: Label1[];
  faqCategoryId: string;
  testimonialId: string;
  scheduleFileId: string;
  studentSlots: number;
  isAvailableFromPoints: boolean;
  maxWalletPoint: number;
  label2: any[];
  createdAt: Date;
  updatedAt: Date;
  preOrder: PreOrder;
  urlEmbeding: UrlEmbeding;
  deliveryCharge?: number;
  tags: string[];
  class: string[];
  language: string;
  displayOrder?: number;
  isGSTApplicable?: boolean;
  categories: any[];
  keywords: any[];
  actions: any[];
  relatedProducts: any[];
  ratingCount?: number;
  isCashbackEnable?: boolean;
  cashbackType: string;
  cashbackValue?: number;
  teachers: any[];
  stopSale?: boolean;
  enableStudyMaterial?: boolean;
  label: any[];
  studyMaterials: any[];
  quantity?: number;
  name: string;
  description: string;
  organizationId: string;
  board: string;
  categoryId: string;
  packages: Package[];
  image: Image;
  constructor(data: any) {
    data = data || {};
    this._id = data._id || '';
    this.updatedAt = data.updatedAt || '';
    this.createdAt = data.createdAt || '';
    this.description = data.description || '';
    this.type = data.type || '';
    this.displayOrder = data.displayOrder || 0;
    this.status = data.status || '';
    this.organizationId = data.organizationId || '';
    this.name = data.name || '';
    this.price = data.price || 0;
    this.relatedProducts = data.relatedProducts || [];
    this.ratingCount = data.ratingCount || 0;
    this.quantity = data.quantity || 0;
    this.preOrder = new PreOrder(data.preOrder) || {};
    this.language = data.language || '';
    this.discount = data.discount || 0;
    this.deliveryCharge = data.deliveryCharge || 0;
    this.testCategoryId = new TestCategoryId(data.testCategoryId) || {};
    this.videoType = data.videoType || '';
    this.startTime = data.startTime || '';
    this.endTime = data.endTime || '';
    this.duration = data.duration || '';
    this.meta = data.meta || [];
    this.label1 = data.label1 || [];
    this.faqCategoryId = data.faqCategoryId || '';
    this.testimonialId = data.testimonialId || '';
    this.scheduleFileId = data.scheduleFileId || '';
    this.studentSlots = data.studentSlots || 0;
    this.isAvailableFromPoints = data.isAvailableFromPoints || false;
    this.maxWalletPoint = data.maxWalletPoint || 0;
    this.label2 = data.label2 || [];
    this.urlEmbeding = new UrlEmbeding(data.urlEmbeding) || {};
    this.tags = data.tags || [];
    this.class = data.class || [];
    this.language = data.language || '';
    this.isGSTApplicable = data.isGSTApplicable || false;
    this.categories = data.categories || [];
    this.keywords = data.keywords || [];
    this.actions = data.actions || [];
    this.relatedProducts = data.relatedProducts || [];
    this.isCashbackEnable = data.isCashbackEnable || false;
    this.cashbackValue = data.cashbackValue || 0;
    this.teachers = data.teachers || [];
    this.stopSale = data.stopSale || false;
    this.enableStudyMaterial = data.enableStudyMaterial || false;
    this.label = data.label || [];
    this.studyMaterials = data.studyMaterials || [];
    this.quantity = data.quantity || 0;
    this.board = data.board || '';
    this.categoryId = data.categoryId || '';
    this.packages = data.packages || [];
    this.image = data.image || {};
  }
}

export class FbtAddonsList {
  _id: string;
  displayOrder: number;
  status: string;
  type: string;
  typeId: string;
  description: string;
  isBest: boolean;
  saarthiPlanList: SaarthiPlanList[];
  batchId: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  organizationId: string;
  defaultSaarthiPlanId: string;
  productDetails: ProductDetails;
  addOnTag: string;
  fbtCategory?: string;
  thumbnailLink: string;
  accessLinkUrl?: string;
  accessLinkType?: string;
  title?: string;
  paidAmount?: number;
  buttonTitle?: string;
  redirection?: string;
  urlType?: string;
  batchType: string;
  pwStoreOrderId: string;
  isCombo?: string;
  comboData: ComboDataType;
  meta: OrderDetailsMeta;
  constructor(data: any) {
    data = data || {};
    this._id = data._id || '';
    this.updatedAt = data.updatedAt || '';
    this.createdAt = data.createdAt || '';
    this.description = data.description || '';
    this.__v = data.__v || '';
    this.type = data.type || '';
    this.displayOrder = data.displayOrder || 0;
    this.status = data.status || '';
    this.organizationId = data.organizationId || '';
    this.typeId = data.typeId || '';
    this.isBest = data.addOnTag?.length > 0 || false;
    this.addOnTag = data.addOnTag || '';
    this.batchId = data.batchId || '';
    this.productDetails = new ProductDetails(data.productDetails) || {};
    this.defaultSaarthiPlanId = data.defaultSaarthiPlanId || '';
    this.fbtCategory = data.fbtCategory || '';
    this.thumbnailLink = data.thumbnailLink || '';
    this.accessLinkUrl = data.accessLinkUrl || '';
    this.accessLinkType = data.accessLinkType || '';
    this.title = data.title || '';
    this.paidAmount = data.paidAmount || '';
    this.buttonTitle = data.buttonTitle || '';
    this.redirection = data.redirection || '';
    this.batchType = data.batchType || '';
    this.urlType = data.urlType || '';
    this.isCombo = data.isCombo || false;
    this.pwStoreOrderId = data.pwStoreOrderId || '';
    this.comboData = new ComboDataType(data.comboData) || new ComboDataType({});
    this.meta =
      new OrderDetailsMeta(data.meta || {}) || new OrderDetailsMeta({});

    if (data && data.saarthiPlanList) {
      this.saarthiPlanList = data.saarthiPlanList.map(
        (item: any) => new SaarthiPlanList(item)
      );
    } else {
      this.saarthiPlanList = [];
    }
  }
}

export class OrderDetailsMeta {
  hasAdjustedBatches: boolean;
  adjustedBatchNames: string;
  batchId: string;

  constructor(data: any) {
    data = data || {};
    this.hasAdjustedBatches = data.hasAdjustedBatches || false;
    this.batchId = data.batchId || '';
    this.adjustedBatchNames = data.adjustedBatchNames || '';
  }
}

export class ComboDataType {
  name: string;
  price: number;
  constructor(data: any) {
    data = data || {};
    this.name = data.name || '';
    this.price = data.price || 0;
  }
}

export interface FbtAddOnsListResponce {
  success: boolean;
  data: FbtAddonsList[];
}

export interface FbtFeeResponse {
  success: boolean;
  data: FBTFee;
}

export class FBTFee {
  addonIds: any[];
  status: string;
  packageItems: any[];
  deliveryCharge: number;
  _id: string;
  packageId: string;
  userId: string;
  price: number;
  discount: number;
  saarthiPlanId: string;
  createdAt: string;
  updatedAt: string;

  constructor(data: any = {}) {
    this.addonIds = data.addonIds || [];
    this.packageItems = data.packageItems || [];
    this.status = data.status || '';
    this._id = data._id || '';
    this.packageId = data.packageId || '';
    this.userId = data.userId || '';
    this.deliveryCharge = data.deliveryCharge || 0;
    this.price = data.price || 0;
    this.discount = data.discount || 0;
    this.saarthiPlanId = data.saarthiPlanId || '';
    this.updatedAt = data.updatedAt || '';
    this.createdAt = data.createdAt || '';
  }
}

export interface PaymentDetail {
  price: number;
  discount: number;
  deliveryCharge: number;
  totalAmount: number;
}

export interface OrderResponseType {
  status: string;
  message: string;
  code: number;
  data: AfterPaymentOrder[];
}

export class AfterPaymentOrder {
  sectionId: number;
  display: boolean;
  title?: string;
  description?: string;
  status?: string;
  orderId?: string;
  downloadInvoiceLink?: boolean;
  shareInvoice?: boolean;
  items?: FbtAddonsList[];
  section_id?: string;
  heading?: string;
  desc?: string;
  supportType?: string;
  url?: string;
  dataType?: string;
  thumbnail?: string;
  faqId?: string;
  videoType?: string;
  thumbnailImageLink?: string;
  urlType?: string;
  erpFormLink: string;
  invoiceWhatsappMsg: string;
  instalments: Array<Instalment> | null;

  constructor(data: any) {
    data = data || {};
    this.sectionId = data.sectionId || '';
    this.status = data?.status?.toLowerCase() || '';
    this.display = data.display || false;
    this.title = data.title || '';
    this.description = data.description || '';
    this.orderId = data.orderId || '';
    this.downloadInvoiceLink = data.downloadInvoiceLink || false;
    this.shareInvoice = data.shareInvoice || false;
    this.section_id = data.section_id || '';
    this.heading = data.heading || '';
    this.desc = data.desc || '';
    this.supportType = data.supportType || '';
    this.url = data.url || '';
    this.dataType = data.dataType || '';
    this.thumbnail = data.thumbnail || '';
    this.faqId = data.faqId || '';
    this.videoType = data.videoType || '';
    this.thumbnailImageLink = data.thumbnailImageLink || '';
    this.urlType = data.urlType || '';
    this.invoiceWhatsappMsg = data.invoiceWhatsappMsg || '';
    this.erpFormLink = data.erpFormLink || '';
    if (data.instalments) {
      this.instalments = data.instalments?.map(
        (res: Instalment) => new Instalment(res)
      );
    } else {
      this.instalments = null;
    }
    if (data?.items?.length) {
      this.items = data?.items?.map((res: any) => new FbtAddonsList(res));
    } else {
      this.items = [];
    }
  }
}

export enum TRANSACTION_TYPE {
  SUCCESS = 'success',
  FAILED = 'failed',
  PENDING = 'pending',
}

export enum AFTER_PAYMENT_COMING_SOURCE {
  SAATHI = 'saarthi',
  BATCH_SAARTHI = 'batch_saarthi',
  TEST_SERIES = 'test_series',
  KHAZANA = 'khazana',
  SAMSUNG_PURCHASE = 'samsung_purchase',
  K8 = 'k8',
  TEST_CATEGORY_MODE = 'TEST_CATEGORY_MODE',
}
export enum SAARTHI_TILL_DATE {
  TILL_DATE = 'Till_Date',
}

export enum PART_PAYMENT {
  ENABLED = 'ENABLED',
}
