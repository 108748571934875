import { Injectable } from '@angular/core';
import { IMqttServiceOptions, MqttService } from 'ngx-mqtt';
import { BehaviorSubject, Subscription } from 'rxjs';
import { GlobalService } from '../global/global.service';
import { ShowErrorService } from '../showError/show-error.service';
import { environment } from 'src/environments/environment';

export const MQTT_OPTIONS: IMqttServiceOptions = {
  url: environment.MQTT_URL,
  clean: true,
  connectTimeout: 3000, //connection timeout in 3sec
  reconnectPeriod: 3000, // will reconnect in 3sec
  clientId: '',
  keepalive: 3000,
};

@Injectable({
  providedIn: 'root',
})
export class PPMQTTService {
  client: MqttService;

  onConnectedSub: Subscription;
  onErrorSub: Subscription;
  onMessageSub: Subscription;
  token: string = '';
  isConnected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  connection: IMqttServiceOptions = MQTT_OPTIONS;

  constructor(
    private _mqttService: MqttService,
    private globalService: GlobalService,
    private showErrorService: ShowErrorService
  ) {
    this.token = this.globalService.getAccessToken().getValue() || '';
    const randomId: string = this.globalService.getRandomId() || '';
    this.client = this._mqttService;
    if (this.token.length > 0 && randomId.length > 0) {
      this.connection = {
        ...this.connection,
        username: this.token,
        clientId: `mqtt-web-client-${randomId}`,
      };
    }
  }

  connect(): boolean {
    let isConnected: boolean = false;

    try {
      if (this.token.length > 0) {
        this.client?.connect(this.connection);
        isConnected = true;
      }
    } catch (e) {
      console.log('ERROR CONNECTING TO MQTT SERVICE, ', e);
      isConnected = false;
    }

    this.client?.onError.subscribe((e) => {
      this.showErrorService.showError(e);
    });
    return isConnected;
  }

  subscribeTopic(topic: string) {
    return this.client.observe(topic, {
      qos: 2,
      rap: true,
    });
  }

  disconnect() {
    return this.client.disconnect();
  }
}

export enum OPERATION {
  START = 'start',
  STOP = 'stop',
  RESULT = 'result',
  STOP_EXPIRY = 'stop_expiry',
}
