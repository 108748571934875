import { Injectable } from '@angular/core';
import { StorageRefService } from './storage-ref.service';
import { BehaviorSubject } from 'rxjs';
import {
  AllPurchasedBatches,
  BatchDetailModel,
} from '../../../pages/batch/batch-overview/batch-overview.model';
import { Question } from '../../../pages/tests/tests.model';
import { GlobalObjectService } from '../global-object/global-object.service';
import { AFTER_PAYMENT_COMING_SOURCE } from '../payment/payment.type';
import { BatchPlus } from '../batch/batch.modal';
import { keysToRetainInLocalStorage } from '../after-login/constants';
export enum CookieKeys {
  'TOKEN_CONTEXT' = 'TOKEN_CONTEXT',
}

const Config = {
  [CookieKeys.TOKEN_CONTEXT]: {
    expiryInSeconds: 60 * 60 * 24 * 7,
    domainsToShare: 'pw.live',
  },
};
@Injectable({
  providedIn: 'root',
})
export class StorageService {
  BOOK_DATA: string = 'BOOK_DATA';
  DEVICE_ID = 'deviceId';
  BATCH_DATA: string = 'BATCH_DATA';
  TEACHER_DATA: string = 'TEACHER_DATA';
  TOKEN: string = 'TOKEN';
  AWS_COOKIES_ARRAY = 'awsCookieArray';
  DEMO_VIDEOS = 'demoVideos';
  JOB_ALERT = 'job_alert';
  DOUBT_NOTIFICATIONS = 'doubtNotif';
  DOUBT_CONNECT_ROOM_DETAILS = 'DOUBT_CONNECT_ROOM_DETAILS';
  PAYMENT_REDIRECTION_LINK = 'paymentredirectionLink';
  CURRENT_URL_BEFORE_TEST = 'beforeTestUrl';
  ACTIVE_QUESTION: string = 'ACTIVE_QUESTION';
  RECENT_EXERCISE_NAVIGATION_DATA: string = 'RECENT_EXERCISE_NAVIGATION_DATA';
  PAYMENT_DETAILS: string = 'AFFISE_PAYMENT_DETAIL';
  SAARTHI_DETAILS: string = 'SAARTHI_DETAILS';
  SAARTHI_SUBJECTS: string = 'SAARTHI_SUBJECTS';
  SAARTHI_PREFERENCES: string = 'SAARTHI_PREFERENCES';
  CONCEPT_RECENT_SEARCHES: string = 'CONCEPT_RECENT_SEARCHES';
  SAARTHI_APPLIED_COUPON = 'APPLIED_COUPON';
  REFRESH_TOKEN: string = 'REFRESH_TOKEN';
  EXPIRES_IN: string = 'expires_in';
  IFRAME_PAYMENT_REDIRECTION: string = 'IFRAME_PAYMENT_REDIRECTION';
  CAME_FROM_K8: string = 'came_from_k8';
  USER_SCHOLARSHIP_STATUS: string = 'userScholarshipStatus';
  REGISTRATION_STEP: string = 'registrationStep';
  SCHOLARSHIP_RESULT: string = 'scholarship_result';
  IS_INSTRUCTION_BACK: string = 'isInstructionBack';
  IS_MUTE_ENABLED = 'IS_MUTE_ENABLED';
  IS_SLOW_CHAT_ENABLED = 'IS_SLOW_CHAT_ENABLED';
  SLOW_TIMER = 'SLOW_TIMER';
  PREVIOUS_SELECTED_PLAN = 'previous-plan-selected';
  STUDY_TIME = 'STUDY_TIME';
  MY_DOUBT_SELECTED_BATCH = 'MY_DOUBT_SELECTED_BATCH';
  SCHEDULE_CLASS_SELECTED_BATCH = 'SCHEDULE_CLASS_SELECTED_BATCH';
  IFRAME_REDIRECTION = 'IFRAME_REDIRECTION';
  KHAZANA_TIMESTAMP = 'khazana_timestamp';
  PLAN_SELECTED_DETAILS = 'PLAN_SELECTED_DETAILS';
  TEST_ACTIVE_LANGUAGE = 'TEST_ACTIVE_LANGUAGE';
  PW_CENTER_ARRAY = 'PW_CENTER_ARRAY';
  PW_MICRO_LEARNING_ARRAY = 'PW_MICRO_LEARNING_ARRAY';
  PW_WEBINAR_ARRAY = 'PW_WEBINAR_ARRAY';
  PW_MENTORSHIP = 'PW_MENTORSHIP';
  PW_SCHOLARSHIP = 'PW_SCHOLARSHIP';
  private _localStorage: Storage;
  private _sessionStorage: Storage;
  SAARTHI_SOURCE = 'saarthi_source';
  BATCHID_SUBJECTID_TESTCATID = 'batchid_subjectid_testcatid';

  MAHAPACK_CHECK_DATA = 'mahapack_check_data';
  MY_DASHBOARD_URLS = 'my_dashboard_urls';

  CART_SELECTED_IDS = 'cart_selected_ids';
  FETCH_STATS_DATA = 'fetch_stats_data';
  RESPONSE_CODE = 'response_code';
  REQUEST_URL = 'request_url';
  SELECTED_CART_DATA = 'SELECTED_CART_DATA';
  PURCHASE_SOURCE = 'purchase_source';
  CLIENT_TYPE = 'client_type';
  AFTER_PAYMENT_COMING_SOURCE = 'AFTER_PAYMENT_COMING_SOURCE';
  IS_PART_PAYMENT_ENABLED = 'IS_PART_PAYMENT_ENABLED';
  DEFAULT_PAYMENT_MODE = 'DEFAULT_PAYMENT_MODE';
  ORDER_ID = 'ORDER_ID';
  FBT_COMING_SOURCE = 'fbt_coming_source';
  CAMPAIGN_PARAM = 'campaignParam';
  BATCH_PLAN_ID = 'BATCH_PLAN_ID';
  TEST_SERIES_FILTER = 'TEST_SERIES_FILTER';
  PREMIUM_DATA = 'PREMIUM_DATA';
  VIDEO_PLAYER_PREFERENCE = 'video_player_preference';
  COMPARISON_PLAN = 'COMPARISON_PLAN';
  ROUTE_TO_NEW_FBT = 'ROUTE_TO_NEW_FBT';
  LAST_VISITED_URL = 'last_visited_url';
  SHOW_POP_UP_AFTER_EXPIRY = 'SHOW_POP_UP_AFTER_EXPIRY';

  private _userToken$ = new BehaviorSubject<string>('');
  public userToken = this._userToken$.asObservable();
  private _bookData$ = new BehaviorSubject<string>('');
  public bookData = this._bookData$.asObservable();
  private _batchData$ = new BehaviorSubject<string>('');
  public batchData = this._batchData$.asObservable();
  private _teacherData$ = new BehaviorSubject<string>('');
  public teacherData = this._teacherData$.asObservable();
  private _activeQuestionData$ = new BehaviorSubject<string>('');
  public activeQuestionData = this._activeQuestionData$.asObservable();
  public isCampaignDataAvailable$ = new BehaviorSubject<boolean>(false);

  private _user$ = new BehaviorSubject<string>('');
  public user = this._user$.asObservable();
  MAINTENANCE = 'MAINTENANCE_DATA';

  constructor(
    private storageRefService: StorageRefService,
    private globalObjectService: GlobalObjectService
  ) {
    this._localStorage = this.storageRefService.localStorage;
    this._sessionStorage = this.globalObjectService.windowObj.sessionStorage;
  }

  setAppliedCoupon(code: string) {
    this.setValue(this.SAARTHI_APPLIED_COUPON, code, 'string');
  }

  getAppliedCoupon() {
    return this.getValue(this.SAARTHI_APPLIED_COUPON, 'string');
  }

  removeAppliedCoupon() {
    return this.removeValue(this.SAARTHI_APPLIED_COUPON);
  }

  setDoubtConnectRoomDetails(data: any) {
    this.setValue(this.DOUBT_CONNECT_ROOM_DETAILS, data, 'string');
  }

  setSlowTimer(value: any) {
    return localStorage.setItem(this.SLOW_TIMER, JSON.stringify(value));
  }

  getSlowTimer() {
    return localStorage.getItem(this.SLOW_TIMER);
  }
  getSelectedPlan() {
    return localStorage.getItem(this.PREVIOUS_SELECTED_PLAN);
  }
  setSelectedPlan(value: any) {
    return localStorage.setItem(this.PREVIOUS_SELECTED_PLAN, value);
  }
  removeSelectedPlan() {
    return localStorage.removeItem(this.PREVIOUS_SELECTED_PLAN);
  }
  removeSlowTimer() {
    return localStorage.removeItem(this.SLOW_TIMER);
  }

  setMute(value: any) {
    return localStorage.setItem(this.IS_MUTE_ENABLED, JSON.stringify(value));
  }

  getSaarthiPreferences() {
    return localStorage.getItem(this.SAARTHI_PREFERENCES);
  }

  getMute() {
    return localStorage.getItem(this.IS_MUTE_ENABLED);
  }

  removeMute() {
    return localStorage.removeItem(this.IS_MUTE_ENABLED);
  }

  setCartData(data: any) {
    return this.setValue(this.SELECTED_CART_DATA, data, 'json');
  }

  getCartData() {
    return this.getValue(this.SELECTED_CART_DATA, 'json');
  }

  removeCartData() {
    return this.removeValue(this.SELECTED_CART_DATA);
  }

  setSlowChat(value: any) {
    return localStorage.setItem(
      this.IS_SLOW_CHAT_ENABLED,
      JSON.stringify(value)
    );
  }
  setRefreshToken(refreshToken: string) {
    this._localStorage.setItem(this.REFRESH_TOKEN, refreshToken);
  }

  getRefreshToken() {
    return this._localStorage.getItem(this.REFRESH_TOKEN);
  }

  setExpiresIn(time: any) {
    this._localStorage.setItem(this.EXPIRES_IN, time);
  }

  getExpiresIn() {
    return this._localStorage.getItem(this.EXPIRES_IN);
  }

  removeExpiresIn() {
    return this._localStorage.removeItem(this.EXPIRES_IN);
  }

  getSlowChat() {
    return localStorage.getItem(this.IS_SLOW_CHAT_ENABLED);
  }

  removeSlowChat() {
    return localStorage.removeItem(this.IS_SLOW_CHAT_ENABLED);
  }

  getPaymentRedirectionUrl() {
    return this._localStorage.getItem(this.PAYMENT_REDIRECTION_LINK) || '';
  }

  setPaymentRedirectionLink(url: string) {
    return this._localStorage.setItem(this.PAYMENT_REDIRECTION_LINK, url);
  }

  removePaymentRedirectionLink() {
    this._localStorage.removeItem(this.PAYMENT_REDIRECTION_LINK);
  }
  setIframePaymentRedirectionLink(value: any) {
    this._localStorage.setItem(
      this.IFRAME_PAYMENT_REDIRECTION,
      JSON.stringify(value)
    );
    this.setAfterPaymentComingSource(AFTER_PAYMENT_COMING_SOURCE.TEST_SERIES);
  }

  removeIframePaymentRedirectionLink() {
    return this._localStorage.removeItem(this.IFRAME_PAYMENT_REDIRECTION);
  }

  getIframePaymentRedirectionLink() {
    return (
      this._localStorage.getItem(this.IFRAME_PAYMENT_REDIRECTION) ||
      JSON.stringify({})
    );
  }

  setIframeRedirection(data: any) {
    return this.setValue(this.IFRAME_REDIRECTION, data, 'json');
  }

  getIframeRedirection() {
    return this.getValue(this.IFRAME_REDIRECTION, 'json');
  }

  removeIframeRedirection() {
    return this.removeValue(this.IFRAME_REDIRECTION);
  }

  setPaymentDetails(data: any) {
    return this.setValue(this.PAYMENT_DETAILS, data, 'json');
  }

  getPaymentDetails() {
    return this.getValue(this.PAYMENT_DETAILS, 'json');
  }

  removePaymentDetails() {
    return this.removeValue(this.PAYMENT_DETAILS);
  }

  getDoubtConnectRoomDetails() {
    return this.getValue(this.DOUBT_CONNECT_ROOM_DETAILS, 'json');
  }

  clearAllLocalStorage() {
    const retainedData: { [key: string]: string } = {};

    keysToRetainInLocalStorage.forEach((key) => {
      const value = this._localStorage.getItem(key);
      if (value !== null) {
        retainedData[key] = value;
      }
    });

    this._localStorage.clear();

    Object.keys(retainedData).forEach((key) => {
      this._localStorage.setItem(key, retainedData[key]);
    });
  }

  getAwsCookie() {
    return this._localStorage.getItem(this.AWS_COOKIES_ARRAY);
  }

  setAwsCookie(data: string) {
    return this._localStorage.setItem(this.AWS_COOKIES_ARRAY, data);
  }

  setUserToken(token: string) {
    this._localStorage.setItem(this.TOKEN, token);
    this._userToken$.next(token);
  }
  setAppVersion(version: string) {
    this._localStorage.setItem('APP_VERSION', version);
  }

  getAppVersion() {
    return this._localStorage.getItem('APP_VERSION');
  }

  setTimerData(data: string) {
    return this._localStorage.setItem(this.MAINTENANCE, data);
  }

  getTimerData() {
    return this._localStorage.getItem(this.MAINTENANCE);
  }

  removeTimerData() {
    return this._localStorage.removeItem(this.MAINTENANCE);
  }

  getUserToken() {
    return this._localStorage.getItem(this.TOKEN);
  }

  removeUserToken() {
    this._localStorage.removeItem(this.TOKEN);
    this._userToken$.next('');
  }

  setValue(key: string, data: any, type: 'string' | 'json') {
    if (type === 'json') {
      this._localStorage.setItem(key, JSON.stringify(data));
    } else {
      this._localStorage.setItem(key, data);
    }
  }

  getValue(key: string, type: 'string' | 'json') {
    if (type === 'json') {
      return JSON.parse(this._localStorage.getItem(key)!);
    }
    return this._localStorage.getItem(key);
  }

  setDeviceId(deviceId: any) {
    this._localStorage.setItem(this.DEVICE_ID, deviceId);
  }

  getDeviceId() {
    return this._localStorage.getItem(this.DEVICE_ID);
  }

  removeDeviceId() {
    this._localStorage.removeItem(this.DEVICE_ID);
  }

  setDemoVideos(demoVideos: any) {
    return this._localStorage.setItem(this.DEMO_VIDEOS, demoVideos);
  }

  getDemoVideos() {
    return this._localStorage.getItem(this.DEMO_VIDEOS);
  }

  removeValue(key: string) {
    this._localStorage.removeItem(key);
  }

  setUser(user: string) {
    this._localStorage.setItem('user', user);
    this._user$.next(user);
  }

  getCookieValue(key: CookieKeys) {
    let name = key + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        const val = c.substring(name.length, c.length);
        if (val) {
          return JSON.parse(val);
        }
        return '';
      }
    }
    return '';
  }
  setCookieValue(key: CookieKeys, data: any = '') {
    const _config = Config[key];
    if (_config) {
      document.cookie = `${key}=${JSON.stringify(data)}; expires=${new Date(
        new Date().getTime() + _config.expiryInSeconds * 1000
      ).toUTCString()}; domain:${_config.domainsToShare}`;
    }
  }

  getUser() {
    return this._localStorage.getItem('user');
  }

  getUserAsBehaviourSubject() {
    return this._user$;
  }

  removeUser() {
    this._localStorage.removeItem('user');
    this._user$.next('');
  }

  // Book Data
  setBookData(value: any) {
    this._localStorage.setItem(this.BOOK_DATA, JSON.stringify(value));
    this._bookData$.next(value);
  }

  getBookData() {
    const data = JSON.parse(<string>this._localStorage.getItem(this.BOOK_DATA));
    this._bookData$.next(data);
    return this.bookData;
  }

  removeBookData() {
    this._localStorage.removeItem(this.BOOK_DATA);
    this._bookData$.next('');
  }

  removeTeacherData() {
    this._localStorage.removeItem(this.TEACHER_DATA);
    this._teacherData$.next('');
  }

  getBatchData() {
    return this.getValue(this.BATCH_DATA, 'json');
  }

  setBatchData(batchData: BatchDetailModel) {
    this.setValue(this.BATCH_DATA, batchData, 'json');
  }

  removeBatchData() {
    this._localStorage.removeItem(this.BATCH_DATA);
    this._batchData$.next('');
  }
  setActiveSubjectTab(data: any) {
    return this._localStorage.setItem('activeTab', JSON.stringify(data));
  }

  getActiveSubjectTab() {
    return this._localStorage.getItem('activeTab');
  }

  removeActiveSubjectTab() {
    return this._localStorage.removeItem('activeTab');
  }

  setActiveQuestionData(data: Question) {
    this._localStorage.setItem(this.ACTIVE_QUESTION, JSON.stringify(data));
  }

  getActiveQuestionData() {
    return this.getValue(this.ACTIVE_QUESTION, 'json');
  }

  removeActiveQuestionData() {
    this._localStorage.removeItem(this.ACTIVE_QUESTION);
    this._activeQuestionData$.next('');
  }

  setDoubtNotifications(data: any) {
    return this._localStorage.setItem(this.DOUBT_NOTIFICATIONS, data);
  }

  getDoubtNotifications() {
    return this._localStorage.getItem(this.DOUBT_NOTIFICATIONS);
  }

  setCurrentUrlBeforeTest(data: string) {
    return this._localStorage.setItem(this.CURRENT_URL_BEFORE_TEST, data);
  }

  getCurrentUrlBeforeTest() {
    return this._localStorage.getItem(this.CURRENT_URL_BEFORE_TEST);
  }

  removeCurrentUrlBeforeTest() {
    return this._localStorage.removeItem(this.CURRENT_URL_BEFORE_TEST);
  }

  setRecentNavigationData(data: any) {
    return this._localStorage.setItem(
      this.RECENT_EXERCISE_NAVIGATION_DATA,
      data
    );
  }
  getRecentNavigationData() {
    return this._localStorage.getItem(this.RECENT_EXERCISE_NAVIGATION_DATA);
  }
  removeRecentNavigationData() {
    return this._localStorage.removeItem(this.RECENT_EXERCISE_NAVIGATION_DATA);
  }

  getSaarthiDetails() {
    return this.getValue(this.SAARTHI_DETAILS, 'json');
  }
  setSaarthiDetails(values: any) {
    return this._localStorage.setItem(
      this.SAARTHI_DETAILS,
      JSON.stringify(values)
    );
  }
  setSaarthiSubjects(values: any) {
    return this._localStorage.setItem(
      this.SAARTHI_SUBJECTS,
      JSON.stringify(values)
    );
  }
  getSaarthiSubjects() {
    return this._localStorage.getItem(this.SAARTHI_SUBJECTS);
  }
  removeSubjects() {
    return this._localStorage.removeItem(this.SAARTHI_SUBJECTS);
  }

  addRecentSearch(recent_search_data: any) {
    return this._localStorage.setItem(
      this.CONCEPT_RECENT_SEARCHES,
      JSON.stringify(recent_search_data)
    );
  }
  getRecentSearch() {
    return this._localStorage.getItem(this.CONCEPT_RECENT_SEARCHES);
  }

  setKhazanaTimeStamp(data: any) {
    this._localStorage.setItem(this.KHAZANA_TIMESTAMP, JSON.stringify(data));
  }

  getKhazanaTimeStamp() {
    return JSON.parse(
      this._localStorage.getItem(this.KHAZANA_TIMESTAMP) || '{}'
    );
  }

  removeKhazanaTimeStamp() {
    this._localStorage.removeItem(this.KHAZANA_TIMESTAMP);
  }

  setStudyTime(data: any) {
    return this.setValue(this.STUDY_TIME, data, 'string');
  }
  getStudyTime() {
    return this.getValue(this.STUDY_TIME, 'string');
  }
  removeStudyTime() {
    return this.removeValue(this.STUDY_TIME);
  }

  setSelectedPlanAndAmount(data: any) {
    this._localStorage.setItem(
      this.PLAN_SELECTED_DETAILS,
      JSON.stringify(data)
    );
  }
  getSelectedPlanAndAmount() {
    return this._localStorage.getItem(this.PLAN_SELECTED_DETAILS);
  }
  removeSelectedPlanAmount() {
    return this._localStorage.removeItem(this.PLAN_SELECTED_DETAILS);
  }

  setMyDoubtSelectedBatch(data: any) {
    this.setValue(this.MY_DOUBT_SELECTED_BATCH, data, 'json');
  }

  getMyDoubtSelectedBatch() {
    return this.getValue(this.MY_DOUBT_SELECTED_BATCH, 'json');
  }

  removeMyDoubtSelectedBatch() {
    this.removeValue(this.MY_DOUBT_SELECTED_BATCH);
  }

  setRandomId(value: string) {
    this._localStorage.setItem('uuid', value);
  }

  getRandomId() {
    return this._localStorage.getItem('uuid');
  }

  setScheduleClassBatch(data: AllPurchasedBatches) {
    this.setValue(this.SCHEDULE_CLASS_SELECTED_BATCH, data, 'json');
  }

  getScheduleClassBatch() {
    return this.getValue(this.SCHEDULE_CLASS_SELECTED_BATCH, 'json');
  }

  setTestActiveLanguage(data: any) {
    this.setValue(this.TEST_ACTIVE_LANGUAGE, data, 'json');
  }
  getTestActiveLanguage() {
    return this.getValue(this.TEST_ACTIVE_LANGUAGE, 'json');
  }
  removeTestActiveLanguage() {
    this.removeValue(this.TEST_ACTIVE_LANGUAGE);
  }

  setCameFromK8(value: string) {
    this.setValue(this.CAME_FROM_K8, value, 'string');
  }
  getCameFromK8() {
    return this.getValue(this.CAME_FROM_K8, 'string');
  }
  removeCameFromK8() {
    this.removeValue(this.CAME_FROM_K8);
  }

  getScholarshipStatus() {
    return this.getValue(this.USER_SCHOLARSHIP_STATUS, 'string');
  }

  removeScholarshipStatus() {
    this.removeValue(this.USER_SCHOLARSHIP_STATUS);
  }

  getRegistrationStep() {
    return this.getValue(this.REGISTRATION_STEP, 'string');
  }

  getIsInstructionBack() {
    return this.getValue(this.IS_INSTRUCTION_BACK, 'string');
  }

  getScholarshipResult() {
    return this.getValue(this.SCHOLARSHIP_RESULT, 'string');
  }

  setCenterPageArray(data: any) {
    this.setSessionValue(this.PW_CENTER_ARRAY, data, 'json');
  }

  getCenterPageArray() {
    return this.getSessionValue(this.PW_CENTER_ARRAY, 'json');
  }

  removeCenterPageArray() {
    this.removeSessionValue(this.PW_CENTER_ARRAY);
  }

  setMicroLearningPageArray(data: any) {
    this.setSessionValue(this.PW_MICRO_LEARNING_ARRAY, data, 'json');
  }

  getMicroLearningPageArray() {
    return this.getSessionValue(this.PW_MICRO_LEARNING_ARRAY, 'json');
  }

  removeMicroLearningPageArray() {
    this.removeSessionValue(this.PW_MICRO_LEARNING_ARRAY);
  }
  /* Webinar */
  setWebinarPageArray(data: any) {
    this.setSessionValue(this.PW_WEBINAR_ARRAY, data, 'json');
  }

  getWebinarPageArray() {
    return this.getSessionValue(this.PW_WEBINAR_ARRAY, 'json');
  }

  removeWebinarPageArray() {
    this.removeSessionValue(this.PW_WEBINAR_ARRAY);
  }

  /* mentorship */
  setMentorShipPageArray(data: any) {
    this.setSessionValue(this.PW_MENTORSHIP, data, 'json');
  }

  getMentorShipPageArray() {
    return this.getSessionValue(this.PW_MENTORSHIP, 'json');
  }
  removeMentorShipPageArray() {
    this.removeSessionValue(this.PW_MENTORSHIP);
  }

  /* Scholarship */
  setScholarshipPageArray(data: any) {
    this.setSessionValue(this.PW_SCHOLARSHIP, data, 'json');
  }

  getScholarshipPageArray() {
    return this.getSessionValue(this.PW_SCHOLARSHIP, 'json');
  }

  removeScholarshipPageArray() {
    this.removeSessionValue(this.PW_SCHOLARSHIP);
  }

  setSessionValue(key: string, data: any, type: 'string' | 'json' | 'boolean') {
    if (type === 'json') {
      this._sessionStorage.setItem(key, JSON.stringify(data));
    } else {
      this._sessionStorage.setItem(key, data);
    }
  }

  getSessionValue(key: string, type: 'string' | 'json' | 'boolean') {
    if (type === 'json') {
      return JSON.parse(this._sessionStorage.getItem(key)!);
    }
    return this._sessionStorage.getItem(key);
  }

  removeSessionStorage() {
    this._sessionStorage.clear();
  }

  removeSessionValue(key: string) {
    this._sessionStorage.removeItem(key);
  }

  setMahapackCheckData(data: any) {
    this.setValue(this.MAHAPACK_CHECK_DATA, data, 'json');
  }

  getMahapackCheckData() {
    return this.getValue(this.MAHAPACK_CHECK_DATA, 'json');
  }
  removeMahapackCheckData() {
    this.removeValue(this.MAHAPACK_CHECK_DATA);
  }

  setSaarthiSource(data: any) {
    this.setSessionValue(this.SAARTHI_SOURCE, data, 'json');
  }
  getSaarthiSource() {
    return this.getSessionValue(this.SAARTHI_SOURCE, 'json');
  }
  removeSaarthiSource() {
    this.removeSessionValue(this.SAARTHI_SOURCE);
  }

  // my dashboard urls start
  setMyDashboardUrls(data: any) {
    this.setSessionValue(this.MY_DASHBOARD_URLS, data, 'json');
  }
  getMyDashboardUrls() {
    return this.getSessionValue(this.MY_DASHBOARD_URLS, 'json');
  }
  removeMyDashboardUrls() {
    this.removeSessionValue(this.MY_DASHBOARD_URLS);
  }

  // my dashboard urls  end

  // set batchId , batchScheduleId,testcatId
  setBatchIdSUbjectIdTest(data: any) {
    this.setValue(this.BATCHID_SUBJECTID_TESTCATID, data, 'json');
  }
  getBatchIdSUbjectIdTest() {
    return this.getValue(this.BATCHID_SUBJECTID_TESTCATID, 'json');
  }
  removeBatchIdSUbjectIdTest() {
    this.removeValue(this.BATCHID_SUBJECTID_TESTCATID);
  }
  setCartSelectedData(data: any) {
    this.setSessionValue(this.CART_SELECTED_IDS, data, 'json');
  }
  getCartSelectedData() {
    return this.getSessionValue(this.CART_SELECTED_IDS, 'json');
  }
  removeCartSelectedData() {
    this.removeSessionValue(this.CART_SELECTED_IDS);
  }

  // set batchId , batchScheduleId,testcatId

  setResponceCode(data: any) {
    this.setValue(this.RESPONSE_CODE, data, 'string');
  }
  getResponceCode() {
    return this.getValue(this.RESPONSE_CODE, 'string');
  }
  removeResponceCode() {
    this.removeValue(this.RESPONSE_CODE);
  }

  setRequestUrl(data: any) {
    this.setValue(this.REQUEST_URL, data, 'string');
  }
  getRequestUrl() {
    return this.getValue(this.REQUEST_URL, 'string');
  }

  // fetch stats data
  setFetchStatsData(data: any) {
    this.setSessionValue(this.FETCH_STATS_DATA, data, 'json');
  }

  getFetchStatsData() {
    return this.getSessionValue(this.FETCH_STATS_DATA, 'json');
  }
  removeFetchStatsData() {
    this.removeSessionValue(this.FETCH_STATS_DATA);
  }

  // purchase source
  setPurchaseSource(data: string) {
    this.setValue(this.PURCHASE_SOURCE, data, 'string');
  }

  getPurchaseSource() {
    return this.getValue(this.PURCHASE_SOURCE, 'string');
  }
  removePurchaseSource() {
    this.removeValue(this.PURCHASE_SOURCE);
  }
  // after payment coming source;
  setAfterPaymentComingSource(data: string) {
    this.setValue(this.AFTER_PAYMENT_COMING_SOURCE, data, 'string');
  }

  getAfterPaymentComingSource() {
    return this.getValue(this.AFTER_PAYMENT_COMING_SOURCE, 'string');
  }

  setPartPaymentEnabledInABatch(data: string) {
    return this.setValue(this.IS_PART_PAYMENT_ENABLED, data, 'string');
  }

  removePartPaymentEnabledInABatch() {
    return this.removeValue(this.IS_PART_PAYMENT_ENABLED);
  }

  removeDefaultPaymentMode() {
    return this.removeValue(this.DEFAULT_PAYMENT_MODE);
  }

  getPartPaymentEnabledInABatch() {
    return this.getValue(this.IS_PART_PAYMENT_ENABLED, 'string');
  }

  setDefaultPaymentMode(data: string) {
    return this.setValue(this.DEFAULT_PAYMENT_MODE, data, 'string');
  }

  getDefaultPaymentMode() {
    return this.getValue(this.DEFAULT_PAYMENT_MODE, 'string');
  }

  removeAfterPaymentComingSource() {
    this.removeValue(this.AFTER_PAYMENT_COMING_SOURCE);
  }

  setAfterPaymentOrderId(data: string) {
    this.setValue(this.ORDER_ID, data, 'string');
  }

  getAfterPaymentOrderId() {
    return this.getValue(this.ORDER_ID, 'string');
  }
  removeAfterPaymentOrderId() {
    this.removeValue(this.ORDER_ID);
  }
  setFbtComingSource(data: any) {
    this.setValue(this.FBT_COMING_SOURCE, data, 'string');
  }

  getFbtComingSource() {
    return this.getValue(this.FBT_COMING_SOURCE, 'string');
  }
  removeFbtComingSource() {
    this.removeValue(this.FBT_COMING_SOURCE);
  }

  setLandingType(type: string) {
    this.setSessionValue('landing_type', type, 'string');
  }
  getLandingType() {
    return this.getSessionValue('landing_type', 'string');
  }
  removeLandingType() {
    return this.removeSessionValue('landing_type');
  }

  getCampaignData() {
    return this.getValue(this.CAMPAIGN_PARAM, 'string');
  }

  setCampaignData() {
    const queryParams = new URLSearchParams(
      decodeURIComponent(this.globalObjectService.windowObj.location.search)
    );

    const marketingCampaigns = {} as Record<string, string>;
    const setMarketingAttribute = (paramName: string) => {
      const paramValue = queryParams.get(paramName);
      if (paramValue) {
        marketingCampaigns[paramName] = paramValue;
      }
    };

    setMarketingAttribute('utm_source');
    setMarketingAttribute('utm_medium');
    setMarketingAttribute('utm_campaign');
    setMarketingAttribute('utm_term');
    setMarketingAttribute('utm_content');
    setMarketingAttribute('gclid');
    setMarketingAttribute('fbclid');
    setMarketingAttribute('igshid');

    if (Object.keys(marketingCampaigns).length > 0) {
      const jsonMarketingCampaign = JSON.stringify(marketingCampaigns);
      localStorage.setItem(this.CAMPAIGN_PARAM, btoa(jsonMarketingCampaign));
      this.isCampaignDataAvailable$.next(true);
    }
  }

  getPaymentData() {
    return this.getValue('PAYMENT', 'json');
  }

  setBatchPlanId(id: string) {
    this.setSessionValue(this.BATCH_PLAN_ID, id, 'string');
  }

  getBatchPlanId() {
    return this.getSessionValue(this.BATCH_PLAN_ID, 'string');
  }

  removeBatchPlanId() {
    this.removeSessionValue(this.BATCH_PLAN_ID);
  }

  getCameFrom() {
    return this.getValue('came_from', 'string');
  }

  setCameFrom(value: string) {
    return this.setValue('came_from', value, 'string');
  }

  removeCameFrom() {
    return this.removeValue('came_from');
  }

  setPremiumData(data: any) {
    this.setValue(this.PREMIUM_DATA, data, 'json');
  }

  getPremiumData() {
    return this.getValue(this.PREMIUM_DATA, 'json');
  }

  removePremiumData() {
    this.removeValue(this.PREMIUM_DATA);
  }

  setComparisonPlanData(data: BatchPlus) {
    this.setValue(this.COMPARISON_PLAN, data, 'json');
  }

  getComparisonPlanData() {
    return this.getValue(this.COMPARISON_PLAN, 'json');
  }

  removeComparisonPlanData() {
    this.removeValue(this.COMPARISON_PLAN);
  }
  setRouteToNewFbtToSessionStorage() {
    this.setSessionValue(this.ROUTE_TO_NEW_FBT, true, 'boolean');
  }

  getRoutToNewFBTSessionValue() {
    return this.getSessionValue(this.ROUTE_TO_NEW_FBT, 'boolean');
  }

  setLastVisitedUrl(url: string) {
    this.setSessionValue(this.LAST_VISITED_URL, url, 'string');
  }

  getLastVisitedUrl() {
    return this.getSessionValue(this.LAST_VISITED_URL, 'string');
  }

  removeLastVisitedUrl() {
    this.removeSessionValue(this.LAST_VISITED_URL);
  }

  setAttachmentsBackUrl(url: string) {
    this.setValue('NEW_ATTACHMENTS_BACK_URL', url, 'string');
  }
  getAttachmentsBackUrl() {
    return this.getValue('NEW_ATTACHMENTS_BACK_URL', 'string');
  }
  removeAttachmentsBackUrl() {
    this.removeValue('NEW_ATTACHMENTS_BACK_URL');
  }

  setShowEMIPopUpExpiryTime(data: string) {
    this._localStorage.setItem(this.SHOW_POP_UP_AFTER_EXPIRY, data);
  }

  getShowEMIPopUpExpiryTime() {
    return this._localStorage.getItem(this.SHOW_POP_UP_AFTER_EXPIRY);
  }

  removeShowEmiPopUpExpiryTime() {
    this._localStorage.removeItem(this.SHOW_POP_UP_AFTER_EXPIRY);
  }

  setKhazanaContentUrls(data: any) {
    this.setSessionValue(STORAGE_ENUM.KHAZANA_CONTENT_URLS, data, 'json');
  }

  getKhazanaContentUrl() {
    return this.getSessionValue(STORAGE_ENUM.KHAZANA_CONTENT_URLS, 'json');
  }

  removeKhazanaContentUrl() {
    this.removeSessionValue(STORAGE_ENUM.KHAZANA_CONTENT_URLS);
  }

  setAllClassesUrls(data: any) {
    this.setSessionValue(STORAGE_ENUM.ALL_CLASSES_URLS, data, 'json');
  }

  getAllClassesUrl() {
    return this.getSessionValue(STORAGE_ENUM.ALL_CLASSES_URLS, 'json');
  }

  removeAllClassesUrl() {
    this.removeSessionValue(STORAGE_ENUM.ALL_CLASSES_URLS);
  }

  setReferredBy(userId: string) {
    this._localStorage.setItem('referredBy', userId);
  }

  getReferredBy() {
    return this._localStorage.getItem('referredBy');
  }

  removeReferredBy() {
    return this._localStorage.removeItem('referredBy');
  }

  setBatchesUrls(data: any) {
    this.setSessionValue(STORAGE_ENUM.BATCHES_URLS, data, 'json');
  }

  removeBatchesUrl() {
    this.removeSessionValue(STORAGE_ENUM.BATCHES_URLS);
  }

  getBatchesUrl() {
    return this.getSessionValue(STORAGE_ENUM.BATCHES_URLS, 'json');
  }

  setIsBatchRevampEnabled(enabled: boolean) {
    this.setValue(STORAGE_ENUM.IS_BATCH_REVAMP_ENABLED, enabled, 'string');
  }
}

export enum STORAGE_ENUM {
  BATCH_ACTIVE_SECTION = 'batch_active_section',
  BATCH_CLUBBING_TEST_DATA = 'batch_clubbing_test_data',
  KHAZANA = 'KHAZANA',
  KHAZANA_SOURCE = 'khazana_source',
  KHAZANA_TITLE = 'khazana_title',
  IP_SOURCE = 'ip_source',
  COHORT_ID = 'COHORT_ID',
  KHAZANA_CONTENT_URLS = 'khazana_content_urls',
  KHAZANA_LANDING_URLS = 'khazana_landing_urls',
  ALL_CLASSES_URLS = 'all_classes_urls',
  BATCHES_URLS = 'batches_urls',
  PREMIUM_CONTENT_URLS = 'premium_content_urls',
  MAHAPACK_COACHMARK = 'mahapack_coachmark',
  BATCH_SOURCE_URL = 'batch_source_url',
  IS_BATCH_REVAMP_ENABLED = 'is_batch_revamp_enabled',
}
