import { environment } from '../../../../environments/environment';
import { HAMBURGER_ICONS } from '../../../core/assets.location';

const hamburgerIcons = HAMBURGER_ICONS;
export const sidePagesDesktop = [
  {
    title: 'Home',
    logo: hamburgerIcons.home,
    url: '/dashboard',
    isActive: false,
    isEnabled: false,
    configName: 'isHomePageEnabled',
  },
  {
    title: 'Study',
    logo: hamburgerIcons.study,
    url: '/batches/study',
    isActive: false,
    external: false,
    isNew: false,
    isEnabled: false,
    configName: 'isStudyPageEnabled',
  },
  {
    title: 'Batches',
    logo: hamburgerIcons.batches,
    url: '/batches',
    isActive: false,
    isEnabled: true,
    configName: 'isBatchPageEnabled',
  },
  {
    title: 'PW Centres',
    logo: hamburgerIcons.pwCenters,
    url: '/pw-centres',
    isActive: false,
    isNew: false,
    isEnabled: false,
    configName: 'isPWCentersEnabled',
  },
  {
    title: 'VP Live',
    logo: hamburgerIcons.vp_live,
    url: '/vp-live',
    isActive: false,
    isEnabled: true,
    configName: 'isVpLiveEnabled',
  },
  {
    title: 'PW Store',
    logo: hamburgerIcons.store,
    // url: '/books-public',
    url: 'https://store.pw.live/authenticate',
    isActive: false,
    isEnabled: false,
    configName: '',
    external: true,
    isNew: false,
  },
  {
    title: 'Test Series',
    logo: hamburgerIcons.testSeries,
    url: '/test-series',
    isActive: false,
    isEnabled: false,
    configName: 'isTestSeriesEnabled',
  },
  {
    title: 'Scholarship',
    logo: hamburgerIcons.PWNSAT,
    url: '/pw-scholarship',
    isActive: false,
    external: false,
    isNew: false,
    isEnabled: false,
    configName: 'isScholarshipEnabled',
  },
  {
    title: 'Financial Support',
    logo: hamburgerIcons.YOGYA,
    url: environment.yogyaUrl,
    isActive: false,
    external: false,
    isNew: false,
    isEnabled: false,
    configName: 'isYogyaEnabled',
  },
  {
    title: 'Quick-Learning',
    logo: hamburgerIcons.microLearning,
    url: '/batches/study/micro-learning',
    isActive: false,
    external: false,
    isNew: false,
    isEnabled: false,
    configName: 'isMicroLearningEnabled',
  },

  {
    title: 'Upskilling - Job Assistance',
    logo: hamburgerIcons.pwSkills,
    url: environment.skillsUrl,
    isActive: false,
    external: true,
    isNew: false,
    isEnabled: false,
    configName: '',
  },
  // {
  //   title: 'API ERROR (TESTING)',
  //   logo: '',
  //   url: '/api-error',
  //   isActive: false,
  //   external: true,
  //   isNew: false,
  //   isEnabled: true,
  //   configName: '',
  // },
  {
    title: 'Government jobs',
    logo: hamburgerIcons.jobs,
    url: '/job-alert',
    isActive: false,
    external: false,
    isNew: false,
    isEnabled: false,
    configName: 'isGovtJobEnabled',
  },
  {
    title: 'Saarthi',
    logo: hamburgerIcons.saarthi,
    url: '/saarthi',
    isActive: false,
    isEnabled: false,
    configName: 'isSarthiEnabled',
  },
  {
    title: 'Library',
    logo: hamburgerIcons.library,
    url: '/library',
    isActive: false,
    isEnabled: false,
    configName: 'isLibraryEnabled',
  },

  {
    title: 'Submit Your Admit Card',
    logo: hamburgerIcons.uploadAdmitCard,
    url: '',
    isActive: false,
    isEnabled: false,
    configName: '',
  },

  {
    title: 'PW Champions League',
    logo: hamburgerIcons.school,
    url: '/school-contact-program',
    isActive: false,
    isEnabled: false,
    configName: 'isPwChampionshipEnabled',
  },
  {
    title: 'My Purchase',
    logo: hamburgerIcons.briefcase,
    url: '/my-purchase?came_from=hamburger',
    isActive: false,
    isEnabled: false,
    onlyMobile: true,
    configName: '',
  },
  {
    title: 'Refer & Earn',
    logo: hamburgerIcons.referEarn,
    url: '/referral-new',
    isActive: false,
    isEnabled: false,
    configName: 'isReferAndEarnEnabled',
  },
  {
    title: 'My Wallet',
    logo: hamburgerIcons.wallet,
    url: '/wallet',
    isActive: false,
    isEnabled: false,
    configName: 'isWalletEnabled',
  },

  {
    title: 'Our results',
    logo: hamburgerIcons.results,
    url: '/results',
    isActive: false,
    isEnabled: true,
    configName: 'isOurResultsEnabled',
  },

  {
    title: 'Feeds',
    logo: hamburgerIcons.feeds,
    url: '/feeds',
    isActive: false,
    isEnabled: false,
    configName: 'isFeedsEnabled',
  },

  // {
  //   title: 'Notifications',
  //   logo: hamburgerIcons.notifications,
  //   url: '/notifications',
  //   isActive: false,
  // },

  {
    title: 'Contact Us',
    logo: hamburgerIcons.contactUs,
    url: '/contact-us',
    isActive: false,
    isEnabled: false,
    configName: 'isContactUsEnabled',
  },
];

export const collapsablePages = [
  {
    title: 'About us',
    url: '/about-us',
    logo: hamburgerIcons.aboutUs,
    isActive: false,
    isEnabled: false,
    configName: 'isAboutUsEnabled',
  },
  {
    title: 'Terms & Conditions',
    logo: hamburgerIcons.tnC,
    url: '/termsandconditions',
    isActive: false,
    isEnabled: false,
    configName: 'isTandCEnabled',
  },
];
