import { Injectable } from '@angular/core';
import { AppUrlService } from '../../app-url/app-url.service';
import { PPApiService } from '../../api/api.service';
import { PPApiOptions, PPApiParams } from '../../api/api.type';
import { catchError } from 'rxjs/operators';
import { handleError } from '../error-handler/error-handler.service';
import { KEYS } from 'src/environments/environment';
import * as crypto from 'crypto-js';
@Injectable({
  providedIn: 'root',
})
export class VideolicenseService {
  b64 = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';

  constructor(
    private appUrlService: AppUrlService,
    private apiService: PPApiService
  ) {}

  getAwsKey(url: string) {
    const path = this.appUrlService.GET_AWS_KEY();
    const options: PPApiOptions = {
      apiPath: path,
    };
    return this.apiService
      .post<any>({ url: url }, options)
      .pipe(catchError(handleError));
  }

  getEncryptedKey(url: string) {
    const path = this.appUrlService.GET_ENCRYPTED_KEY();
    const options: PPApiOptions = {
      apiPath: path,
    };
    return this.apiService
      .post<any>({ url: url }, options)
      .pipe(catchError(handleError));
  }
  encrypt(key: any, data: any) {
    data = this.xor_encrypt(key, data);
    return this.b64_encode(data);
  }

  private b64_encode(data: any) {
    let o1,
      o2,
      o3,
      h1,
      h2,
      h3,
      h4,
      bits,
      r,
      i = 0,
      enc = '';
    if (!data) {
      return data;
    }
    do {
      o1 = data[i++];
      o2 = data[i++];
      o3 = data[i++];
      bits = (o1 << 16) | (o2 << 8) | o3;
      h1 = (bits >> 18) & 0x3f;
      h2 = (bits >> 12) & 0x3f;
      h3 = (bits >> 6) & 0x3f;
      h4 = bits & 0x3f;
      enc +=
        this.b64.charAt(h1) +
        this.b64.charAt(h2) +
        this.b64.charAt(h3) +
        this.b64.charAt(h4);
    } while (i < data.length);
    r = data.length % 3;
    return (r ? enc.slice(0, r - 3) : enc) + '==='.slice(r || 3);
  }

  base64ToHex(str: string) {
    str = str.replace(/\-/g, '+').replace(/\_/g, '/');
    for (
      var i = 0, bin = atob(str.replace(/[ \r\n]+$/, '')), hex = [];
      i < bin.length;
      ++i
    ) {
      let tmp = bin.charCodeAt(i).toString(16);
      if (tmp.length === 1) {
        tmp = '0' + tmp;
      }
      hex[hex.length] = tmp;
    }
    return hex.join('');
  }

  hexEncode(code: string) {
    var hex, i;

    var result = '';
    for (i = 0; i < code.length; i++) {
      hex = code.charCodeAt(i).toString(16);
      result += ('000' + hex).slice(-4);
    }

    return result;
  }

  hexDecode(code: string) {
    var j;
    var hexes = code.match(/.{1,4}/g) || [];
    var decodedString = '';
    for (j = 0; j < hexes.length; j++) {
      decodedString += String.fromCharCode(parseInt(hexes[j], 16));
    }

    return decodedString;
  }

  getKeyFromServer(params: any) {
    params = params || {};
    const path = this.appUrlService.GET_LICENCE;
    const options: PPApiOptions = {
      apiPath: path,
      params: new PPApiParams().appendAll(params),
    };
    return this.apiService.get<any>(options).pipe(catchError(handleError));
  }

  hexToBase64(str: any) {
    return btoa(
      String.fromCharCode.apply(
        null,
        str
          .replace(/\r|\n/g, '')
          .replace(/([\da-fA-F]{2}) ?/g, '0x$1 ')
          .replace(/ +$/, '')
          .split(' ')
      )
    )
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=*$/, '');
  }

  decrypt(key: string, data: string | number[]) {
    data = this.b64_decode(data);
    return this.xor_decrypt(key, data);
  }
  private b64_decode(data: any) {
    let o1,
      o2,
      o3,
      h1,
      h2,
      h3,
      h4,
      bits,
      i = 0,
      result = [];
    if (!data) {
      return data;
    }
    data += '';
    do {
      h1 = this.b64.indexOf(data.charAt(i++));
      h2 = this.b64.indexOf(data.charAt(i++));
      h3 = this.b64.indexOf(data.charAt(i++));
      h4 = this.b64.indexOf(data.charAt(i++));
      bits = (h1 << 18) | (h2 << 12) | (h3 << 6) | h4;
      o1 = (bits >> 16) & 0xff;
      o2 = (bits >> 8) & 0xff;
      o3 = bits & 0xff;
      result.push(o1);
      if (h3 !== 64) {
        result.push(o2);
        if (h4 !== 64) {
          result.push(o3);
        }
      }
    } while (i < data.length);
    return result;
  }

  cookieSplitter(cookie: string) {
    let decryptedCookie = '';
    let cookieArr = cookie.split('&');
    for (let i = 0; i < 3; i++) {
      let cookiePart = cookieArr[i].split(/=(.*)/s); //splits from first occurrence of "="
      decryptedCookie += cookiePart[0];
      decryptedCookie +=
        '=' + this.getDecryptCookie(cookiePart[1]).toString() + '&';
    }
    return decryptedCookie.substr(1, decryptedCookie.length - 2);
  }
  getDecryptCookie(cookie: any) {
    const key = crypto.enc.Utf8.parse(KEYS.VIDEO_ENCRYPTION_KEY);
    const iv = crypto.enc.Utf8.parse(KEYS.INITIALISATION_VECTOR);
    const decryptedKey = crypto.AES.decrypt(cookie, key, { iv: iv });
    return decryptedKey.toString(crypto.enc.Utf8);
    // let decipher = crypto.createDecipheriv('aes-256-cbc', KEYS.VIDEO_ENCRYPTION_KEY, KEYS.INITIALISATION_VECTOR);
    // let decrypted = decipher.update(cookie, 'base64', 'utf8');
    // return (decrypted + decipher.final('utf8'));
  }

  setEncrypt(cookie: string) {
    const key = crypto.enc.Hex.parse(KEYS.VIDEO_ENCRYPTION_KEY);
    const iv = crypto.enc.Hex.parse(KEYS.INITIALISATION_VECTOR);
    const encrypt = crypto.AES.encrypt(cookie, key, { iv });
    return encrypt.toString();
  }

  private xor_encrypt(key: any, data: any) {
    return Object.assign([], data).map((c: string, i: any) => {
      return c.charCodeAt(0) ^ this.keyCharAt(key, i);
    });
  }

  private xor_decrypt(key: any, data: any) {
    return Object.assign([], data)
      .map((c: number, i: any) => {
        return String.fromCharCode(c ^ this.keyCharAt(key, i));
      })
      .join('');
  }

  private keyCharAt(key: string, i: number) {
    return key.charCodeAt(Math.floor(i % key.length));
  }
}
