import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { ApplicationTypes } from '../../services/batch/batch.service';
import { AuthService } from '../../services/auth/auth.service';
import { GlobalObjectService } from '../../services/global-object/global-object.service';
import { FILTER_CLOSE } from 'src/app/core/assets.location';

@Component({
  selector: 'app-lead-forms',
  templateUrl: './lead-forms.component.html',
  styleUrls: ['./lead-forms.component.scss'],
})
export class LeadFormsComponent implements OnInit {
  url: string = '';
  iframe: any;
  filterCloseIcon = FILTER_CLOSE;
  constructor(
    public dialogRef: MatDialogRef<LeadFormsComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private authService: AuthService,
    private globalObjectService: GlobalObjectService
  ) {
    this.url = `${environment.LEAD_FORMS_LINK}?type=LEAD_FILTRATION&formId=${modalData?.formId}&batchId=${modalData?.batchId}`;
  }

  checkLoaded(event: any, iframe: HTMLIFrameElement) {
    this.iframe = iframe;
    this.sendData(iframe);
  }

  sendData(iframe: HTMLIFrameElement) {
    setTimeout(() => {
      const data = {
        applicationTypes: ApplicationTypes.LEAD_FORM,
        authToken: this.authService.getTokenAsValue(),
      };
      iframe?.contentWindow?.postMessage(data, '*');
    }, 500);
  }

  ngOnInit(): void {
    this.globalObjectService.windowObj.addEventListener(
      'message',
      this.eventHandler
    );
  }

  closeLeadForm() {
    this.modalData.onCloseCB();
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.globalObjectService.windowObj.removeEventListener(
      'message',
      this.eventHandler
    );
  }

  eventHandler = (event: MessageEvent) => {
    if (
      event.data.key === 'triggerOnSuccess' &&
      event.data.applicationType === ApplicationTypes.LEAD_FORM
    ) {
      this.dialogRef.close();
      this.modalData.onSuccessCB();
    }
  };
}
