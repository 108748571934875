<div>
  <div class="flex justify-end cursor-pointer">
    <img [src]="cross" (click)="onClose()" />
  </div>
  <div>
    <rs-penpencil-player
      #player
      (inIt)="playerLoaded($event)"
      (onPause)="handleVideoPause($event)"
      (onPlay)="handleVideoPlay($event)"
      (onEnded)="handleVideoPause($event)"
      *ngIf="playerConfig && playerConfig.sources"
      (onFullscreenchange)="handleFullScreenChange($event)"
      [playerConfig]="playerConfig"
    >
    </rs-penpencil-player>
  </div>
</div>
