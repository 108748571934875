import { LocationStrategy } from '@angular/common';
import { Injectable } from '@angular/core';
import { SNACKBAR_MESSAGE } from 'src/app/constants/message.constant';
import { GlobalService } from '../global/global.service';
import { lastValueFrom } from 'rxjs';
import { VideolicenseService } from '../videolicense/videolicense.service';
import { environment } from 'src/environments/environment';
import { getDomainName, querySplitter } from '../../utils/utils';

declare const videojs: any;

@Injectable({
  providedIn: 'root',
})
export class PlayerConfigService {
  constructor(
    private location: LocationStrategy,
    private globalService: GlobalService,
    private videoLicenseService: VideolicenseService
  ) {}

  async penpencilLogic(
    videoDetails: any,
    isLive: boolean,
    videoUrl: string,
    poster: string,
    isEnableFullScreen: boolean
  ) {
    const browser = videojs.browser;
    // let HLStype = false;

    const isSafari =
      browser &&
      (browser['IS_SAFARI'] ||
        browser['IS_IOS'] ||
        browser['IS_IPAD'] ||
        browser['IS_IPHONE'] ||
        browser['IS_IPOD']);
    const types = videoDetails?.types || ['DASH'];
    if (isSafari && types.length && !types.includes('HLS')) {
      this.globalService.showSnackBar(SNACKBAR_MESSAGE.VIDEO_NOT_SUPPORTED);
      await this.location.back();
      return;
    }
    let cookie = '';
    let encryptedCookie = '';
    // if (types.length && types.includes('HLS')) {
    //   HLStype = true;
    // }
    const playerConfigLocal: any = {
      poster: poster,
      liveui: isLive,
      sources: [],
      autoplay: true,
      startTime: 0,
      fullScreenEnabled: isEnableFullScreen,
      fluid: true,
      fill: false,
      responsive: false,
      seekButtons: true,
      seekSeconds: 0,
      withCredentials: true,
      accessToken: this.globalService.getAccessToken().value,
    };

    let src;
    if (isSafari) {
      let maskedUrl: any = videoUrl.split('/');
      if (maskedUrl[2] === 'https://duaj2e1qg40as.cloudfront.net') {
        maskedUrl[2] = environment.SAFARI_CDN_2;
      } else {
        maskedUrl[2] = environment.SAFARI_CDN_1;
      }

      maskedUrl.join('/');
      maskedUrl = maskedUrl.join('/');
      src = maskedUrl.replace('mpd', 'm3u8');

      encryptedCookie = await this.getAwsCookieViaNetwork(src);
      cookie = this.videoLicenseService.cookieSplitter(encryptedCookie);
      let queryObj: any = querySplitter(cookie);

      document.cookie = `CloudFront-Key-Pair-Id=${
        queryObj['Key-Pair-Id']
      };domain=.${getDomainName()};path=/`;
      document.cookie = `CloudFront-Policy=${
        queryObj['Policy']
      };domain=.${getDomainName()};path=/`;
      document.cookie = `CloudFront-Signature=${
        queryObj['Signature']
      };domain=.${getDomainName()};path=/`;
    }
    if (isSafari) {
      const sources = [
        {
          src: src,
          // src: ,
          type: 'application/x-mpegURL',
          withCredentials: true,
          cacheEncryptionKeys: true,
        },
      ];

      playerConfigLocal['headers'] = [
        {
          authorization: `Bearer ${this.globalService.accessToken}`,
        },
      ];

      playerConfigLocal.sources = sources;
      return playerConfigLocal;
    } else {
      const sourceData = {
        src: videoUrl,
        type: 'application/dash+xml',
        keySystems: {
          'org.w3.clearkey': {
            videoContentType: 'video/mp4;codecs="avc1.42c01e"',
            audioContentType: 'audio/mp4;codecs="mp4a.40.2"',
            getLicense: (emeOptions: any, keyMessage: any, callback: any) => {
              this.getLicenseFromServer(emeOptions, keyMessage, callback);
            },
          },
        },
      };
      try {
        const cookie = await this.getAwsCookieViaNetwork(videoUrl);
        const changedCookie = this.videoLicenseService.cookieSplitter(cookie);
        if (changedCookie) {
          sourceData.src += `?${changedCookie}`;
          playerConfigLocal.query = `?${changedCookie}`;
        }
      } catch (error) {
        console.error('Error while getting cookie or splitting it:', error);
      }
      playerConfigLocal.sources = [];

      playerConfigLocal.sources.push(sourceData);
      return playerConfigLocal;
    }
  }

  async getAwsCookieViaNetwork(videoUrl: string) {
    try {
      const res: any = await lastValueFrom(
        this.videoLicenseService.getEncryptedKey(videoUrl)
      );

      if (res && res['data']) {
        return res.data;
      }
    } catch (e) {
      console.log(e);
    } finally {
    }
  }

  async getLicenseFromServer(emeOptions: any, keyMessage: any, callback: any) {
    const request = JSON.parse(new TextDecoder().decode(keyMessage));
    const token = this.globalService.accessToken;

    const params = {
      key: this.videoLicenseService.hexEncode(
        this.videoLicenseService.encrypt(
          token,
          this.videoLicenseService.base64ToHex(request.kids[0])
        )
      ),
      isEncoded: true,
    };

    try {
      const response = await lastValueFrom(
        this.videoLicenseService.getKeyFromServer(params)
      );
      const keyObj = {
        kty: 'oct',
        kid: request.kids[0],
        k: this.videoLicenseService.hexToBase64(
          this.videoLicenseService.decrypt(
            this.globalService.accessToken,
            response['data'].otp
          )
        ), // This key should be come from server
      };

      callback(
        null,
        new TextEncoder().encode(
          JSON.stringify({
            keys: [keyObj],
          })
        )
      );
    } catch (e) {}
  }
}
