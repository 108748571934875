<mat-sidenav-container *ngIf="showSplash === false">
  <!-- ! Sidebar Content -->
  <mat-sidenav
    *ngIf="
      (!router.url.includes('/auth') &&
        router.url !== '/batches/batch-player' &&
        !router.url.includes('test-engine') &&
        !router.url.includes('new-infinite-practice-engine') &&
        !router.url.includes('sahayak-ip-engine') &&
        !router.url.includes('exercise') &&
        !router.url.includes('logout')) ||
      router.url.includes('saarthi')
    "
    [(opened)]="isSideNavOpen"
    [mode]="mobileQuery.matches ? 'over' : 'side'"
    class="pt-3"
  >
    <app-hamburger></app-hamburger>
  </mat-sidenav>

  <!-- ! Main Content -->
  <mat-sidenav-content
    [ngClass]="{
      'not-found': router.url.includes('not-found'),
      'modify-mat-container': cameFrom === 'dpp'
    }"
  >
    <ng-container *ngIf="!router.url.includes('/auth')">
      <app-header
        [userInfo]="userInfo"
        *ngIf="!router.url.includes('saarthi')"
      ></app-header>
    </ng-container>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
  <mat-sidenav
    *ngIf="
      (!router.url.includes('/auth') &&
        router.url !== '/batches/batch-player' &&
        !router.url.includes('test-engine') &&
        !router.url.includes('new-infinite-practice-engine') &&
        !router.url.includes('exercise') &&
        !router.url.includes('sahayak-ip-engine') &&
        !router.url.includes('logout') &&
        !router.url.includes('pw-scholarship')) ||
      router.url.includes('saarthi')
    "
    [mode]="'over'"
    [(opened)]="isLearnToEarnSideBarOpen"
    position="end"
    class="learn-2-earn-sidebar"
    (closedStart)="onLearn2EarnSidebarClose()"
  >
    <ng-container
      *ngIf="!router.url.includes('/auth') && isLearnToEarnSideBarOpen"
    >
      <learn-2-earn></learn-2-earn>
    </ng-container>
  </mat-sidenav>
</mat-sidenav-container>
<article *ngIf="showSplash === true" class="animation_article vertical-align">
  <div id="load_animation">
    <img [src]="pwLogo" class="animation" alt="" />
  </div>
</article>
