import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { SimpleChanges } from '@angular/core';
// @ts-ignore
import { GlobalObjectService } from '../../services/global-object/global-object.service';

@Component({
  selector: 'app-mathjax',
  templateUrl: './mathjax.component.html',
  styleUrls: ['./mathjax.component.scss'],
})
export class MathjaxComponent implements OnChanges, OnInit {
  @Input() content: string;
  mathJaxObject: any;
  identity: string;
  delay = 10;

  preview: any = null;
  buffer: any = null;

  timeout: any = null;
  mjRunning = false;
  mjPending = false;
  oldText: any = null;
  callback: any = null;

  constructor(public globalObject: GlobalObjectService) {
    this.identity = new Date().getTime() + '' + Math.random();
  }

  ngOnChanges(changes: SimpleChanges) {
    // to render math equations again on content change

    if (changes['content']) {
      setTimeout(() => {
        this.Update();
      }, 100);
    }
  }

  Update() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    // @ts-ignore
    this.timeout = setTimeout(this.callback, this.delay);
  }

  CreatePreview() {
    this.timeout = null;
    if (this.mjPending) return;
    const text = this.content;
    if (text === this.oldText) return;
    if (this.mjRunning) {
      this.mjPending = true;
      // @ts-ignore
      this.globalObject.window!.MathJax.Hub.Queue(['CreatePreview', this]);
    } else {
      this.oldText = text;
      if (this.buffer !== null) {
        this.buffer.innerHTML = this.oldText;
        this.mjRunning = true;
        // @ts-ignore
        this.globalObject.window!.MathJax.Hub.Queue(
          // @ts-ignore
          ['Typeset', this.globalObject.window!.MathJax.Hub, this.buffer],
          ['PreviewDone', this]
        );
      }
    }
  }

  SwapBuffers() {
    let buffer = this.preview,
      preview = this.buffer;
    this.buffer = buffer;
    this.preview = preview;
    buffer.style.visibility = 'hidden';
    buffer.style.position = 'absolute';
    preview.style.position = '';
    preview.style.visibility = '';
  }

  PreviewDone() {
    this.mjRunning = this.mjPending = false;
    this.SwapBuffers();
  }

  ngOnInit() {
    // @ts-ignore
    this.callback = window.MathJax.Callback(['CreatePreview', this]);
    this.callback.autoReset = true; // make sure it can run more than once
    setTimeout(() => {
      this.preview = document.getElementById('MathPreview' + this.identity);
      this.buffer = document.getElementById('MathBuffer' + this.identity);
    }, 100);
  }
}
