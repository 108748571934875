import { Subscription } from 'rxjs';

export interface ImageId {
  _id: string;
  baseUrl: string;
  key: string;
  name: string;
}

export interface TestSeriesData {
  _id: string;
  price: number;
  name: string;
  programId: string;
  description: string;
  fileId?: any;
  imageId: ImageId;
  totalTests: number;
  isPurchased: boolean;
  slug: string;
}

export interface TestSeriesRootObject {
  success: boolean;
  data: TestSeriesData[];
}

/**
 * Test category interface
 */

export interface Error {
  message: string;
  status: number;
}

/**
 * Test List
 */
export interface TestList {
  _id: string;
  type: string;
  maxDuration: number;
  price: number;
  isAvailableFromPoints: boolean;
  availableFor: string[];
  isSpecial: boolean;
  enableNewJeeTestPatter: boolean;
  totalQuestions: number;
  totalMarks: number;
  name: string;
  categoryId: string;
  startTime: Date;
  endTime: Date;
  maxStartTime: Date;
  minStartTime: Date;
  solutions: any[];
  resultScheduleAt: Date;
  enableNewNeetTestPatter: boolean;
  isPurchased: boolean;
  isSubjective?: boolean;
  slug: string;
  programId: string;
  config: TestConfig;
  displayOrder: number;
  enableCuetType1Pattern: boolean;
  enableCuetType2Pattern: boolean;
  sectionTimingEnable: boolean;
  questionFileId: QuestionFileId;
  isFree: boolean;
  modeType: string;
}
export interface QuestionFileId {
  _id: string;
  baseUrl: string;
  name: string;
  key: string;
  organization: string;
  createdAt: Date;
  config: TestConfig;
}
export interface TestConfig {
  proctoring: boolean;
  isScientificCalculatorEnabled: boolean;
}

export interface TestListRootObject {
  success: boolean;
  data: TestList[];
}
export interface TestListParticularObject {
  success: boolean;
  data: TestList;
}
/**
 * test status
 */
export interface TestStatus {
  _id: string;
  testActivityStatus: string;
  endsAt?: any;
}

export interface TestStatusRootObject {
  success: boolean;
  data: TestStatus;
}

/**
 * Test Instruction
 */
export interface TestInstructionData {
  _id: string;
  generalInstructions: string;
  testInstructions: string;
}

export interface TestInstructionRootObject {
  success: boolean;
  data: TestInstructionData;
}

/**
 * Test Student Mapping
 */
export class StudentTestMapping {
  createdAt: string;
  endedAt: string;
  endsAt: string;
  isSaved: string;
  responseFiles: Array<any> = [];
  startedAt: string;
  status: string;
  studentId: string;
  testActivityStatus: string;
  updatedAt: string;
  _id: string;

  constructor(props: any) {
    props = props || {};
    this.createdAt = props.createdAt || '';
    this.endedAt = props.endedAt || '';
    this.endsAt = props.endsAt || '';
    this.isSaved = props.isSaved || '';
    if (props && props.responseFiles) {
      this.responseFiles = props.responseFiles || [];
    } else {
      this.responseFiles = [];
    }
    this.startedAt = props.startedAt || '';
    this.studentId = props.studentId || '';
    this.testActivityStatus = props.testActivityStatus || '';
    this.updatedAt = props.updatedAt || '';
    this._id = props._id || '';
  }
}

/**
 * Test data model
 */
export class Option {
  isSelected: boolean;
  _id: string;
  text: string;
  imageId?: ImageId;

  constructor(data: any) {
    this.text = data?.texts || {};
    this.imageId = data?.imageIds || {};
    this._id = data?._id || '';
  }
}

export interface VideoDetails {
  name: string;
  image: string;
  embedCode: string;
  error: string;
  description: string;
  duration: string;
  _id: string;
  parentId: string;
  questionId: string;
}

export interface ImageId {
  _id: string;
  baseUrl: string;
  name: string;
  key: string;
  organization: string;
  createdAt: Date;
}

export class SolutionDescription {
  _id: string;
  videoType: string;
  videoDetails: VideoDetails;
  imageIds: any;
  videoUrl: string;
  texts: any;

  constructor(data: any) {
    this._id = data._id || '';
    this.videoType = data.videoType || '';
    this.videoDetails = data.videoDetails || {};
    this.imageIds = data.imageIds || {};
    this.videoUrl = data.videoUrl || '';
    this.texts = data.texts || {};
  }
}

export interface ImageId2 {
  _id: string;
  baseUrl: string;
  name: string;
  key: string;
  organization: string;
  createdAt: Date;
}

export class Question2 {
  selectedOption: any;
  timeTaken: number;
  _id: string;
  text: any;
  videoType: string;
  videoUrl: string;
  type: string;
  displayOrder: number;
  options: Option[];
  childQuestions: any[];
  positiveMarks: number;
  negativeMarks: number;
  tags: any[];
  subTags: any[];
  solutionDescription: any[];
  solutions: string[];
  isBookmarked: boolean;
  imageId: any;
  solutionText: number;

  constructor(data: any) {
    this.timeTaken = data?.timeTaken || data?.questionResponse?.timeTaken || 0;
    this.text = data?.texts || {};
    this.imageId = data?.imageIds || {};
    this.videoType = data?.solutionDescription?.videoType || '';
    this.videoUrl =
      (data?.solutionDescription?.videoDetails &&
        data?.solutionDescription?.videoDetails?.baseUrl +
          data?.solutionDescription?.videoDetails?.key) ||
      '';
    this.type = data?.type || data?.questionResponse?.type || '';
    this.displayOrder = data?.displayOrder || 0;

    if (data?.options) {
      this.options = data?.options?.map((option: any) => new Option(option));
    } else {
      this.options = [];
    }
    this.positiveMarks = data?.positiveMarks || '';
    this.negativeMarks = data?.negativeMarks || '';
    this.solutionDescription = data?.solutionDescription || [];
    this.solutions = data?.solutions || [];
    this.solutionText = data?.solutionText || '';
  }
}

export class Question {
  reviewLater: boolean;
  markedSolutionText: string;
  isSubmitted: boolean;
  markedSolutions: string[];
  status: string;
  _id: string;
  questionId: string;
  sectionId: string;
  question: Question2;
  timerSubs: Subscription;
  timeTaken: number;
  notes: string;

  constructor(data: any = {}) {
    data = data || {};
    this.reviewLater = data?.reviewLater || false;
    this.markedSolutionText =
      data?.markedSolutionText ||
      data?.questionResponse?.markedSolutionText ||
      '';
    this.isSubmitted = data?.isSubmitted || false;
    this.markedSolutions =
      data?.markedSolutions || data?.questionResponse?.markedSolutions || [];
    this.status = data?.status || data?.questionResponse?.status || '';
    this._id = data?._id || '';
    this.questionId = data?.questionId || '';
    this.question = data?.question || {};
    this.timeTaken = data?.questionResponse?.timeTaken || 0;
    this.notes = data?.notes || '';
    this.question = new Question2(data);
  }
}

export class Section {
  showQuestions: boolean;
  attemptedNumericQuestions: Question[];
  totalNumericQuestions: Question[];
  _id: string;
  displayOrder: number;
  isOptional: boolean;
  status: string;
  name: string;
  testId: string;
  subjectId: string;
  createdAt: Date;
  updatedAt: Date;
  questions: Question[];
  isSelected: boolean;
  isAttempted: boolean;

  constructor(props: any) {
    this.showQuestions = props.showQuestions || false;
    this._id = props._id || '';
    this.displayOrder = props.displayOrder || 0;
    this.isOptional = props.isOptional || false;
    this.status = props.status || '';
    this.name = props.name || '';
    this.testId = props.testId || '';
    this.subjectId = props.subjectId || '';
    this.createdAt = props.createdAt || '';
    this.updatedAt = props.updatedAt || '';
    this.isSelected = props.isSelected || false;
    this.isAttempted = props.isAttempted || false;

    if (props && props.attemptedNumericQuestions) {
      this.attemptedNumericQuestions = props.attemptedNumericQuestions.map(
        (item: any) => new Question(item)
      );
    } else {
      this.attemptedNumericQuestions = [];
    }

    if (props && props.totalNumericQuestions) {
      this.totalNumericQuestions = props.totalNumericQuestions.map(
        (item: any) => new Question(item)
      );
    } else {
      this.totalNumericQuestions = [];
    }

    if (props && props.questions) {
      this.questions = props.questions.map((item: any) => new Question(item));
    } else {
      this.questions = [];
    }
  }
}

export class Details {
  notViewed: number;
  viewed: number;
  totalQuestions: number;
  testActivityStatus: string;
  isSaved: boolean;
  responseFiles: any[];
  status: string;
  _id: string;
  studentId: string;
  endsAt: Date;
  testId: string;
  createdAt: Date;
  updatedAt: Date;
  startedAt: Date;
  type: string;
  duration: number;
  isScientificCalculatorEnabled?: boolean;
  testLanguages: string[];
  languageMap: any;
  language: string;
  endedAt: Date;
  submittedBy: string;
  isGatePattern: boolean;
  testMappingId: string;

  constructor(data: any) {
    data = data || {};
    this.notViewed = data.notViewed || 0;
    this.viewed = data.viewed || 0;
    this.totalQuestions = data.totalQuestions || 0;
    this.testActivityStatus =
      data.testActivityStatus ||
      data.testStudentMapping.testActivityStatus ||
      '';
    this.isSaved = data.isSaved || false;
    this.responseFiles = data.responseFiles || [];
    this.status = data.status || '';
    this._id = data._id || data.test._id || '';
    this.studentId = this.studentId || data.testStudentMapping._id || '';
    this.endsAt = data.endsAt || data.testStudentMapping.endsAt || '';
    this.testId = data.testId || data.test._id || '';
    this.createdAt = data.createdAt || data.testStudentMapping.createdAt || '';
    this.updatedAt = data.updatedAt || data.testStudentMapping.updated || '';
    this.type = data.type || data.test.type || '';
    this.duration = data.duration || data.test.duration || '';
    this.isScientificCalculatorEnabled =
      data && data?.isScientificCalculatorEnabled
        ? data?.isScientificCalculatorEnabled
        : false;
    this.testLanguages = data.testLanguages || data.languageCodes || [];
    this.languageMap = data.languageMap || data.languageCodes || [];
    this.language = data.language || '';
    this.endedAt = data.endedAt || data.testStudentMapping.endedAt || '';
    this.submittedBy = data.submittedBy || '';
    this.isGatePattern = data.isGatePattern || false;
    this.testMappingId = data.testStudentMapping._id || '';
  }
}

export interface TestData {
  sections: Section[];
  details: Details;
}

export interface TestRootObject {
  success: boolean;
  data: TestData;
}

export interface InstructionRootObject {
  success: boolean;
  data: InstructionData;
}

export class MultiGeneralInstructions {
  hi: string;
  en: string;
  hien: string;
  constructor(data: any) {
    data = data || {};
    this.hi = data.hi || '';
    this.en = data.en || '';
    this.hien = data.hien || '';
  }
}

export class MultiTestInstructions {
  hi: string;
  en: string;
  hien: string;
  constructor(data: any) {
    data = data || {};
    this.hi = data.hi || '';
    this.en = data.en || '';
    this.hien = data.hien || '';
  }
}
export class Config {
  isScientificCalculatorEnabled: boolean;
  proctoring: boolean;
  enableCatPercentile: boolean;
  constructor(data: any) {
    data = data || {};
    this.isScientificCalculatorEnabled =
      data.isScientificCalculatorEnabled || false;
    this.proctoring = data.proctoring || false;
    this.enableCatPercentile = data.enableCatPercentile || false;
  }
}
export class Residence {
  state: string;
  constructor(data: any) {
    data = data || {};
    this.state = data.state || '';
  }
}
export class LanguageCode {
  language: string;
  code: 'hi' | 'en' | 'hien';
  isSelected?: boolean;
  constructor(data: any) {
    this.language = data.language || '';
    this.code = data.code || '';
    this.isSelected = data.code || false;
  }
}

export class Syllabus {
  hi: string;
  en: string;
  hien: string;
  constructor(data: any) {
    data = data || {};
    this.hi = data.hi || '';
    this.en = data.en || '';
    this.hien = data.hien || '';
  }
}

export class InstructionData {
  _id: string;
  name: string;
  maxDuration: number;
  totalMarks: number;
  totalQuestions: number;
  multiGeneralInstructions: MultiGeneralInstructions;
  multiTestInstructions: MultiTestInstructions;
  config: Config;
  enableStateRank: boolean;
  languageCodes: LanguageCode[];
  residence: Residence;
  tag2: string;
  type: string;
  userCategory: string;
  syllabus: Syllabus;
  constructor(props: any) {
    props = props || {};
    this._id = props._id || '';
    this.name = props.name || '';
    this.maxDuration = props.maxDuration || 0;
    this.totalMarks = props.totalMarks || 0;
    this.totalQuestions = props.totalQuestions || 0;
    this.tag2 = props.tag2 || '';
    this.multiGeneralInstructions =
      new MultiGeneralInstructions(props.multiGeneralInstructions) ||
      new MultiGeneralInstructions({});
    this.multiTestInstructions =
      new MultiTestInstructions(props.multiTestInstructions) ||
      new MultiTestInstructions({});
    this.config = new Config(props.config) || new Config({});
    this.enableStateRank = props.enableStateRank || false;
    if (props?.languageCodes) {
      this.languageCodes = props.languageCodes.map(
        (res: any) => new LanguageCode(res)
      );
    } else {
      this.languageCodes = [];
    }
    this.residence = new Residence(props.residence) || new Residence({});
    this.type = props.type || '';
    this.userCategory = props.userCategory || undefined;
    this.syllabus = new Syllabus(props.syllabus) || new Syllabus({});
  }
}

/**
 * Questions Response
 */

export class QuestionsResponse {
  markedSolutions: string[];
  isBookmarked: boolean;
  markedSolutionText: string;
  status: string;
  timeTaken: number;
  questionId: string;
  notes: string;

  constructor(submitDetails: any) {
    this.markedSolutions = submitDetails.markedSolutions || [];
    this.markedSolutionText = submitDetails.markedSolutionText || '';
    this.status =
      UserStatus[submitDetails.status as string] ||
      UserStatus['UnAttempted'] ||
      '';
    this.isBookmarked = submitDetails.question.isBookmarked || false;
    this.timeTaken = submitDetails.timeTaken || 0;
    this.questionId =
      submitDetails.questionId ||
      submitDetails.question.id ||
      submitDetails._id ||
      '';
    this.notes = submitDetails.notes || '';
  }
}

export class QuestionsResponseRootObject {
  questionsResponses: QuestionsResponse[];
  submittedBy: string;
  lastVisitedQuestionId?: string;
  type?: string;
  batchId?: string;

  constructor(data: any) {
    this.questionsResponses = data.data || [];
    this.submittedBy = data.submittedBy || '';
    this.lastVisitedQuestionId = data.lastVisitedQuestionId || '';
    this.type = data.type || '';
    this.batchId = data.batchId || '';
  }
}

/**
 * Test detials object
 */
export interface TestCategoryId {
  _id: string;
  name: string;
  slug: string;
}

export interface ProgramId {
  _id: string;
  name: string;
  slug: string;
}

export interface TestDetailsData {
  type: string;
  _id: string;
  categoryId: TestCategoryId;
  programId: ProgramId;
  name: string;
  slug: string;
  config: TestConfig;
}

export interface TestDetailsRootObject {
  success: boolean;
  data: TestDetailsData;
}
export interface ExerciseFeedbackRootObject {
  success: boolean;
  data?: ExerciseFeedbackData;
}

export interface QuestionFileId {
  _id: string;
  baseUrl: string;
  name: string;
  key: string;
  organization: string;
  createdAt: Date;
}

export interface ResponseData {
  responseFiles: QuestionFileId[];
}

export interface ResponseFileRootObject {
  success: boolean;
  data: ResponseData;
}

export class ExerciseFeedbackData {
  _id: string;
  status: string;
  testId: string;
  studentId: string;
  rating: number;
  tags: string[];
  description: string;
  createdAt: string;
  updatedAt: string;
  constructor(data: any) {
    this._id = data._id || '';
    this.status = data.status || '';
    this.testId = data.testId || '';
    this.studentId = data.studentId || '';
    this.rating = data.rating || '';
    this.tags = data.tags || '';
    this.description = data.description || '';
    this.createdAt = data.createdAt || '';
    this.updatedAt = data.updatedAt || '';
  }
}

export class PracticeDetailsModal {
  testActivityStatus: string;
  testStudentMappingId: string;
  name: string;
  type: string;
  modeType: string;
  currentType: string;
  testSource: string;
  isSubjective: boolean;
  isPurchased: boolean;
  isSaved: boolean;
  isFree: boolean;
  isDelivered: boolean;
  categoryDetails: any;
  batchDetails: BatchDetailsAddons;
  tag1: string;
  tag2: string;
  responseFiles: [];
  submittedBy: string;
  status: string;
  slug: string;
  _id: string;
  testId: TestList;
  startedAt: string;
  endsAt: string;
  createdAt: string;
  updatedAt: string;
  maxDuration: number;
  totalMarks: number;
  totalQuestions: number;
  startTime: string;
  maxStartTime: string;
  endTime: string;
  maxEndTime: string;
  template: string;

  constructor(data: any) {
    data = data || {};
    this.testActivityStatus = data.testActivityStatus || '';
    this.testStudentMappingId = data.testStudentMappingId || '';
    this.name = data.name || '';
    this.type = data.type || '';
    this.modeType = data.modeType || '';
    this.currentType = data.currentType || '';
    this.testSource = data.testSource || '';
    this.isSubjective = data.isSubjective || false;
    this.isPurchased = data.isPurchased || false;
    this.isSaved = data.isSaved || '';
    this.isFree = data.isFree || false;
    this.isDelivered = data.isDelivered || false;
    this.tag1 = data.tag1 || '';
    this.tag2 = data.tag2 || '';
    this.responseFiles = data.responseFiles || [];
    this.submittedBy = data.submittedBy || '';
    this.status = data.status || '';
    this.slug = data.slug || '';
    this._id = data._id || '';
    this.testId = data.testId || {};
    this.startedAt = data.startedAt || '';
    this.endsAt = data.endedAt || '';
    this.createdAt = data.createdAt || '';
    this.updatedAt = data.updatedAt || '';
    this.maxDuration = data.maxDuration || 0;
    this.totalMarks = data.totalMarks || 0;
    this.totalQuestions = data.totalQuestions || 0;
    this.startTime = data.startTime || '';
    this.maxStartTime = data.maxStartTime || '';
    this.endTime = data.endTime || '';
    this.maxEndTime = data.maxEndTime || '';
    this.categoryDetails = data.categoryDetails || {};
    this.batchDetails = data.batchDetails || {};
    this.template = data.template || '';
  }
}

export class FeedbackRatingTag {
  rating: number;
  question: string;
  tags: Tag[];
  constructor(ratingData: any) {
    this.rating = ratingData.rating || 0;
    this.question = ratingData.question || '';
    if (ratingData && ratingData.tags && ratingData.tags.length) {
      this.tags = ratingData.tags.map((tag: string) => new Tag(tag));
    }
  }
}

export class Tag {
  name: string;
  isChecked: boolean;
  constructor(tagName: any) {
    this.name = tagName || '';
    this.isChecked = false;
  }
}

export interface TestFeedback {
  testId: string;
  rating: number;
  tags: string[];
  description: string;
}

const UserStatus: any = Object.freeze({
  Viewed: 'UnAttempted',
  UnAttempted: 'UnAttempted',
  Attempted: 'Attempted',
  NotViewed: 'NotViewed',
});

export class BatchDetailsAddons {
  isAskSaarthiEnabled: boolean;
  masterBatchId: string;
  name: string;
  template: string;
  type: String;
  _id: string;
  constructor(data: any) {
    this.isAskSaarthiEnabled = data.isAskSaarthiEnabled || false;
    this.masterBatchId = data.masterBatchId || '';
    this.name = data.name || '';
    this.template = data.template || '';
    this.type = data.type || '';
    this._id = data._id || '';
  }
}
