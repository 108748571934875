import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { lastValueFrom } from 'rxjs';
import { ShowErrorService } from 'src/app/common/services/showError/show-error.service';
import { TestSeriesService } from 'src/app/common/services/tests/test-series.service';
import { TEST_CLOSE, TEST_LANGUAGE } from 'src/app/core/assets.location';
import { InstructionData, LanguageCode } from '../tests.model';

@Component({
  selector: 'app-test-sidebar',
  templateUrl: './test-sidebar.component.html',
  styleUrls: ['./test-sidebar.component.scss'],
})
export class TestSidebarComponent implements OnInit {
  test_language = TEST_LANGUAGE;
  close_icon = TEST_CLOSE;
  instructionsData: InstructionData;
  activeLang: 'hi' | 'en' | 'hien';
  showLangDropdown: boolean = false;
  showTestInstructions: boolean = false;
  enableStudySource: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<TestSidebarComponent>,
    private showErrorService: ShowErrorService,
    private testService: TestSeriesService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      testId: string;
      showTestInstructions: boolean;
      enableStudySource: boolean;
    }
  ) {}

  ngOnInit(): void {
    this.showTestInstructions = this.data?.showTestInstructions;
    this.enableStudySource = this.data?.enableStudySource;
    this.getInstructionsData();
  }

  async getInstructionsData(): Promise<void> {
    try {
      const res = await lastValueFrom(
        this.testService.getTestInstructionsData(this.data.testId)
      );
      if (res) {
        this.instructionsData = res;
        // set default instruction language to english in case not available set to first language availble
        this.activeLang =
          this.instructionsData?.languageCodes.filter(
            (language: LanguageCode) => language?.code === 'en'
          )[0]?.code || this.instructionsData?.languageCodes[0]?.code;
      }
    } catch (e) {
      await this.showErrorService.showError(e);
    }
  }

  handleLanguageChange(selectedLangCode: 'hi' | 'en' | 'hien'): void {
    this.activeLang = selectedLangCode;
    this.showLangDropdown = false;
  }

  toggleDropdown() {
    this.showLangDropdown = !this.showLangDropdown;
  }

  handleClose(): void {
    this.dialogRef.close();
  }
}
