import { ChapterData } from './../../../pages/library/library-subject/library-subject.model';
import { Injectable } from '@angular/core';
import { PPApiService } from '../../api/api.service';
import { PPApiOptions, PPApiParams } from '../../api/api.type';
import { ORGANIZATION_ID } from '../../../constants/app.constant';
import {
  RecentChapterDataRootObject,
  RecentContentRootObject,
  RootObjectProgram,
} from '../../../pages/library/library-tab/library.model';

import { SearchContent } from 'src/app/pages/library/library-subject/library-search.model';
import { BehaviorSubject, catchError, of } from 'rxjs';
import {
  RootObjectChapter,
  RootObjectTopics,
  ViewAllObject,
} from '../../../pages/library/library-subject/library-subject.model';
import { map, tap, retry } from 'rxjs/operators';
import { ShowErrorService } from '../showError/show-error.service';
import { handleError } from '../error-handler/error-handler.service';
import { QuesBankContentRootObject } from '../../../pages/tests/qBank.model';

@Injectable({
  providedIn: 'root',
})
export class LibraryService {
  chapters$: BehaviorSubject<ChapterData[]> = new BehaviorSubject<
    ChapterData[]
  >([]);
  activeLibraryTab$: BehaviorSubject<ChapterData> =
    new BehaviorSubject<ChapterData>(<ChapterData>{});

  constructor(
    private apiService: PPApiService,
    private showErrorService: ShowErrorService
  ) {}

  getLibraryPrograms(query?: any) {
    const options: PPApiOptions = {
      apiPath: `v1/programs/program-subjects`,

      params: new PPApiParams().append('organizationId', ORGANIZATION_ID),
    };
    return this.apiService.get<RootObjectProgram>(options);
  }

  getCohortLibraryPrograms(cohortId?: string) {
    const options: PPApiOptions = {
      apiPath: `v3/cohort/${cohortId}/program-subject`,

      params: new PPApiParams().append('organizationId', ORGANIZATION_ID),
    };
    return this.apiService.get<RootObjectProgram>(options);
  }

  getFilteredLibraryPrograms(query?: any) {
    const options: PPApiOptions = {
      apiPath: `v1/programs/program-subjects`,

      params: new PPApiParams().appendAll(query),
    };
    return this.apiService.get<RootObjectProgram>(options).pipe(
      map((res) => res),
      tap((data) => {}),
      catchError(handleError)
    );
  }

  // https://api-dev.penpencil.xyz/v1/programs/recent-content?page=1&limit=8
  getRecentChapters(query: any) {
    const options: PPApiOptions = {
      apiPath: `v1/programs/recent-content`,

      params: new PPApiParams()
        .append('page', query.page)
        .append('limit', query.limit),
    };
    return this.apiService.get<RecentChapterDataRootObject>(options).pipe(
      map((res) => res.data),
      tap((data) => {}),
      catchError(handleError)
    );
  }

  // https://api-dev.penpencil.xyz/v1/programs/recent-content?page=1&topicId=5f17f11ce480ef00bc8dd77a
  getRecentContent(query: any) {
    const options: PPApiOptions = {
      apiPath: `v1/programs/recent-content`,

      params: new PPApiParams()
        .append('page', query.page)
        .append('topicId', query.topicId),
    };
    return this.apiService.get<RecentContentRootObject>(options).pipe(
      map((res) => res.data),
      tap((data) => {}),
      catchError(handleError)
    );
  }

  getRecentKhazana(query: any) {
    const options: PPApiOptions = {
      apiPath: `v1/programs/recent-content`,
      params: new PPApiParams().append('programId', query.programId),
    };

    return this.apiService.get<any>(options).pipe(
      map((res) => res.data),
      tap((data) => {}),
      catchError(handleError)
    );
  }

  // https://api-dev.penpencil.xyz/v1/programs/5eb3b1017b1fb86475ec30eb/subjects/60f592bfc420f35bb7dd169f/chapters?page=1
  getSubjectChapters(programId: string, subjectId: string, page = 1) {
    const options: PPApiOptions = {
      apiPath: `v2/programs/${programId}/subjects/${subjectId}/chapters`,
      params: new PPApiParams()
        .append('organizationId', ORGANIZATION_ID)
        .append('limit', 50),
    };

    return this.apiService.get<RootObjectChapter>(options).pipe(
      map((res) => res.data),
      tap((data) => {
        // if (data && data.length > 0) {
        //   data[0].active = true;
        //   this.chapters$.next(data);
        // }
      }),
      catchError((e) => this.showErrorService.showError(of(e)))
    );
  }

  getChapterTopics(programId: string, subjectId: string, chapterId: string) {
    const options: PPApiOptions = {
      apiPath: `v1/programs/${programId}/subjects/${subjectId}/chapters/${chapterId}/topics/topic-content`,
      params: new PPApiParams()
        .append('organizationId', ORGANIZATION_ID)
        .append('type', 'Concept,Lecture')
        .append('limit', 50),
    };
    return this.apiService.get<RootObjectTopics>(options).pipe(
      map((res) => res.data),
      tap((data) => {}),
      catchError(handleError)
    );
  }

  getAllChapterTopics(
    programId: string,
    subjectId: string,
    chapterId: string,
    page: number
  ) {
    const options: PPApiOptions = {
      apiPath: `v1/programs/${programId}/subjects/${subjectId}/chapters/${chapterId}/topics/topic-content`,
      params: new PPApiParams().append('page', page),
    };
    return this.apiService.get<RootObjectTopics>(options).pipe(
      map((res) => res.data),
      tap((data) => {}),
      catchError(handleError)
    );
  }

  // Search API
  getSearchResults(params: any) {
    const { chapterId, key, keys, page } = params;
    const options: PPApiOptions = {
      apiPath: 'v1/search',

      params: new PPApiParams()
        .append('organizationId', ORGANIZATION_ID.toString())
        .append('chapterId', chapterId)
        .append('key', key)
        .append('keys', keys)
        .append('page', page),
    };
    return this.apiService.get<SearchContent>(options).pipe(
      map((res) => res.data.contents),
      tap((data) => {}),
      catchError((e) => this.showErrorService.showError(of(e)))
    );
  }

  updateWatchTime(
    programSlug: string,
    subjectSlug: string,
    chapterSlug: string,
    topicSlug: string,
    contentSlug: string,
    data: any
  ) {
    const options: PPApiOptions = {
      apiPath: `v1/programs/${programSlug}/subjects/${subjectSlug}/chapters/${chapterSlug}/topics/${topicSlug}/contents/${contentSlug}/update-watchtime`,
    };

    return this.apiService
      .put<any>(data, options)
      .pipe(retry(1), catchError(handleError));
  }

  markContentComplete(
    programSlug: string,
    subjectSlug: string,
    chapterSlug: string,
    topicSlug: string,
    contentSlug: string,
    params?: any
  ) {
    const options: PPApiOptions = {
      apiPath: `v1/programs/${programSlug}/subjects/${subjectSlug}/chapters/${chapterSlug}/topics/${topicSlug}/contents/${contentSlug}/complete`,
    };

    if (!this.isEmpty(params)) {
      options.params = new PPApiParams().appendAll(params);
    }
    const data = {};

    return this.apiService
      .put<any>(data, options)
      .pipe(retry(1), catchError(handleError));
  }

  viewAll(params: any, contentId: string = '') {
    const options: PPApiOptions = {
      apiPath: 'v2/programs/contents',
      params: new PPApiParams()
        .append('organizationId', ORGANIZATION_ID.toString())
        .append('type', params.type || '')
        .append('programId', params.programId)
        .append('subjectId', params.subjectId)
        .append('chapterId', params.chapterId)
        .append('topicId', params.topicId || '')
        .append('page', params.page)
        .append('contentId', contentId),
    };

    return this.apiService.get<ViewAllObject>(options).pipe(
      map((res) => res.data),
      tap((data) => {}),
      catchError((e) => this.showErrorService.showError(of(e)))
    );
  }
  viewAllKhazana(params: any) {
    const options: PPApiOptions = {
      apiPath: 'v2/programs/contents',
      params: new PPApiParams()
        .append('type', params.type || '')
        .append('programId', params.programId)
        .append('subjectId', params.subjectId)
        .append('chapterId', params.chapterId)
        .append('topicId', params.topicId || '')
        .append('page', params.page)
        .append('subTopicId', params.subTopicId),
    };

    return this.apiService.get<ViewAllObject>(options).pipe(
      map((res) => res.data),
      tap((data) => {}),
      catchError((e) => this.showErrorService.showError(of(e)))
    );
  }

  isEmpty(obj: any) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }
}

/*  if (query && Object.keys(query).length > 0) {
     const param: Array<{key: string, value: string | number | boolean}> = Object.keys(query).map(key => ({ key, value: query[key] }));
     console.log(param);
      param.forEach(item => {
        options.params?.appendAll(item);
      })
    }*/
